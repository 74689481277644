// tslint:disable max-line-length
import React from 'react';
import { Formik } from 'formik';
import cn from 'classnames';

import { DEFAULT_FACILITY_NAME } from '../../../constants/form';
import {
  Button, FormDropdown, ErrorMessage, InputContainer, TermsAndConditionsChoice,
} from '../../../components';
import { errorValidationsAccount } from '../../../helpers/formHelpers';
import { Facility } from '../../../entities';
import { ErrorValues } from '../../../views/CreateAccountView';
import styles from './styles.module.scss';

export type AccountHolderFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  phone: string;
  password: string;
  confirmPassword: string;
  facilityId: string;
  acceptedTermsAndConditions: boolean;
};

export const defaultInitialValues: AccountHolderFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  phone: '',
  password: '',
  confirmPassword: '',
  facilityId: '',
  acceptedTermsAndConditions: false,
};

const formIsIncomplete = (
  formValues: AccountHolderFormValues,
) : boolean => Object.values( formValues ).some( value => !value );

const isDisabled = (
  isSubmitting: boolean,
  values: AccountHolderFormValues,
) : boolean => isSubmitting || formIsIncomplete( values );

interface CreateAccountFormProps {
  onSubmit: ( parentAccountData: AccountHolderFormValues ) => Promise<void>;
  facilities: Facility[];
  initialValues?: AccountHolderFormValues;
  errorsValues: ErrorValues;
}

const CreateAccountForm: React.FC<CreateAccountFormProps> = ( {
  onSubmit,
  facilities,
  initialValues = defaultInitialValues,
  errorsValues,
}: CreateAccountFormProps ) => {
  const facilitiesOptions = [
    { id: 0, name: DEFAULT_FACILITY_NAME },
    ...facilities.map( ( f ) => ( { id: f.id, name: f.name } ) ),
  ];

  return (
    <>
      <h4 className={cn( 'subheader', styles.subHeader )}>This will be the account holder information.</h4>
      <Formik
        initialValues={initialValues}
        validate={( values ) => errorValidationsAccount( values )}
        onSubmit={async ( values, { setSubmitting, setFieldError } ) => {
          await onSubmit( values );
          if ( errorsValues.email ) setFieldError( 'email', errorsValues.email );
          setSubmitting( false );
        }}
      >
        {( {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
        } ) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div className={styles.col}>
                <InputContainer
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  label="First Name"
                  error={errors.firstName}
                  touched={!!touched.firstName}
                />
              </div>
              <div className={styles.col}>
                <InputContainer
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  label="Last Name"
                  error={errors.lastName}
                  touched={!!touched.lastName}
                />
              </div>
            </div>
            <InputContainer
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              label="Email"
              error={errors.email}
              touched={!!touched.email}
            />
            <InputContainer
              type="email"
              name="confirmEmail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmEmail}
              label="Confirm Email"
              error={errors.confirmEmail}
              touched={!!touched.confirmEmail}
              onPaste={( e ) => {
                e.preventDefault();
                return false;
              }}
            />
            <InputContainer
              type="phone"
              name="phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              label="Phone Number"
              error={errors.phone}
              touched={!!touched.phone}
            />
            <InputContainer
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              label="Password"
              error={errors.password}
              touched={!!touched.password}
            />
            <InputContainer
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              label="Confirm Password"
              error={errors.confirmPassword}
              touched={!!touched.confirmPassword}
            />
            <h4 className={cn( 'subheader', styles.subHeader )}>Default Facility</h4>
            <div>
              <FormDropdown
                options={facilitiesOptions}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.facilityId}
                index={values.facilityId}
                dropdownId="facilityId"
              />
              <ErrorMessage errorText={errors.facilityId} touched={touched.facilityId} />
            </div>
            <TermsAndConditionsChoice
              name="acceptedTermsAndConditions"
              onClick={handleChange}
              areAccepted={values.acceptedTermsAndConditions}
            />
            <Button
              className={styles.submitBtn}
              type="submit"
              disabled={isDisabled( isSubmitting, values )}
              label="CREATE ACCOUNT"
              loading={isSubmitting && !isValidating}
              loadingType="Oval"
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreateAccountForm;
