import localStorage from 'store';

type StorageType = typeof localStorage;

export default class StorageService {
  storage: StorageType;

  constructor( storage: StorageType ) {
    this.storage = storage;
  }

  static localStorage() {
    return new StorageService( localStorage );
  }

  getItem = ( key: string ) => this.storage.get( key );

  setItem = ( key: string, value: string ) => this.storage.set( key, value );

  removeItem = ( key: string ) => this.storage.remove( key );
}
