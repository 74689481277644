import React from 'react';
import cn from 'classnames';
import { ConfirmationDialog } from '..';
import styles from './styles.module.scss';

interface RemoveMembershipConfirmationDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const RemoveMembershipConfirmationDialog :
  React.FC<RemoveMembershipConfirmationDialogProps> = (
    { ...props },
  ) => {
    const bodyComponent = (
      <p className={cn( 'body1', styles.dialogBodyText )}>
        This means we will not charge you again for this membership and
        {' '}
        <b>{'you\'ll be able to use it for the remaining time.'}</b>
      </p>
    );

    return (
      <ConfirmationDialog
        title="Cancel membership"
        body={bodyComponent}
        confirmationButtonText="confirm"
        cancelButtonText="I don't want to cancel"
        {...props}
      />
    );
  };

export default RemoveMembershipConfirmationDialog;
