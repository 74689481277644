export interface LeavingReasonJSON{
  id: number;
  description: string;
}

interface LeavingReasonProps{
  id: number;
  reason: string;
}

export default class LeavingReason {
  id: number;

  reason: string;

  constructor( {
    id,
    reason,
  }: LeavingReasonProps ) {
    this.id = id;
    this.reason = reason;
  }

  static fromJSON( json: LeavingReasonJSON ) {
    return new LeavingReason( {
      id: json.id,
      reason: json.description,
    } );
  }
}
