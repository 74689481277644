import React from 'react';
import { ROOT } from '../../../constants/routes';
import SwishLogo from '../../../assets/images/logo-h/logo-h.svg';

const SwishButton : React.FC = () => (
  <a href={ROOT}>
    <img src={SwishLogo} alt="Swish home" />
  </a>
);

export default SwishButton;
