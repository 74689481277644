import React, { memo } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface SuccessMessageProps {
  successText?: string;
  className?: string;
}

const SuccessMessage: React.FC<SuccessMessageProps> = (
  {
    successText = '',
    className = '',
  }: SuccessMessageProps,
) => (
  <div className={cn( styles.container, className )}>
    <div className={styles.greenTick} />
    <span className={cn( styles.successMessage, 'body1' )}>{successText}</span>
  </div>
);

export default memo( SuccessMessage );
