import React from 'react';
import { observer } from 'mobx-react-lite';
import { Dropdown, MembershipPlanCard } from '../../../components';
import { Facility, MembershipPlan } from '../../../entities';
import styles from '../styles.module.scss';

interface MembershipPlansByFacilityProps {
  facilities : Facility[];
  membershipPlans : MembershipPlan[];
  onFacilityChange : ( facilityId : string ) => void;
  onMembershipClick: () => void;
}
const MembershipPlansByFacility : React.FC<MembershipPlansByFacilityProps> = (
  {
    facilities, membershipPlans, onFacilityChange, onMembershipClick,
  },
) => {
  const membershipCards = membershipPlans
    .map( plan => (
      <MembershipPlanCard
        membershipPlan={plan}
        className={styles.membershipCards}
        key={plan.id}
        onClick={onMembershipClick}
      />
    ) );

  return (
    <section className={styles.membershipsSection}>
      <Dropdown
        options={facilities}
        onChange={( { target } ) => onFacilityChange( target.value )}
        className={styles.dropdown}
      />
      <div className={styles.membershipPlansContainer}>
        {membershipCards}
      </div>
    </section>
  );
};

export default observer( MembershipPlansByFacility );
