/* eslint-disable camelcase */
import { parse } from 'date-fns';
import Times from '../helpers/Times';
import { PromoCodeJSON } from '../services/CurrentUserService';

interface PromoCodeProps {
  discount: number,
  name: string,
  expirationDate: string | null,
}

export default class PromoCode {
  discount: number;

  name: string;

  expirationDate: string | null;

  constructor( {
    discount,
    name,
    expirationDate,
  }: PromoCodeProps ) {
    this.discount = discount;
    this.name = name;
    this.expirationDate = expirationDate;
  }

  static fromJSON( promoCode: PromoCodeJSON ) {
    const times = new Times();

    return new PromoCode( {
      discount: promoCode.value,
      name: promoCode.name,
      expirationDate: ( promoCode.expiration_date )
        ? times.dateToString( parse( promoCode.expiration_date, 'yyyy-MM-dd', new Date() ) )
        : null,
    } );
  }
}
