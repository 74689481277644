import { makeAutoObservable } from 'mobx';

import { MembershipPlan } from '../entities';

export default class MembershipPlansStore {
  private usersFacilityPlans: MembershipPlan[];

  private allFacilitiesPlans: MembershipPlan[];

  constructor() {
    this.usersFacilityPlans = [];
    this.allFacilitiesPlans = [];

    makeAutoObservable( this );
  }

  setUsersFacilityMembershipPlans( membershipPlans: MembershipPlan[] ) {
    this.usersFacilityPlans = membershipPlans;
  }

  setAllFacilitiesMembershipPlans( membershipPlans: MembershipPlan[] ) {
    this.allFacilitiesPlans = membershipPlans;
  }

  get usersFacilityMembershipPlans() : MembershipPlan[] {
    return this.usersFacilityPlans;
  }

  get allFacilitiesMembershipPlans() : MembershipPlan[] {
    return this.allFacilitiesPlans;
  }
}
