import React, { memo, useCallback } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';
import { Checkbox } from '../index';

export type MembershipType = 'Basic' | 'Standard' | 'Premium';
interface MembershipHeaderProps {
  name: string;
  type: string | MembershipType;
  iconUrl: string;
  isSelected?: boolean;
  Icon?: React.ComponentType<{className: string}>;
}

const MembershipHeader: React.FC<MembershipHeaderProps> = (
  {
    name,
    type = 'Basic',
    iconUrl,
    isSelected,
    Icon,
  }: MembershipHeaderProps,
) => {
  const displayNone = useCallback( ( event ) => {
    event.currentTarget.style.display = 'none';
  }, [] );

  return (
    <div className={styles.mainHeaderContainer}>
      <div className={styles.headerInformationContainer}>
        <img
          src={iconUrl}
          alt=""
          width={50}
          height={50}
          onError={displayNone}
        />
        <div className={styles.headerTextContainer}>
          <p className={cn( 'caption1', styles.membershipType )}>{type}</p>
          <h2 className="header2">{name}</h2>
        </div>
      </div>
      {Icon && ( <Icon className={styles.optionalIcon} /> )}
      {isSelected !== undefined && (
        <div className={styles.membershipHeaderCheckboxContainer}>
          <Checkbox isChecked={isSelected} />
        </div>
      ) }
    </div>
  );
};

export default memo( MembershipHeader );
