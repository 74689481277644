import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import {
  ROOT,
  CREATE_ACCOUNT_URL,
  RECOVER_PASSWORD_URL,
  SET_NEW_PASSWORD_URL,
  PAYMENT_SUCCESS_URL,
  HOME_URL,
  CREATE_PLAYERS_URL,
  EDIT_PLAYER_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  PLAYER,
  EDIT_ACCOUNT_HOLDER_URL,
  DELETE_ACCOUNT_URL,
  PROMO_CODE_MANAGEMENT, FAQ_URL, CHANGE_FACILITY_URL,
} from '../constants/routes';
import {
  Landing,
  CreateAccount,
  RecoverPassword,
  SetNewPassword,
  PaymentSuccess,
  Home,
  CreatePlayers,
  EditPlayer,
  PrivacyPolicy,
  TermsAndConditions,
  EditAccountHolder,
  DeleteAccount,
  PromoCodeManagement,
  FAQ,
  ChangeFacility,
} from '../screens';
import RequireAuth from './RequireAuth';
import RequireUnauth from './RequireUnauth';
import PlayerMembershipInfo
  from '../screens/Player/Player.screen';

const RootNavigator: React.FC = () => (
  <Router>
    <Switch>
      <Route exact path={ROOT}>
        <RequireUnauth><Landing /></RequireUnauth>
      </Route>
      <Route exact path={CREATE_ACCOUNT_URL}>
        <RequireUnauth><CreateAccount /></RequireUnauth>
      </Route>
      <Route exact path={RECOVER_PASSWORD_URL}>
        <RecoverPassword />
      </Route>
      <Route exact path={SET_NEW_PASSWORD_URL}>
        <SetNewPassword />
      </Route>
      <Route exact path={PAYMENT_SUCCESS_URL}>
        <PaymentSuccess />
      </Route>
      <Route exact path={HOME_URL}>
        <RequireAuth><Home /></RequireAuth>
      </Route>
      <Route exact path={PLAYER}>
        <RequireAuth><PlayerMembershipInfo /></RequireAuth>
      </Route>
      <Route exact path={CREATE_PLAYERS_URL}>
        <RequireAuth><CreatePlayers /></RequireAuth>
      </Route>
      <Route exact path={EDIT_ACCOUNT_HOLDER_URL}>
        <RequireAuth><EditAccountHolder /></RequireAuth>
      </Route>
      <Route exact path={EDIT_PLAYER_URL}>
        <RequireAuth><EditPlayer /></RequireAuth>
      </Route>
      <Route exact path={DELETE_ACCOUNT_URL}>
        <RequireAuth><DeleteAccount /></RequireAuth>
      </Route>
      <Route exact path={PROMO_CODE_MANAGEMENT}>
        <RequireAuth><PromoCodeManagement /></RequireAuth>
      </Route>
      <Route exact path={CHANGE_FACILITY_URL}>
        <RequireAuth><ChangeFacility /></RequireAuth>
      </Route>
      <Route exact path={PRIVACY_POLICY_URL}>
        <PrivacyPolicy />
      </Route>
      <Route exact path={TERMS_AND_CONDITIONS_URL}>
        <TermsAndConditions />
      </Route>
      <Route exact path={FAQ_URL}>
        <FAQ />
      </Route>
      <Route>
        <Redirect to={HOME_URL} />
      </Route>
    </Switch>
  </Router>
);

export default RootNavigator;
