import { PlayersService } from '../services';

export default class RemoveMembershipFromPlayerInteractor {
  service: PlayersService;

  constructor( { service = new PlayersService() } = {} ) {
    this.service = service;
  }

  async execute( playerId: number ) {
    return this.service.removeMembershipPlanForPlayer( playerId );
  }
}
