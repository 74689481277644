import { FormikErrors, FormikHelpers } from 'formik';
import { makeAutoObservable } from 'mobx';

import { EMAIL_CRITERIA } from '../constants';
import { SwishError } from '../errors';
import { handleError } from '../helpers/errors';
import { SendRecoverPasswordLinkInteractor } from '../interactors';
import { RecoverPasswordFormValues } from '../screens/RecoverPassword/RecoverPassword.screen';

interface RecoverPasswordViewParams {
  sendRecoverPasswordLinkInteractor?: SendRecoverPasswordLinkInteractor;
}

export default class RecoverPasswordView {
  private sendRecoverPasswordLinkInteractor: SendRecoverPasswordLinkInteractor;

  renderCallback = false;

  constructor( {
    sendRecoverPasswordLinkInteractor = new SendRecoverPasswordLinkInteractor(),
  }: RecoverPasswordViewParams ) {
    this.sendRecoverPasswordLinkInteractor = sendRecoverPasswordLinkInteractor;
    makeAutoObservable( this );
  }

  validateForm( values: RecoverPasswordFormValues ) {
    const errors: FormikErrors<RecoverPasswordFormValues> = {};

    if ( !values.email ) errors.email = 'Required';
    else if ( !EMAIL_CRITERIA.test( values.email ) ) {
      errors.email = 'Invalid email address';
    }

    return errors;
  }

  async sendRecoveryLink(
    values: RecoverPasswordFormValues,
    { setSubmitting }: Partial<FormikHelpers<RecoverPasswordFormValues>>,
  ) {
    try {
      await this.sendRecoverPasswordLinkInteractor.execute( values.email );
      this.renderCallback = true;
    } catch ( error ) {
      const e = error as SwishError;
      handleError( e );
    } finally {
      setSubmitting!( false );
    }
  }
}
