import { makeAutoObservable } from 'mobx';
import { AccordionHandle } from '../components/Accordion/Accordion.component';
import FAQSection from '../entities/FAQSection';

export default class FAQView {
  sections: FAQSection[] = [
    new FAQSection( {
      title: 'About Memberships',
      options: [
        'Create an account and purchase a membership',
        'How to purchase a membership to an existing player',
        'How to cancel a membership',
        'How to upgrade/downgrade a membership',
      ],
    } ),
    new FAQSection( {
      title: 'Billing & Payments',
      options: [
        'How to change credit cards once you are a member',
      ],
    } ),
    new FAQSection( {
      title: 'Booking Sessions',
      options: [
        'How to sign up for a shooting/skill class session',
        'How to change facilities for a session',
      ],
    } ),
  ];

  constructor() {
    makeAutoObservable( this );
  }

  get options() {
    return this.sections.map( section => section.options ).flat( 1 );
  }

  accordionHandleFor( title: string ) {
    return this.sectionWithTitle( title )!.accordionHandle;
  }

  setAccordionHandleFor( title: string, accordionHandle: AccordionHandle ) {
    const section = this.sectionWithTitle( title );
    section!.setAccordionHandle( accordionHandle );
  }

  selectOption = ( option: string|null ) => {
    if ( !!option && this.options.includes( option ) ) {
      const selectedSection = this.sectionThatContains( option );
      this.closeAllSectionsExeptTheOneThatContains( selectedSection! );
      selectedSection!.open();
    } else {
      this.closeAllSections();
    }
  }

  private closeAllSectionsExeptTheOneThatContains( excludedSection: FAQSection ) {
    this.sections.forEach( ( section ) => {
      if ( section !== excludedSection ) section.close();
    } );
  }

  private sectionThatContains( option: string ) {
    return this.sections.find( ( section ) => section.containsOption( option ) );
  }

  private closeAllSections() {
    this.sections.forEach( ( section ) => {
      section.close();
    } );
  }

  get titles() {
    return this.sections.map( ( section ) => ( section.title ) );
  }

  sectionWithTitle( title:string ) {
    return this.sections.find( ( section ) => ( section.hasTitle( title ) ) );
  }
}
