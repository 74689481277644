import React from 'react';
import { Formik, FormikHelpers, FormikErrors } from 'formik';

import { Button, InputContainer } from '../../../components';
import styles from './styles.module.scss';

interface FormProps {
  onSubmit: (
    values: RecoverPasswordFormValues,
    helpers: Partial<FormikHelpers<RecoverPasswordFormValues>>,
  ) => void;
  validate: ( values: RecoverPasswordFormValues ) => FormikErrors<RecoverPasswordFormValues>;
}

export type RecoverPasswordFormValues = {
  email: string;
};

const Form: React.FC<FormProps> = ( {
  onSubmit,
  validate,
}: FormProps ) => {
  const initialValues: RecoverPasswordFormValues = {
    email: '',
  };

  return (
    <>
      <h4 className={styles.header2}>Recover password</h4>
      <p className={styles.body1}>
        Enter the email associated with your account to receive password reset instructions.
      </p>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {( {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
        } ) => (
          <form onSubmit={handleSubmit}>
            <InputContainer
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className={styles.caption1}
              label="Email address"
              error={errors.email}
              touched={!!touched.email}
            />
            <Button
              className={styles.submitBtn}
              type="submit"
              label="send me the recovery link"
              disabled={isSubmitting || !values.email || !!errors.email}
              loading={isSubmitting && !isValidating}
              loadingType="Oval"
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default Form;
