export interface IErrorResponse {
  statusCode: number;
  name: string;
  description: string;
}

export default class ErrorResponse {
  statusCode: number;

  name: string;

  description: string;

  constructor( {
    statusCode,
    name,
    description,
  }: IErrorResponse ) {
    this.statusCode = statusCode;
    this.name = name;
    this.description = description;
  }
}
