import React from 'react';
import { Callback } from '.';
import styles from './styles.module.scss';

const NewPasswordSuccess: React.FC = () => (
  <Callback
    iconClassName={styles.circleCheckIcon}
    title="Password reset succesfully"
    body="The password has already been changed. You can go back to the app and access again."
  />
);

export default NewPasswordSuccess;
