/* eslint-disable camelcase */

import { makeAutoObservable } from 'mobx';
import { BillingCycleStore, CurrentUserStore, MembershipPlansStore } from '../stores';
import { Player } from '../entities';
import { handleError } from '../helpers/errors';
import { SwishError } from '../errors';
import { TotalChargesView } from './index';
import { ChargesRequest } from '../services/TotalChargesService';
import { AssignMembershipPlanToPlayersInteractor, FetchTotalChargesInteractor } from '../interactors';
import { PAYMENT_CANCEL_URL, PAYMENT_SUCCESS_URL } from '../constants/routes';
import Currency from '../helpers/Currency';
import GetMembershipPlansFromStore from '../interactors/GetMembershipPlansFromStore';

export type PlayerInCart = Player & {membershipPlanId: number}

interface CartViewParams {
  membershipPlansStore: MembershipPlansStore
  billingCycleStore: BillingCycleStore
  currentUserStore?: CurrentUserStore
  fetchTotalChargesInteractor: FetchTotalChargesInteractor;
  assignMembershipPlansInteractor: AssignMembershipPlanToPlayersInteractor;
  getMembershipPlansFromStore?: GetMembershipPlansFromStore;
  players: PlayerInCart[];
  onConfirm?: () => unknown;
}

export default class CartView {
  private membershipPlansStore: MembershipPlansStore;

  private billingCycleStore: BillingCycleStore;

  private currentUserStore?: CurrentUserStore;

  private assignMembershipPlansInteractor: AssignMembershipPlanToPlayersInteractor;

  private totalChargesView: TotalChargesView;

  getMembershipPlansFromStore: GetMembershipPlansFromStore;

  onConfirm: () => unknown;

  players: PlayerInCart[];

  isSubmitting = false

  constructor( {
    membershipPlansStore = new MembershipPlansStore(),
    billingCycleStore = new BillingCycleStore(),
    currentUserStore = new CurrentUserStore(),
    assignMembershipPlansInteractor = new AssignMembershipPlanToPlayersInteractor(),
    fetchTotalChargesInteractor = new FetchTotalChargesInteractor(),
    getMembershipPlansFromStore = new GetMembershipPlansFromStore(),
    players = [],
    onConfirm = () => null,
  } : CartViewParams ) {
    this.membershipPlansStore = membershipPlansStore;
    this.billingCycleStore = billingCycleStore;
    this.currentUserStore = currentUserStore;
    this.assignMembershipPlansInteractor = assignMembershipPlansInteractor;
    this.getMembershipPlansFromStore = getMembershipPlansFromStore;
    this.players = players;
    this.onConfirm = onConfirm;

    this.totalChargesView = new TotalChargesView( {
      fetchTotalChargesInteractor,
      chargesRequest: ():ChargesRequest => ( {
        cart: {
          billingCycle: this.billingCycle,
          promoCodeName: this.promoCode,
          membershipsForPlayers: this.membershipsForPlayers,
        },
      } ),
    } );

    makeAutoObservable( this );
  }

  get membershipPlans() {
    return this.getMembershipPlansFromStore.execute();
  }

  get billingCycleOptions() {
    return this.billingCycles || [];
  }

  get totalCharges() {
    return this.totalChargesView.totalCharges;
  }

  get invalidPromoCode() {
    return this.totalChargesView.invalidPromoCode;
  }

  get validPromoCode() {
    return this.totalChargesView.validPromoCode;
  }

  get promoCodeValidUntil() {
    return this.totalChargesView.promoCodeValidUntil;
  }

  get extraFee() {
    return this.totalChargesView.extraFee;
  }

  get userHasSavedPromoCode() {
    return !!this.currentUserStore?.currentUser?.promoCode;
  }

  get goToPayLabel() {
    return `Go to pay - ${this.afterFees}`;
  }

  async fetchTotalCharges() {
    return this.totalChargesView.fetchTotalCharges();
  }

  goToPay = async () => {
    this.setIsSubmitting( true );
    const duration = this.billingCycle;
    try {
      const checkoutUrl = await this.assignMembershipPlansInteractor.execute(
        this.buildDataForAssignMembershipPlans( duration ),
      );
      this.onConfirm();
      window.location.href = checkoutUrl;
    } catch ( error ) {
      handleError( error as SwishError );
    } finally {
      this.setIsSubmitting( false );
    }
  }

  updateBillingCycle = ( newBillingCycle: number ) => {
    this.totalChargesView.updateBillingCycle( newBillingCycle );
  }

  applyPromoCode = () => (
    this.totalChargesView.fetchTotalCharges()
  )

  resetPromoCode = () => (
    this.totalChargesView.resetPromoCode()
  )

  changePromoCode = ( newPromoCode:string ) => {
    this.totalChargesView.changePromoCode( newPromoCode );
  }

  private get membershipsForPlayers() {
    return this.players.map( ( { id, membershipPlanId } ) => ( {
      membershipPlanId,
      playerId: id,
    } ) );
  }

  private get billingCycles() {
    return this.billingCycleStore.billingCycles;
  }

  get billingCycle() {
    return this.totalChargesView.billingCycle;
  }

  get promoCode() {
    return this.totalChargesView.promoCode || undefined;
  }

  private setIsSubmitting( value: boolean ) {
    this.isSubmitting = value;
  }

  private buildDataForAssignMembershipPlans( selectedDuration: number ) {
    return {
      success_url: `${process.env.REACT_APP_URL}${PAYMENT_SUCCESS_URL}`,
      cancel_url: `${process.env.REACT_APP_URL}${PAYMENT_CANCEL_URL}`,
      duration_in_months: selectedDuration,
      promo_code_name: this.promoCode,
      memberships_for_players: this.membershipsForPlayers.map(
        ( { playerId, membershipPlanId } ) => ( {
          player_id: playerId,
          membership_plan_id: membershipPlanId,
        } ),
      ),
    };
  }

  private get afterFees() {
    return new Currency( this.totalCharges.afterFees ).format();
  }
}
