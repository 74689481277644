/* eslint-disable @typescript-eslint/no-unused-vars */
import { Facility } from '../entities';
import { FacilitiesService } from '../services';
import FacilitiesServiceFactory from '../services/factories/FacilitiesServiceFactory';

export default class FetchFacilitiesInteractor {
  private service: FacilitiesService;

  saveInStore = ( facilities: Facility [] ) => { /* do nothing */ };

  constructor( {
    service = FacilitiesServiceFactory.forAllSports(),
    saveInStore = ( facilities: Facility [] ) => { /* do nothing */ },
  } = {} ) {
    this.service = service;
    this.saveInStore = saveInStore;
  }

  execute = async () => {
    const facilities = await this.fetchFromService();
    this.saveInStore( facilities );
    return facilities;
  };

  private fetchFromService = () => this.service.fetchFacilities();
}
