import { makeAutoObservable } from 'mobx';
import LocalStorage from 'store';

import { AccessToken } from '../entities';
import { StorageService } from '../services';

export default class AuthenticationStore {
  storageService: StorageService;

  accessToken?: AccessToken;

  private key = 'accessToken';

  constructor(
    storageService: StorageService = new StorageService( LocalStorage ),
  ) {
    this.storageService = storageService;
    this.accessToken = undefined;
    makeAutoObservable( this );
    this.restoreAccessToken();
  }

  private restoreAccessToken() {
    const accessTokenStr = this.storageService.getItem( this.key );
    if ( accessTokenStr ) {
      const accessTokenJson = JSON.parse( accessTokenStr );
      this.setAccessToken( new AccessToken(
        accessTokenJson.token,
        accessTokenJson.refreshToken,
        accessTokenJson.tokenType,
        accessTokenJson.expiresIn,
        accessTokenJson.createdAt,
      ) );
    }
  }

  get isAuthenticated() {
    return !!this.accessToken;
  }

  get token() {
    return this.accessToken?.token;
  }

  get refreshToken() {
    return this.accessToken?.refreshToken;
  }

  get tokenType() {
    return this.accessToken?.tokenType;
  }

  private unassignAccessToken() {
    this.accessToken = undefined;
  }

  private assignAccessToken( accessToken: AccessToken ) {
    this.accessToken = accessToken;
  }

  setAccessToken( accessToken: AccessToken ) {
    this.storageService.setItem( this.key, JSON.stringify( accessToken ) );
    this.assignAccessToken( accessToken );
  }

  removeAccessToken() {
    this.storageService.removeItem( this.key );
    this.unassignAccessToken();
  }

  setAccessTokenWithOnlyToken( token:string ) {
    const accessToken = new AccessToken(
      token,
      '',
      'Bearer',
      Date.now() + 7200 * 1000,
      Date.now(),
    );

    this.setAccessToken( accessToken );
  }
}
