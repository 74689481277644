/* eslint-disable camelcase */
import { ALL_MEMBERSHIP_PLANS_URL, MEMBERSHIP_PLANS_URL } from '../constants/services';
import { MembershipPlan } from '../entities';
import { Api } from '.';
import { FacilityJson } from './CurrentUserService';

interface PromotionalBenefitJson {
  description: string;
}

export interface MembershipPlanJson {
  id: number;
  name: string;
  tier: string;
  icon: string;
  price: number;
  promotional_benefits: PromotionalBenefitJson[];
  facility: FacilityJson;
  published: boolean;
}

export default class MembershipPlansService {
  api: Api;

  builder: typeof MembershipPlan.fromJSON;

  constructor( {
    api = new Api(),
    builder = MembershipPlan.fromJSON,
  } = {} ) {
    this.api = api;
    this.builder = builder;
  }

  private async fetchMembershipPlansFrom( url : string ) {
    const result = await this.api.get( url );

    return result.map(
      ( membershipPlan : MembershipPlanJson ) => this.builder( membershipPlan ),
    );
  }

  fetchUsersFacilityMembershipPlans() {
    return this.fetchMembershipPlansFrom( MEMBERSHIP_PLANS_URL );
  }

  fetchAllFacilitiesMembershipPlans() {
    return this.fetchMembershipPlansFrom( ALL_MEMBERSHIP_PLANS_URL );
  }
}
