import React from 'react';
import { Dialog } from '@mui/material';
import {
  DialogTitleWithCloseIcon,
  DialogBody,
  DialogConfirmationAndCancelButtons,
} from './components';
import {
  rootSx,
} from './styles';

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  body: React.ReactNode;
  confirmationButtonText: string;
  cancelButtonText: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmationDialog : React.FC<ConfirmationDialogProps> = ( {
  open,
  title,
  body,
  confirmationButtonText,
  cancelButtonText,
  onConfirm,
  onClose,
} : ConfirmationDialogProps ) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="xs"
    sx={rootSx}
  >
    <DialogTitleWithCloseIcon
      title={title}
      onClose={onClose}
    />
    <DialogBody>{body}</DialogBody>
    <DialogConfirmationAndCancelButtons
      confirmationButtonText={confirmationButtonText}
      cancelButtonText={cancelButtonText}
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  </Dialog>
);

export default ConfirmationDialog;
