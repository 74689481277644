import React, { memo } from 'react';
import cn from 'classnames';

import Currency from '../../helpers/Currency';
import styles from './styles.module.scss';

interface PricingInfoProps {
  price: number;
}

const PricingInfo: React.FC<PricingInfoProps> = (
  { price }: PricingInfoProps,
) => (
  <div className={styles.pricingInfoContainer}>
    <p className={cn( styles.pricingText, styles.megaHeader )}>
      {new Currency( price ).format()}
      <span className={cn( styles.body1, styles.pricingRecurringText )}>
        / Recurring
      </span>
    </p>
  </div>
);

export default memo( PricingInfo );
