/* eslint-disable prefer-destructuring */
import { makeAutoObservable } from 'mobx';
import { FetchTotalChargesInteractor } from '../interactors';
import { TotalCharges } from '../entities';
import { ChargesRequest } from '../services/TotalChargesService';
import { SwishError } from '../errors';
import { handleError } from '../helpers/errors';

interface TotalChargesViewParams {
  fetchTotalChargesInteractor?: FetchTotalChargesInteractor;
  chargesRequest: () => ChargesRequest;
}

export default class TotalChargesView {
  private fetchTotalChargesInteractor: FetchTotalChargesInteractor;

  totalCharges: TotalCharges;

  billingCycle = 1;

  promoCode = '';

  invalidPromoCode = false;

  chargesRequest: () => ChargesRequest;

  constructor( {
    fetchTotalChargesInteractor = new FetchTotalChargesInteractor(),
    chargesRequest,
  }:TotalChargesViewParams ) {
    this.fetchTotalChargesInteractor = fetchTotalChargesInteractor;
    this.chargesRequest = chargesRequest;
    this.totalCharges = TotalCharges.empty();

    makeAutoObservable( this );
  }

  async fetchTotalCharges() {
    this.invalidPromoCode = false;
    await this.requestTotalCharges();
  }

  private async requestTotalCharges() {
    try {
      const newTotalCharges = await this.fetchTotalChargesInteractor.execute(
        this.chargesRequest(),
      );
      this.setTotalCharges( newTotalCharges );
    } catch ( error ) {
      const e = error as SwishError;

      if ( e.isInputError() ) {
        this.handlePromoCodeError();
      } else {
        handleError( e );
      }
    }
  }

  private setTotalCharges( newValue: TotalCharges ) {
    this.totalCharges = newValue;
  }

  updateBillingCycle( newBillingCycle: number ) {
    this.billingCycle = newBillingCycle;
    this.fetchTotalCharges();
  }

  async resetPromoCode() {
    this.changePromoCode( '' );
    await this.fetchTotalCharges();
  }

  changePromoCode( newPromoCode:string ) {
    this.promoCode = newPromoCode;
  }

  private handlePromoCodeError() {
    this.invalidPromoCode = true;
  }

  get validPromoCode() {
    return !!( this.totalCharges.promoCode );
  }

  get promoCodeValidUntil() {
    return ( this.validPromoCode )
      ? this.expirationDate
      : '';
  }

  private get expirationDate() {
    return this.totalCharges.promoCode!.expirationDate || '';
  }

  get extraFee() {
    return this.totalCharges.extraFee;
  }
}
