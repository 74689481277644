import React from 'react';
import cn from 'classnames';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from './styles.module.scss';

interface SearchInputProps {
  options: string[];
  onFinishedChange: ( value:string|null ) => void;
}

const SearchInput:React.FC<SearchInputProps> = ( {
  options,
  onFinishedChange,
} ) => (
  <Autocomplete
    id="searchBox"
    freeSolo
    onChange={( event, newValue: string | null ) => {
      onFinishedChange( newValue );
    }}
    options={options.map( ( option ) => option )}
    className={cn( styles.autoComplete, styles.body1 )}
    renderInput={( params ) => (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          placeholder: 'Search',
        }}
        sx={{
          '& fieldset': {
            borderRadius: '8px',
            borderWidth: '0',
          },
        }}
        className={styles.searchBox}
      />
    )}
  />
);

export default SearchInput;
