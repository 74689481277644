import { CurrentUserService } from '../services';

export default class ValidateResetPasswordTokenInteractor {
  service: CurrentUserService;

  constructor( { service = new CurrentUserService() } = {} ) {
    this.service = service;
  }

  execute( token: string ) {
    return this.service.validateResetPasswordToken( token );
  }
}
