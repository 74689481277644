import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';
import BackArrowWithLabel from '../BackArrowWithLabel/BackArrowWithLabel.component';

interface StepHeaderProps {
  currentStep: number;
  finalStep?: number;
  className?: string;
  onClick?: () => void;
}

const StepHeader: React.FC<StepHeaderProps> = ( {
  currentStep,
  finalStep = 3,
  className = '',
  onClick,
}: StepHeaderProps ) => (
  <div className={cn( styles.container, className )}>
    <BackArrowWithLabel label={`Step ${currentStep} of ${finalStep}`} onClick={onClick} />
  </div>
);

export default StepHeader;
