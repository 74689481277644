import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface CardProps {
  className?: string;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ( { className, children }: CardProps ) => (
  <div className={cn( styles.container, className )}>
    {children}
  </div>
);

export default Card;
