import { makeAutoObservable } from 'mobx';
import { Facility } from '../entities';
import {
  FetchFacilitiesInteractor,
  SelectNewFacilityInteractor,
} from '../interactors';
import { SwishError } from '../errors';
import ErrorHandler from './errors/ErrorHandler';

interface ChangeFacilityViewProps{
  fetchFacilitiesInteractor?: FetchFacilitiesInteractor;
  selectNewFacilityInteractor?: SelectNewFacilityInteractor;
  errorHandler?: ErrorHandler;
}

export default class ChangeFacilityView {
  private fetchFacilitiesInteractor: FetchFacilitiesInteractor;

  private selectNewFacilityInteractor: SelectNewFacilityInteractor;

  private errorHandler: ErrorHandler;

  facilities: Facility[] = [];

  selectedFacilityId = -1;

  isSendingNewFacility = false;

  finishedSendingNewFacility = false;

  constructor( {
    fetchFacilitiesInteractor = new FetchFacilitiesInteractor(),
    selectNewFacilityInteractor = new SelectNewFacilityInteractor(),
    errorHandler = new ErrorHandler(),
  }: ChangeFacilityViewProps = {} ) {
    this.fetchFacilitiesInteractor = fetchFacilitiesInteractor;
    this.selectNewFacilityInteractor = selectNewFacilityInteractor;
    this.errorHandler = errorHandler;

    this.selectFacility = this.selectFacility.bind( this );
    this.chooseNewFacility = this.chooseNewFacility.bind( this );

    makeAutoObservable( this );
  }

  async fetchFacilities() {
    try {
      this.setFacilities( await this.fetchFacilitiesInteractor.execute() );
    } catch ( e ) {
      this.errorHandler.handleError( e as SwishError );
    }
  }

  private setFacilities( facilities:Facility[] ) {
    this.facilities = facilities;
  }

  selectFacility( facilityId:number ) {
    this.selectedFacilityId = facilityId;
  }

  isButtonDisabled() {
    return ( this.selectedFacilityId === -1 );
  }

  get basketballFacilities() {
    return this.facilities.filter( ( facility ) => facility.sport === 'Basketball' );
  }

  async chooseNewFacility() {
    this.isSendingNewFacility = true;
    try {
      await this.selectNewFacilityInteractor.execute( this.selectedFacilityId );
      this.finishedSendingNewFacility = true;
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    } finally {
      this.isSendingNewFacility = false;
    }
  }
}
