import React from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { Card, MembershipInformation, Separator } from '..';
import { MembershipPlan } from '../../entities';
import styles from './styles.module.scss';
import { SelectablePlayer } from '../../views/CreatePlayersStep2View';

interface AssignableMembershipCardProps {
  membershipPlan: MembershipPlan;
  players: SelectablePlayer[];
  onPlayerSelect: ( selectedItem: SelectablePlayer ) => void;
  onPlayerRemove: ( removedItem: SelectablePlayer ) => void;
}

const AssignableMembershipPlanCard: React.FC<AssignableMembershipCardProps> = ( {
  membershipPlan,
  players,
  onPlayerSelect,
  onPlayerRemove,
} ) => (
  <Card>
    <MembershipInformation
      membershipPlan={membershipPlan}
    />
    <Separator className={styles.separator} />
    <div className={styles.playersDropdown}>
      <p className="caption1">Assign player</p>
      <Multiselect
        hidePlaceholder
        placeholder="Select players"
        closeOnSelect={false}
        showArrow
        customCloseIcon={<div className={styles.customCloseIcon} />}
        showCheckbox
        avoidHighlightFirstOption
        onSelect={(
          _: SelectablePlayer[],
          selectedItem: SelectablePlayer,
        ) => onPlayerSelect( selectedItem )}
        onRemove={(
          _: SelectablePlayer[],
          selectedItem: SelectablePlayer,
        ) => onPlayerRemove( selectedItem )}
        options={players}
        displayValue="firstName"
        selectedValues={players.filter(
          ( { selected, membershipPlanId } ) => (
            selected && membershipPlanId === membershipPlan.id
          ),
        )}
        style={{
          searchBox: {
            flexWrap: 'wrap',
          },
          chips: {
            whiteSpace: 'pre-line',
          },
        }}
      />
    </div>
  </Card>
);

export default AssignableMembershipPlanCard;
