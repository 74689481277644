import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import cn from 'classnames';
import { WithHeader } from '../../hocs';
import PlayersInfo from './components/PlayerInfo';
import {
  MembershipSection,
  ResponsiveContainer,
  DeletePlayerConfirmationDialog,
  RemoveMembershipConfirmationDialog,
  UpgradeMembershipConfirmationDialog,
  RenewMembershipConfirmationDialog,
  DowngradeMembershipConfirmationDialog,
} from '../../components';
import styles from './styles.module.scss';
import usePlayerCoordinator
  from '../../view-coordinators/usePlayerCoordinator';
import { HOME_URL } from '../../constants/routes';
import BackArrowWithLabel from '../../components/BackArrowWithLabel/BackArrowWithLabel.component';
import { DeletePlayerButton } from '../EditPlayer/components';
import CartStep from './components/CartStep';

interface Params {
  id: string;
}

const Player: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const view = usePlayerCoordinator();

  useEffect( () => {
    view.fetchData();
  }, [ view ] );

  useEffect( () => {
    if ( Number.isNaN( +id ) ) {
      history.replace( HOME_URL );
    } else view.loadPlayer( +id );
  }, [ id, view, history ] );

  useEffect( () => {
    if ( view.deletePlayerSuccessful ) {
      history.replace( HOME_URL );
    }
  }, [ view.deletePlayerSuccessful, history ] );

  const onRemoveMembershipConfirmation = useCallback( async () => {
    await view.removeMembership();
    view.closeRemoveMembershipModal();
    history.replace( HOME_URL );
  }, [ view, history ] );

  const { membershipPlan } = view;

  if ( !view.player ) return null;

  if ( view.isInCartStep ) {
    return (
      <ResponsiveContainer sectionStyle={styles.container}>
        <CartStep view={view.manageMembershipView} />
      </ResponsiveContainer>
    );
  }

  return (
    <section>
      <ResponsiveContainer sectionStyle={styles.container}>
        <BackArrowWithLabel />
        <h2 className={cn( 'header2', styles.playerName )}>{view.player?.firstName}</h2>
        <PlayersInfo player={view.player} />
        <MembershipSection
          activeMembershipPlan={membershipPlan}
          isActive={view.hasActiveMembership}
          isMembershipExpirationImminent={view.isMembershipExpirationImminent}
          activeUntil={view.membershipDueDate}
          onClickRemove={view.openRemoveMembershipModal}
          membershipPlans={view.otherMembershipOptions}
          onMembershipClick={view.onMembershipClick}
        />
        <DeletePlayerButton onClick={view.openDeletePlayerModal} />
      </ResponsiveContainer>
      <RemoveMembershipConfirmationDialog
        open={view.isRemoveMembershipModalOpen}
        onConfirm={onRemoveMembershipConfirmation}
        onClose={view.closeRemoveMembershipModal}
      />
      <DeletePlayerConfirmationDialog
        open={view.isDeletePlayerModalOpen}
        playerName={view.player.firstName}
        onConfirm={view.deletePlayer}
        onClose={view.closeDeletePlayerModal}
      />
      <UpgradeMembershipConfirmationDialog
        open={view.isUpgradeMembershipModalOpen}
        membership={view.getSelectedMembershipName}
        onConfirm={view.changeMembershipToSelectedOne}
        onClose={view.closeUpgradeMembershipModal}
      />
      <RenewMembershipConfirmationDialog
        open={view.isRenewMembershipModalOpen}
        membership={view.getSelectedMembershipName}
        onConfirm={view.changeMembershipToSelectedOne}
        onClose={view.closeRenewMembershipModal}
      />
      <DowngradeMembershipConfirmationDialog
        open={view.isDowngradeMembershipModalOpen}
        membership={view.getSelectedMembershipName}
        onConfirm={view.changeMembershipToSelectedOne}
        onClose={view.closeDowngradeMembershipModal}
      />
    </section>
  );
};

export default WithHeader( observer( Player ) );
