import {
  InputError, AvailabilityError, ServerError, UnknownError,
} from './index';

export interface SwishErrorParams {
  name?: string;
  message?: string;
  statusCode?: number;
}

export default class SwishError extends Error {
  statusCode?: number;

  constructor( {
    name = '',
    message = '',
    statusCode,
  }: SwishErrorParams = {} ) {
    super();
    this.name = name;
    this.message = message;
    this.statusCode = statusCode;
  }

  isAvailabilityError(): this is AvailabilityError { return false; }

  isInputError():this is InputError { return false; }

  isUnknownError(): this is UnknownError { return false; }

  isServerError(): this is ServerError { return false; }

  isUnauthorizedError() { return false; }

  isConflictError() { return false; }
}
