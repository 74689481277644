import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import Currency from '../../helpers/Currency';
import DiscountLabel from './components/DiscountLabel';
import DisclaimerTooltip from './components/DisclaimerTooltip';

interface BillingLineProps {
  name: string;
  discountPercentage?: number | null;
  fullPrice: number;
  netPrice?: number;
  disclaimerText?: string;
}

const BillingLine: React.FC<BillingLineProps> = ( {
  name,
  discountPercentage = null,
  fullPrice,
  netPrice,
  disclaimerText = '',
} ) => {
  const renderPrice = () => {
    if ( !discountPercentage ) {
      return (
        <span className={cn( styles.caption1, styles.priceText )}>
          {new Currency( fullPrice ).format()}
        </span>
      );
    }

    return (
      <div className={styles.detailPricesContainer}>
        <DiscountLabel discount={discountPercentage} />
        <span className={cn( styles.caption1, styles.detailText, styles.linethrough )}>
          {new Currency( fullPrice ).format()}
        </span>
        <span className={cn( styles.caption1, styles.priceText )}>
          {new Currency( netPrice ).format()}
        </span>
      </div>
    );
  };

  const renderDisclaimer = () => disclaimerText && (
  <DisclaimerTooltip description={disclaimerText} />
  );

  return (
    <div className={cn( styles.caption1, styles.billingLineContainer )}>
      <span className={cn( styles.caption1, styles.detailText )}>{name}</span>
      <div className={styles.disclaimerAndPriceContainer}>
        {renderDisclaimer()}
        {renderPrice()}
      </div>
    </div>
  );
};

export default BillingLine;
