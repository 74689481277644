import React from 'react';
import { MembershipPlan } from '../../entities';
import { CompactMembershipPlanCard, SectionTitle } from '../index';
import styles from './styles.module.scss';
import ActiveMembershipPlanCard from './components/ActiveMembershipPlanCard';
import NoMembership from './components/NoMembership';

interface MembershipSectionProps {
  activeMembershipPlan?: MembershipPlan;
  onClickRemove?: () => unknown;
  isActive?: boolean;
  isMembershipExpirationImminent?: boolean;
  activeUntil?: string;
  membershipPlans?: MembershipPlan[];
  onMembershipClick?: ( planId: number ) => unknown;
}

const MembershipSection = ( {
  activeMembershipPlan,
  onClickRemove = undefined,
  isActive = false,
  isMembershipExpirationImminent = false,
  activeUntil = undefined,
  membershipPlans = [],
  onMembershipClick = () => null,
} : MembershipSectionProps ) => (
  <div className={styles.membershipSection}>
    <SectionTitle title="Membership" />
    {activeMembershipPlan
      ? (
        <ActiveMembershipPlanCard
          activeMembershipPlan={activeMembershipPlan}
          onClickRemove={onClickRemove}
          isActive={isActive}
          isExpirationImminent={isMembershipExpirationImminent}
          activeUntil={activeUntil}
          onMembershipClick={onMembershipClick}
        />
      )
      : <NoMembership />}
    {membershipPlans.map( ( plan ) => (
      <CompactMembershipPlanCard
        key={plan.id}
        membershipPlan={plan}
        onClick={() => { onMembershipClick( plan.id ); }}
        expandable
        isSelected={( !isActive || !activeMembershipPlan ) ? false : undefined}
      />
    ) )}
  </div>
);

export default MembershipSection;
