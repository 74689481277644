import CurrentUserService from '../services/CurrentUserService';
import { AuthStore } from '../stores';

export default class DeleteAccountInteractor {
  service: CurrentUserService;

  authStore: AuthStore;

  constructor( {
    service = new CurrentUserService(),
    authStore = new AuthStore(),
  } = {} ) {
    this.service = service;
    this.authStore = authStore;
  }

  async execute( reasonId?:number, customReason?:string ) {
    const response = await this.service.deleteAccount( reasonId, customReason );
    this.authStore.removeAccessToken();
    return response;
  }
}
