/* eslint-disable camelcase */
import { Api } from './index';
import {
  BILLING_DETAILS_URL,
} from '../constants/services';
import TotalCharges from '../entities/TotalCharges';
import { PromoCodeJSON } from './CurrentUserService';

export interface Line {
  player_id: number,
  membership_plan_id: number,
  full_price: number,
  discount: number,
  discount_percentage: number,
  subtotal: number,
}

export interface AxiosResponseType {
  lines: Line[],
  promo_code: PromoCodeJSON | null,
  transaction_fee: number,
  full_price: number,
  price_without_promo_code: number,
  net_price: number,
  technology_platform_fee?: { name: string, price: number, description: string},
  price_after_fees: number,
}

export interface ChargesRequest {
  cart: {
    billingCycle?: number,
    promoCodeName?: string,
    membershipsForPlayers: {
      playerId: number,
      membershipPlanId: number,
    }[],
  }
}

interface SerializedMembershipForPlayers {
  player_id: number,
  membership_plan_id: number,
}

export default class TotalChargesService {
  api: Api;

  constructor( {
    api = new Api(),
  } = {} ) {
    this.api = api;
  }

  fetchCharges = async ( request: ChargesRequest ): Promise<TotalCharges> => {
    const axiosResponse: AxiosResponseType = await this.api.post(
      BILLING_DETAILS_URL,
      this.requestToJSON( request ),
    );
    return TotalCharges.fromJSON( axiosResponse );
  }

  private requestToJSON = ( request: ChargesRequest ) => ( {
    cart: {
      billing_cycle: request.cart.billingCycle,
      promo_code_name: request.cart.promoCodeName,
      memberships_for_players: TotalChargesService.serializeMemberships(
        request.cart.membershipsForPlayers,
      ),
    },
  } )

  private static serializeMemberships( memberships: {
    playerId: number,
    membershipPlanId: number,
  }[] ) : SerializedMembershipForPlayers[] {
    return memberships.map( ( { playerId, membershipPlanId } ) => ( {
      player_id: playerId,
      membership_plan_id: membershipPlanId,
    } ) );
  }
}
