import React from 'react';
import styles from './styles.module.scss';

interface CallbackProps {
  iconClassName: string;
  title: string;
  body: string;
}

const Callback: React.FC<CallbackProps> = ( {
  iconClassName,
  title,
  body,
}:CallbackProps ) => (
  <>
    <div className={iconClassName} />
    <h4 className={styles.header2}>{title}</h4>
    <p className={styles.body1}>{body}</p>
  </>
);

export default Callback;
