import { useState } from 'react';
import {
  Api, CurrentUserService, MembershipPlansService, OAuthResponseDeserializer,
} from '../services';
import { FetchMembershipPlansInteractor, LoginInteractor } from '../interactors';
import { LandingView, LoginView, ViewMembershipsView } from '../views';
import { useRootStore } from '../providers/RootStoreProvider';

const useLandingCoordinator = () => {
  const { authStore, membershipPlansStore } = useRootStore();

  const createView = () => {
    const createViewMembershipView = () => {
      const api = new Api( { authStore } );

      const service = new MembershipPlansService( { api } );

      const interactor = new FetchMembershipPlansInteractor( {
        store: ( membershipPlans ) => membershipPlansStore
          .setAllFacilitiesMembershipPlans( membershipPlans ),
        fetch: () => service.fetchAllFacilitiesMembershipPlans(),
      } );

      return new ViewMembershipsView( {
        fetchingMembershipPlansWith: interactor,
        storingMembershipPlansOn: membershipPlansStore,
      } );
    };

    const createLoginView = () => {
      const api = new Api( {
        authStore,
        responseDeserializerKlass: OAuthResponseDeserializer,
      } );

      const currentUserServiceForLogin = new CurrentUserService( { api } );
      const loginInteractor = new LoginInteractor( { service: currentUserServiceForLogin } );

      return new LoginView( { loginInteractor } );
    };

    return new LandingView( {
      viewMembershipView: createViewMembershipView(),
      loginView: createLoginView(),
    } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default useLandingCoordinator;
