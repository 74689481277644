import React from 'react';
import cn from 'classnames';

import styles from '../styles.module.scss';
import { BillingLine } from '../../index';
import { DeserializedLine } from '../../../entities/TotalCharges';
import { PlayerInCart } from '../../../views/CartView';

interface BillingDetailsForPlanProps {
  plan: { id: number, membershipName: string };
  players: PlayerInCart[];
  lines: DeserializedLine[];
}

const BillingDetailsForPlan: React.FC<BillingDetailsForPlanProps> = ( {
  plan,
  players,
  lines,
}: BillingDetailsForPlanProps ) => (
  <>
    <p className={cn( styles.caption1, styles.planTitle )}>{plan.membershipName}</p>
    {lines.map( ( line ) => {
      const currentPlayer = players.find( ( player ) => player.id === line.playerId );

      return (
        <BillingLine
          key={currentPlayer!.id}
          name={currentPlayer!.firstName}
          fullPrice={line.fullPrice}
          discountPercentage={line.discountPercentage}
          netPrice={line.subtotal}
        />

      );
    } )}
  </>
);

export default BillingDetailsForPlan;
