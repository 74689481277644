import React from 'react';
import styles from './styles.module.scss';

const Callback: React.FC = () => (
  <>
    <div className={styles.emailIcon} />
    <h4 className={styles.header2}>Check your email</h4>
    <p className={styles.body1}>
      We have sent password recover instructions to the email you provided.
      Remember to check the spam filter. The link will only be available for 24hrs.
    </p>
  </>
);

export default Callback;
