import React from 'react';
import { Button, Card, MembershipInformation } from '..';
import { MembershipPlan } from '../../entities';
import styles from './styles.module.scss';

interface MembershipCardProps {
  membershipPlan: MembershipPlan;
  className? : string;
  onClick?: () => void;
}

const MembershipPlanCard: React.FC<MembershipCardProps> = ( {
  className,
  onClick,
  ...props
} ) => (
  <Card className={className}>
    <MembershipInformation {...props} />
    {
          ( !!onClick )
          && (
          <Button
            className={styles.getStartedButton}
            onClick={onClick}
            type="button"
            label="GET STARTED"
            variant="secondary"
          />
          )
      }
  </Card>
);

export default MembershipPlanCard;
