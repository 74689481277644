import { useState } from 'react';
import { FetchCurrentUserDataInteractor, FetchFacilitiesInteractor } from '../interactors';

import { useRootStore } from '../providers/RootStoreProvider';
import {
  Api,
  CurrentUserService,
} from '../services';
import { EditAccountHolderView } from '../views';
import useErrorHandler from '../views/errors/useErrorHandler';
import EditAccountInteractor from '../interactors/EditAccountInteractor';
import { FacilitiesServiceFactory } from '../services/factories';

const useEditAccountHolderCoordinator = () => {
  const { currentUserStore, authStore, facilitiesStore } = useRootStore();
  const errorHandler = useErrorHandler();

  const createView = () => {
    const api = new Api( { authStore } );
    const currentUserService = new CurrentUserService( { api } );
    const fetchCurrentUserData = new FetchCurrentUserDataInteractor( {
      store: currentUserStore,
      service: currentUserService,
    } );
    const editAccountInteractor = new EditAccountInteractor();

    const facilitiesService = FacilitiesServiceFactory.forSameSports( { api } );
    const fetchFacilitiesInteractor = new FetchFacilitiesInteractor( {
      saveInStore: ( facilities ) => facilitiesStore.setSameSportFacilities( facilities ),
      service: facilitiesService,
    } );

    return new EditAccountHolderView( {
      currentUserStore,
      fetchCurrentUserData,
      errorHandler,
      editAccountInteractor,
      facilitiesStore,
      fetchFacilitiesInteractor,
    } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default useEditAccountHolderCoordinator;
