import React, { memo } from 'react';

import styles from './styles.module.scss';

interface DeleteBtnProps {
  onClick: ( index?: unknown ) => unknown;
}

const DeletePlayerBtn: React.FC<DeleteBtnProps> = (
  { onClick }: DeleteBtnProps,
) => (
  <div role="button" tabIndex={0} onClick={onClick} className={styles.deleteBtnContainer}>
    <div className={styles.deletePlayer} />
    <span className={styles.linkText}>Delete</span>
  </div>
);

export default memo( DeletePlayerBtn );
