import { useState } from 'react';
import { useRootStore } from '../providers/RootStoreProvider';
import { Api, CurrentUserService } from '../services';
import { FacilitiesServiceFactory } from '../services/factories';
import { FetchFacilitiesInteractor, SelectNewFacilityInteractor } from '../interactors';
import { ChangeFacilityView } from '../views';
import useErrorHandler from '../views/errors/useErrorHandler';

const useChangeFacilityCoordinator = () => {
  const { authStore } = useRootStore();
  const errorHandler = useErrorHandler();

  const api = new Api( { authStore } );

  const createView = () => {
    const facilitiesService = FacilitiesServiceFactory.forAllSports( { api } );
    const fetchFacilitiesInteractor = new FetchFacilitiesInteractor( {
      service: facilitiesService,
    } );

    const currentUserService = new CurrentUserService( { api } );
    const selectNewFacilityInteractor = new SelectNewFacilityInteractor( {
      service: currentUserService,
    } );

    return new ChangeFacilityView( {
      fetchFacilitiesInteractor,
      selectNewFacilityInteractor,
      errorHandler,
    } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default useChangeFacilityCoordinator;
