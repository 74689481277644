import React from 'react';
import { PromotionalBenefits } from '../../entities/MembershipPlan';
import styles from './styles.module.scss';

interface ListProps {
  benefits: PromotionalBenefits[];
}

const MembershipBenefitsList: React.FC<ListProps> = (
  { benefits }: ListProps,
) => (
  <div className={styles.membershipBenefitsListContainer}>
    {!!benefits.length && (
      benefits.map( ( { description } ) => (
        <div className={styles.benefitItemContainer} key={description}>
          <div className={styles.benefitItemIcon} />
          <p className="body1">{description}</p>
        </div>
      ) ) )}
  </div>
);

export default MembershipBenefitsList;
