import { makeAutoObservable, runInAction } from 'mobx';

import { MembershipPlan, Player } from '../entities';
import { SwishError } from '../errors';
import { EditPlayerInteractor, FetchMembershipPlansInteractor } from '../interactors';
import { CurrentUserStore, MembershipPlansStore } from '../stores';
import ErrorHandler from './errors/ErrorHandler';
import { IPlayerForm } from '../components/PlayerForm/PlayerForm.component';
import { PlayerData } from '../services/PlayersService';
import { parsePlayer } from '../helpers/formHelpers';

interface HomeViewParams {
  currentUserStore?: CurrentUserStore;
  membershipPlansStore?: MembershipPlansStore;
  editPlayerInteractor?: EditPlayerInteractor;
  fetchMembershipPlansInteractor?: FetchMembershipPlansInteractor;
  errorHandler?: ErrorHandler;
}

export default class EditPlayerView {
  private currentUserStore: CurrentUserStore;

  private membershipPlansStore: MembershipPlansStore;

  private editPlayerInteractor: EditPlayerInteractor;

  private fetchMembershipPlansInteractor: FetchMembershipPlansInteractor;

  player?: Player;

  editPlayerSuccessful = false;

  membershipPlans: MembershipPlan[] = [];

  errorHandler: ErrorHandler;

  constructor( {
    currentUserStore = new CurrentUserStore(),
    membershipPlansStore = new MembershipPlansStore(),
    editPlayerInteractor = new EditPlayerInteractor(),
    fetchMembershipPlansInteractor = new FetchMembershipPlansInteractor(),
    errorHandler = new ErrorHandler(),
  }: HomeViewParams = {} ) {
    this.currentUserStore = currentUserStore;
    this.membershipPlansStore = membershipPlansStore;
    this.editPlayerInteractor = editPlayerInteractor;
    this.fetchMembershipPlansInteractor = fetchMembershipPlansInteractor;
    this.errorHandler = errorHandler;
    this.player = undefined;
    makeAutoObservable( this );
  }

  get membershipPlan() {
    return this.membershipPlans.find(
      ( plan ) => plan.id === this.player?.membership.membershipPlanId,
    );
  }

  loadPlayer( playerId: number ) {
    this.player = this.currentUserStore.playerById( playerId );
  }

  loadMembershipPlans() {
    this.membershipPlans = this.membershipPlansStore.usersFacilityMembershipPlans || [];
  }

  async fetchMembershipPlans() {
    try {
      await this.fetchMembershipPlansInteractor.execute();
      this.loadMembershipPlans();
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }

  async editPlayer( playerInfo: IPlayerForm ) {
    this.editPlayerSuccessful = false;
    try {
      const playerData: PlayerData = parsePlayer( playerInfo );
      await this.editPlayerInteractor.execute( this.player!.id, playerData );
      runInAction( () => { this.editPlayerSuccessful = true; } );
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }
}
