import React from 'react';
import cn from 'classnames';
import { BackButton, Separator } from '../index';
import styles from './styles.module.scss';

interface BackArrowWithLabelProps {
  label?: string;
  onClick?: () => void;
}

const BackArrowWithLabel: React.FC<BackArrowWithLabelProps> = ( {
  label = 'Account',
  onClick,
} ) => (
  <section className={styles.container}>
    <h2 className={cn( 'header2', styles.previousSectionName )}>
      <BackButton className={styles.backbutton} onClick={onClick} />
      <span className={styles.text}>{label}</span>
    </h2>
    <Separator className={styles.separator} />
  </section>
);

export default BackArrowWithLabel;
