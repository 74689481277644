import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface SectionRowProps {
  valueName: string;
  value: string;
  onEditClick?: () => void;
}

const SectionRow: React.FC<SectionRowProps> = ( {
  valueName,
  value,
  onEditClick,
} ) => (
  <div className={styles.sectionRow}>
    <p className={styles.textContainer}>
      <span className={cn( styles.rowName, 'body2' )}>
        {`${valueName}:`}
      </span>
      <span className={cn( styles.rowValue, 'body1' )}>{value}</span>
    </p>

    {!!onEditClick && (
    <span
      tabIndex={0}
      role="button"
      onClick={onEditClick}
      className="linkText"
    >
      Edit
    </span>
    )}
  </div>
);

export default SectionRow;
