import { useState } from 'react';
import { useRootStore } from '../providers/RootStoreProvider';
import useErrorHandler from '../views/errors/useErrorHandler';
import {
  Api,
  MembershipPlansService,
  PlayersService,
  TotalChargesService,
} from '../services';
import {
  AssignMembershipPlanToPlayersInteractor,
  DeletePlayerInteractor,
  FetchMembershipPlansInteractor, FetchTotalChargesInteractor,
  RemoveMembershipFromPlayerInteractor,
} from '../interactors';
import PlayerView from '../views/PlayerView';
import ManageMembershipView from '../views/ManageMembershipView';
import { MembershipPlan } from '../entities';

const usePlayerCoordinator = () => {
  const {
    authStore,
    currentUserStore,
    membershipPlansStore,
    billingCycleStore,
  } = useRootStore();
  const errorHandler = useErrorHandler();

  const createView = () => {
    const api = new Api( { authStore } );
    const playersService = new PlayersService( { api } );

    const deletePlayerInteractor = new DeletePlayerInteractor( { service: playersService } );
    const assignMembershipPlansInteractor = new AssignMembershipPlanToPlayersInteractor( {
      service: playersService,
    } );

    const membershipPlansService = new MembershipPlansService( { api } );
    const removeMembershipFromPlayerInteractor = new RemoveMembershipFromPlayerInteractor(
      { service: playersService },
    );
    const fetchMembershipPlansInteractor = new FetchMembershipPlansInteractor( {
      store: ( membershipPlans : MembershipPlan[] ) => membershipPlansStore
        .setUsersFacilityMembershipPlans( membershipPlans ),
      fetch: () => membershipPlansService
        .fetchUsersFacilityMembershipPlans(),
    } );

    const totalChargesService = new TotalChargesService( { api } );
    const fetchTotalChargesInteractor = new FetchTotalChargesInteractor( {
      service: totalChargesService,
    } );

    const manageMembershipView = new ManageMembershipView( {
      currentUserStore,
      membershipPlansStore,
      billingCycleStore,
      assignMembershipPlansInteractor,
      errorHandler,
      fetchTotalChargesInteractor,
    } );

    return new PlayerView( {
      currentUserStore,
      membershipPlansStore,
      fetchMembershipPlansInteractor,
      errorHandler,
      removeMembershipFromPlayerInteractor,
      deletePlayerInteractor,
      manageMembershipView,
    } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default usePlayerCoordinator;
