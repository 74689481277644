import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as Trashfull } from '../../../assets/icons/trashfull/trashfull.svg';

interface DeleteAccountButtonProps {
  onClick?: () => void;
}

const DeleteAccountButton: React.FC<DeleteAccountButtonProps> = ( { onClick } ) => (
  <button
    type="button"
    className={cn( styles.deleteAccountButton, styles.linkText )}
    onClick={onClick}
  >
    <Trashfull className={styles.thrashfull} />
    <p className={styles.body1}>Delete account</p>
  </button>
);

export default DeleteAccountButton;
