import React, { memo } from 'react';

import cn from 'classnames';
import { Button } from '../../../../components';
import styles from './styles.module.scss';
import { ReactComponent as Sucessfull } from '../../../../assets/icons/success-icon/success-icon.svg';

interface AssignMembershipsBaseScreenProps {
  goToSeeMemberships?: () => void;
}

const AssignMembershipsBaseScreen: React.FC<AssignMembershipsBaseScreenProps> = (
  {
    goToSeeMemberships,
  }: AssignMembershipsBaseScreenProps,
) => (
  <div className={styles.container}>
    <Sucessfull />
    <h1 className={cn( styles.header, styles.megaHeader )}>
      {'Done! \n Now you can Assign \n Memberships to each of \n your players'}
    </h1>
    <Button
      label="CONTINUE"
      type="button"
      onClick={goToSeeMemberships}
      className={styles.baseScreenBtn}
    />
  </div>
);

export default memo( AssignMembershipsBaseScreen );
