import React, { memo } from 'react';

import {
  Button, InfoMessage, AssignableMembershipPlanCard,
} from '../../../components';
import { MembershipPlan } from '../../../entities';
import { SelectablePlayer } from '../../../views/CreatePlayersStep2View';
import styles from './styles.module.scss';

interface AssignMembershipFormProps {
  membershipPlans: MembershipPlan[];
  playersDropdownInfo: SelectablePlayer[];
  goToCart: () => void;
  onSelectPlayer: ( membershipId: number, selectedPlayer: SelectablePlayer ) => void;
  onRemovePlayer: ( removedPlayer: SelectablePlayer ) => void;
  onEarlyFinish: () => void;
}

const AssignmembershipsForm: React.FC<AssignMembershipFormProps> = (
  {
    membershipPlans,
    playersDropdownInfo,
    goToCart,
    onSelectPlayer,
    onRemovePlayer,
    onEarlyFinish,
  }: AssignMembershipFormProps,
) => {
  const handleSelectPlayer = ( membershipId: number ) => ( selectedPlayer: SelectablePlayer ) => {
    onSelectPlayer( membershipId, selectedPlayer );
  };

  const handleRemovePlayer = ( removedPlayer: SelectablePlayer ) => {
    onRemovePlayer( removedPlayer );
  };

  const noPlayersSelected = !playersDropdownInfo.some( ( { selected } ) => selected );

  const getButtonLabel = () => 'GO TO CART';

  return (
    <>
      <InfoMessage
        containerClassname={styles.infoMessageContainer}
        infoText="Select one membership for each of your players from the options below. By assigning more than one player, you’ll recieve a discount over the cheapest."
      />
      <div className={styles.membershipPlansContainer}>
        {membershipPlans.map( ( membership: MembershipPlan ) => (
          <div key={membership.id} className={styles.membershipPlan}>
            <AssignableMembershipPlanCard
              key={membership.id}
              membershipPlan={membership}
              players={playersDropdownInfo}
              onPlayerSelect={handleSelectPlayer( membership.id )}
              onPlayerRemove={handleRemovePlayer}
            />
          </div>
        ) )}
      </div>
      <Button
        className={styles.assignMembershipBtn}
        label={getButtonLabel()}
        loadingType="Oval"
        disabled={noPlayersSelected}
        onClick={goToCart}
      />
      <div className={styles.doItLaterLinkContainer}>
        <button type="submit" className="linkText" onClick={onEarlyFinish}>
          <p>Do it later</p>
        </button>
      </div>
    </>
  );
};

export default memo( AssignmembershipsForm );
