import { AxiosResponse } from 'axios';
import ErrorResponse from './ErrorResponse';

export default class ResponseDeserializer {
  protected response : AxiosResponse;

  constructor( response: AxiosResponse ) {
    this.response = response;
  }

  protected get responseBody() {
    return this.response.data.response;
  }

  protected get isResponseSuccessful() {
    return this.statusCode >= 200 && this.statusCode < 400;
  }

  protected get statusCode() {
    return this.response.status;
  }

  protected get errorResponse() {
    return new ErrorResponse( {
      statusCode: this.statusCode,
      name: this.response.data.error?.name,
      description: this.response.data.error?.detail,
    } );
  }

  deserializeResponse() {
    if ( this.isResponseSuccessful ) {
      return this.responseBody;
    }

    return this.errorResponse;
  }
}
