/* eslint-disable camelcase */
import { makeAutoObservable } from 'mobx';
import { BillingCycleStore, CurrentUserStore, MembershipPlansStore } from '../stores';
import {
  AssignMembershipPlanToPlayersInteractor,
  FetchTotalChargesInteractor,
} from '../interactors';
import ErrorHandler from './errors/ErrorHandler';
import { MembershipPlan, Player } from '../entities';
import CartView, { PlayerInCart } from './CartView';
import GetMembershipPlansFromStore from '../interactors/GetMembershipPlansFromStore';

interface MembershipManagerParams {
  currentUserStore?: CurrentUserStore;
  membershipPlansStore?: MembershipPlansStore;
  billingCycleStore?: BillingCycleStore;
  assignMembershipPlansInteractor?: AssignMembershipPlanToPlayersInteractor;
  errorHandler?: ErrorHandler;
  fetchTotalChargesInteractor?: FetchTotalChargesInteractor;
}

export default class ManageMembershipView {
  private currentUserStore: CurrentUserStore;

  private membershipPlansStore: MembershipPlansStore;

  private billingCycleStore: BillingCycleStore;

  private assignMembershipPlansInteractor: AssignMembershipPlanToPlayersInteractor;

  player?: Player;

  selectedPlanId: null | number;

  isInCartStep: boolean;

  isSubmitting: boolean;

  membershipPlans: MembershipPlan[] = [];

  errorHandler: ErrorHandler;

  cartView: CartView

  constructor( {
    currentUserStore = new CurrentUserStore(),
    membershipPlansStore = new MembershipPlansStore(),
    billingCycleStore = new BillingCycleStore(),
    assignMembershipPlansInteractor = new AssignMembershipPlanToPlayersInteractor(),
    errorHandler = new ErrorHandler(),
    fetchTotalChargesInteractor = new FetchTotalChargesInteractor(),
  }: MembershipManagerParams = {} ) {
    this.currentUserStore = currentUserStore;
    this.membershipPlansStore = membershipPlansStore;
    this.billingCycleStore = billingCycleStore;
    this.assignMembershipPlansInteractor = assignMembershipPlansInteractor;
    this.errorHandler = errorHandler;
    this.player = undefined;
    this.selectedPlanId = null;
    this.isInCartStep = false;
    this.isSubmitting = false;

    this.cartView = new CartView( {
      membershipPlansStore,
      billingCycleStore,
      currentUserStore,
      assignMembershipPlansInteractor,
      fetchTotalChargesInteractor,
      players: this.selectedPlayers,
      getMembershipPlansFromStore: new GetMembershipPlansFromStore( {
        plansStore: membershipPlansStore,
        getter: ( store ) => store.usersFacilityMembershipPlans ?? [],
      } ),
    } );

    makeAutoObservable( this );
  }

  get hasActiveMemberships() {
    return this.currentUserStore.currentUser?.anyPlayerHasMembership();
  }

  get selectedPlayers() : PlayerInCart[] {
    return [ {
      ...this.player as Player,
      membershipPlanId: this.selectedPlanId as number,
    } as PlayerInCart ];
  }

  get billingCycleOptions() {
    return this.billingCycles || [];
  }

  private get billingCycles() {
    return this.billingCycleStore.billingCycles;
  }

  get actionLabel() {
    return this.isInCartStep ? this.cartView.goToPayLabel : this.goToCartLabel;
  }

  loadPlayer( playerId: number ) {
    this.player = this.currentUserStore.playerById( playerId );
    this.cartView.players = this.selectedPlayers;
  }

  loadMembershipPlans() {
    this.membershipPlans = this.membershipPlansStore.usersFacilityMembershipPlans || [];
  }

  goToCart( planId: number ) {
    this.selectPlan( planId );
    this.cartView.players = this.selectedPlayers;
    this.isInCartStep = true;
  }

  private selectPlan( id: number ) {
    this.selectedPlanId = id;
  }

  private get goToCartLabel() {
    return 'Go to cart';
  }

  fetchTotalCharges() {
    this.cartView.fetchTotalCharges();
  }
}
