import React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { ReactComponent as HappyFace } from '../../../assets/icons/happy-face/happy-face.svg';
import { ReactComponent as Chevron } from '../../../assets/icons/chevron/chevron.svg';
import { PROMO_CODE_MANAGEMENT } from '../../../constants/routes';

const ManagePromoCodesButton : React.FC = () => {
  const history = useHistory();

  return (
    <button
      type="button"
      onClick={() => history.push( PROMO_CODE_MANAGEMENT )}
      className={cn( styles.playerCard, styles.billingAndPaymentCard, 'body1' )}
    >
      <span>
        <HappyFace />
        Manage Promo Codes
      </span>
      <Chevron className={styles.chevron} />
    </button>
  );
};
export default ManagePromoCodesButton;
