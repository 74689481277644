import { useState } from 'react';
import { SendRecoverPasswordLinkInteractor } from '../interactors';

import { Api, CurrentUserService } from '../services';
import { RecoverPasswordView } from '../views';

const useRecoverPasswordCoordinator = () => {
  const createView = () => {
    const api = new Api();
    const currentUserService = new CurrentUserService( { api } );
    const sendRecoverPasswordLinkInteractor = new SendRecoverPasswordLinkInteractor(
      { service: currentUserService },
    );

    return new RecoverPasswordView( { sendRecoverPasswordLinkInteractor } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default useRecoverPasswordCoordinator;
