/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals.js';
import AppProvider from './providers/AppProvider';

ReactDOM.render(
  <AppProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AppProvider>,
  document.getElementById( 'root' ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
ReactGA.initialize( process.env.REACT_APP_TRACKING_ID );
hotjar.initialize( process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION );
