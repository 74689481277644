import React from 'react';
import { Formik, FormikHelpers, FormikErrors } from 'formik';

import { Button, InputContainer } from '../../../components';
import styles from './styles.module.scss';

interface FormProps {
  onSubmit: (
    values: SetNewPasswordFormValues,
    helpers: Partial<FormikHelpers<SetNewPasswordFormValues>>,
  ) => void;
  validate: ( values: SetNewPasswordFormValues ) => FormikErrors<SetNewPasswordFormValues>;
}

export type SetNewPasswordFormValues = {
  password: string;
  confirmPassword: string;
};

const Form: React.FC<FormProps> = ( {
  onSubmit,
  validate,
}: FormProps ) => {
  const initialValues: SetNewPasswordFormValues = {
    password: '',
    confirmPassword: '',
  };

  const submitIsDisabled = (
    isSubmitting: boolean,
    values: SetNewPasswordFormValues,
    errors: FormikErrors<SetNewPasswordFormValues>,
  ) => isSubmitting
    || !values.password
    || !!errors.password
    || !values.confirmPassword
    || !!errors.confirmPassword;

  return (
    <>
      <h4 className={styles.header2}>Create new password</h4>
      <p className={styles.body1}>
        Your new password should be different from previous used passwords.
      </p>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {( {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
        } ) => (
          <form onSubmit={handleSubmit}>
            <InputContainer
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className={styles.caption1}
              label="New password"
              error={errors.password}
              touched={!!touched.password}
            />
            <InputContainer
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              className={styles.caption1}
              label="Confirm password"
              error={errors.confirmPassword}
              touched={!!touched.confirmPassword}
            />
            <Button
              className={styles.submitBtn}
              type="submit"
              label="reset password"
              disabled={submitIsDisabled( isSubmitting, values, errors )}
              loading={isSubmitting && !isValidating}
              loadingType="Oval"
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default Form;
