import React from 'react';
import { Redirect } from 'react-router-dom';

import { useRootStore } from '../providers/RootStoreProvider';
import { ROOT } from '../constants/routes';
import { useGetTokenFromURL } from '../hooks';

interface RequireAuthProps {
  children: JSX.Element;
}

const RequireAuth = ( { children }: RequireAuthProps ) => {
  const { authStore } = useRootStore();
  const isLoading = useGetTokenFromURL();

  if ( isLoading ) return null;

  return authStore.isAuthenticated
    ? children
    : <Redirect to={ROOT} />;
};

export default RequireAuth;
