import { parse } from 'date-fns';
import { Membership } from '.';
import { IPlayerForm } from '../components/PlayerForm/PlayerForm.component';
import { PlayerJson } from '../services/CurrentUserService';

interface PlayerProps {
  id: number;
  firstName: string;
  lastName: string;
  birthday: Date;
  school: string;
  grade: string;
  membership: Membership;
  membershipValidUntil?: Date;
}

export default class Player {
  id: number;

  firstName: string;

  lastName: string;

  birthday: Date;

  school: string;

  grade: string;

  membership: Membership;

  membershipValidUntil?: Date;

  constructor( {
    id,
    firstName,
    lastName,
    birthday,
    school,
    grade,
    membership,
    membershipValidUntil = undefined,
  }: PlayerProps ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.birthday = birthday;
    this.school = school;
    this.grade = grade;
    this.membership = membership;
    this.membershipValidUntil = membershipValidUntil;
  }

  static fromJSON = ( json: PlayerJson ) => new Player( {
    id: json.id,
    firstName: json.first_name,
    lastName: json.last_name,
    birthday: parse( json.birthday, 'yyyy-MM-dd', new Date() ),
    school: json.school,
    grade: json.grade,
    membership: json.membership_plan_version
      ? Membership.fromJSON( json.membership_plan_version )
      : Membership.empty(),
    membershipValidUntil: ( json.membership_valid_until )
      ? parse( json.membership_valid_until, 'yyyy-MM-dd', new Date() )
      : undefined,
  } )

  toForm() {
    return {
      name: this.firstName,
      lastName: this.lastName,
      birthday: this.birthday,
      school: this.school,
      gradeId: this.grade,
    } as IPlayerForm;
  }

  hasMembership = () => !this.membership.isNull();

  hasActiveMembership = () => !this.membershipValidUntil;
}
