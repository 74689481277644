import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { Checkbox } from '../index';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '../../constants/routes';
import styles from './styles.module.scss';

interface TermsAndConditionsChoiceProps {
  name: string,
  onClick?: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ( e: React.ChangeEvent<any> ): void;
  };
  areAccepted?: boolean;
}

const TermsAndConditionsChoice : React.FC<TermsAndConditionsChoiceProps> = (
  { areAccepted, ...props } : TermsAndConditionsChoiceProps,
) => {
  const history = useHistory();
  const checkboxId = 'termsAndConditions';

  return (
    <div className={styles.container}>
      <Checkbox
        id={checkboxId}
        isChecked={areAccepted}
        variant="squared"
        {...props}
      />
      <label
        className={cn( 'body1', styles.bottomLinks )}
        htmlFor={checkboxId}
      >
        {'By creating an account, you agree to our '}
        <span
          role="link"
          tabIndex={0}
          className={cn( styles.linkText, styles.nowrap )}
          onClick={() => history.push( PRIVACY_POLICY_URL )}
        >
          Privacy Notice
        </span>
        {' and '}
        <span
          role="link"
          tabIndex={0}
          className={cn( styles.linkText, styles.nowrap )}
          onClick={() => history.push( TERMS_AND_CONDITIONS_URL )}
        >
          Conditions of Use
        </span>
      </label>
    </div>
  );
};

export default TermsAndConditionsChoice;
