import { useState } from 'react';
import { FAQView } from '../views';

const useFAQCoordinator = () => {
  const createView = () => new FAQView();

  const [ viewInstance ] = useState( createView );

  return viewInstance;
};

export default useFAQCoordinator;
