import { makeAutoObservable, runInAction } from 'mobx';
import { CurrentUserStore, MembershipPlansStore } from '../stores';
import {
  DeletePlayerInteractor,
  FetchMembershipPlansInteractor,
  RemoveMembershipFromPlayerInteractor,
} from '../interactors';
import { MembershipPlan, Player } from '../entities';
import ErrorHandler from './errors/ErrorHandler';
import { SwishError } from '../errors';
import Times from '../helpers/Times';
import ManageMembershipView from './ManageMembershipView';

interface PlayerViewParams {
  currentUserStore?: CurrentUserStore;
  membershipPlansStore?: MembershipPlansStore;
  fetchMembershipPlansInteractor?: FetchMembershipPlansInteractor;
  errorHandler?: ErrorHandler;
  removeMembershipFromPlayerInteractor?: RemoveMembershipFromPlayerInteractor;
  deletePlayerInteractor?: DeletePlayerInteractor;
  manageMembershipView?: ManageMembershipView;
}

type playerScreenModal =
  null |
  'removeMembership' |
  'deletePlayer' |
  'upgradeMembership' |
  'renewMembership' |
  'downgradeMembership';

export default class PlayerView {
  private currentUserStore: CurrentUserStore;

  private membershipPlansStore: MembershipPlansStore;

  private fetchMembershipPlansInteractor: FetchMembershipPlansInteractor;

  private removeMembershipFromPlayerInteractor: RemoveMembershipFromPlayerInteractor;

  private deletePlayerInteractor: DeletePlayerInteractor;

  private times = new Times()

  manageMembershipView: ManageMembershipView;

  private activeModal: playerScreenModal = null;

  private clickedMembershipId: null | number = null;

  player?: Player;

  deletePlayerSuccessful = false;

  membershipPlans: MembershipPlan[] = [];

  errorHandler: ErrorHandler;

  constructor( {
    currentUserStore = new CurrentUserStore(),
    membershipPlansStore = new MembershipPlansStore(),
    fetchMembershipPlansInteractor = new FetchMembershipPlansInteractor(),
    errorHandler = new ErrorHandler(),
    removeMembershipFromPlayerInteractor = new RemoveMembershipFromPlayerInteractor(),
    deletePlayerInteractor = new DeletePlayerInteractor(),
    manageMembershipView = new ManageMembershipView(),
  }: PlayerViewParams = {} ) {
    this.currentUserStore = currentUserStore;
    this.membershipPlansStore = membershipPlansStore;
    this.fetchMembershipPlansInteractor = fetchMembershipPlansInteractor;
    this.errorHandler = errorHandler;
    this.deletePlayerInteractor = deletePlayerInteractor;
    this.player = undefined;
    this.removeMembershipFromPlayerInteractor = removeMembershipFromPlayerInteractor;
    this.manageMembershipView = manageMembershipView;
    makeAutoObservable( this );
  }

  get membershipPlan() {
    return this.findPlanById( this.currentPlanId! );
  }

  loadPlayer( playerId: number ) {
    this.player = this.currentUserStore.playerById( playerId );
    this.manageMembershipView.loadPlayer( playerId );
  }

  loadMembershipPlans() {
    this.membershipPlans = this.membershipPlansStore.usersFacilityMembershipPlans || [];
    this.manageMembershipView.loadMembershipPlans();
  }

  async fetchMembershipPlans() {
    try {
      await this.fetchMembershipPlansInteractor.execute();
      this.loadMembershipPlans();
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }

  async fetchData() {
    try {
      await Promise.all( [
        this.fetchMembershipPlans(),
      ] );
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }

  async removeMembership() {
    try {
      await this.removeMembershipFromPlayerInteractor.execute( this.player!.id );
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }

  get hasActiveMembership() {
    return this.player?.hasActiveMembership();
  }

  get membershipActiveUntil() {
    return ( this.player )
      ? this.times.dateToString( this.player.membershipValidUntil! )
      : '';
  }

  get membershipDueDate() {
    if ( !this.hasActiveMembership ) {
      return this.membershipActiveUntilToDisplay;
    }

    if ( this.userHasActiveMembership ) {
      return this.times.dateToString( this.dueDateFromAccountHolder );
    }

    return '';
  }

  private get userHasActiveMembership() {
    return this.currentUserStore.currentUser?.subscriptionValidUntil;
  }

  private get dueDateFromAccountHolder() {
    return this.currentUserStore.currentUser!.subscriptionValidUntil!;
  }

  get otherMembershipOptions() {
    return this.membershipPlans.filter(
      ( plan ) => ( plan.id !== this.currentPlanId && plan.published ),
    );
  }

  get isInCartStep() {
    return this.manageMembershipView.isInCartStep;
  }

  get isMembershipExpirationImminent() {
    return !this.hasActiveMembership
        && this.times.happensInTheNextSevenDays( this.membershipExpirationDate );
  }

  deletePlayer = async () => {
    this.deletePlayerSuccessful = false;
    try {
      await this.deletePlayerInteractor.execute( this.player!.id );
      runInAction( () => { this.deletePlayerSuccessful = true; } );
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }

  private assignNewMembership( planId: number ) {
    this.manageMembershipView.goToCart( planId );
  }

  private get membershipActiveUntilToDisplay() {
    return this.isMembershipExpirationImminent
      ? this.membershipExpirationInDays
      : this.membershipActiveUntil;
  }

  private get membershipExpirationDate() {
    return new Date( this.membershipActiveUntil );
  }

  private get membershipExpirationInDays() {
    return this.times.formatDistanceInDays( this.membershipExpirationDate );
  }

  private get currentPlanId() {
    return this.player?.membership?.membershipPlanId;
  }

  private setActiveModal( newActiveModal : playerScreenModal ) {
    this.activeModal = newActiveModal;
  }

  onMembershipClick = ( planId: number ) => {
    if ( !this.membershipPlan ) this.assignNewMembership( planId );
    else if ( !this.hasActiveMembership ) {
      this.setClickedMembership( planId );
      this.openCorrespondingChangeMembershipModal();
    }
  }

  private openCorrespondingChangeMembershipModal() {
    if ( this.clickedMembershipId === null ) return;
    if ( this.clickedMembershipIsAnUpgrade ) this.openUpgradeMembershipModal();
    else if ( this.clickedMembershipIsADowngrade ) this.openDowngradeMembershipModal();
    else this.openRenewMembershipModal();
  }

  private get clickedMembershipIsAnUpgrade() {
    const newPlan = this.findPlanById( this.clickedMembershipId! );
    return ( newPlan!.price > this.membershipPlan!.price );
  }

  private get clickedMembershipIsADowngrade() {
    const newPlan = this.findPlanById( this.clickedMembershipId! );
    return ( newPlan!.price < this.membershipPlan!.price );
  }

  get getSelectedMembershipName() {
    if ( this.clickedMembershipId === null ) return '';
    return this.findPlanById( this.clickedMembershipId )?.name || '';
  }

  private openDowngradeMembershipModal() {
    if ( this.activeModal === null ) this.setActiveModal( 'downgradeMembership' );
  }

  get isDowngradeMembershipModalOpen() {
    return this.activeModal === 'downgradeMembership';
  }

  closeDowngradeMembershipModal = () => {
    if ( this.activeModal === 'downgradeMembership' ) this.setActiveModal( null );
  }

  private openRenewMembershipModal() {
    if ( this.activeModal === null ) this.setActiveModal( 'renewMembership' );
  }

  get isRenewMembershipModalOpen() {
    return this.activeModal === 'renewMembership';
  }

  closeRenewMembershipModal = () => {
    if ( this.activeModal === 'renewMembership' ) this.setActiveModal( null );
  }

  private openUpgradeMembershipModal() {
    if ( this.activeModal === null ) this.setActiveModal( 'upgradeMembership' );
  }

  closeUpgradeMembershipModal = () => {
    if ( this.activeModal === 'upgradeMembership' ) this.setActiveModal( null );
  }

  get isUpgradeMembershipModalOpen() {
    return this.activeModal === 'upgradeMembership';
  }

  get isRemoveMembershipModalOpen() {
    return this.activeModal === 'removeMembership';
  }

  closeRemoveMembershipModal = () => {
    if ( this.activeModal === 'removeMembership' ) this.setActiveModal( null );
  }

  openRemoveMembershipModal = () => {
    if ( this.activeModal === null ) this.setActiveModal( 'removeMembership' );
  }

  openDeletePlayerModal = () => {
    if ( this.activeModal === null ) this.setActiveModal( 'deletePlayer' );
  }

  closeDeletePlayerModal = () => {
    if ( this.activeModal === 'deletePlayer' ) this.setActiveModal( null );
  }

  get isDeletePlayerModalOpen() {
    return this.activeModal === 'deletePlayer';
  }

  private setClickedMembership( id: number ) {
    this.clickedMembershipId = id;
  }

  changeMembershipToSelectedOne = () => {
    if ( this.clickedMembershipId !== null ) this.assignNewMembership( this.clickedMembershipId );
  }

  getChangeMembershipTitle() {
    if ( this.clickedMembershipId === null ) return '';

    const newPlan = this.findPlanById( this.clickedMembershipId );

    if ( newPlan!.price === this.membershipPlan!.price ) return 'Renew this membership';
    return 'Change membership';
  }

  getChangeMembershipMessage() {
    if ( this.clickedMembershipId === null ) return '';

    const newPlan = this.findPlanById( this.clickedMembershipId );

    if ( newPlan!.price > this.membershipPlan!.price ) return this.upgradeText( newPlan!.name );
    if ( newPlan!.price < this.membershipPlan!.price ) return this.downgradeText( newPlan!.name );
    return this.renewText( newPlan!.name );
  }

  private findPlanById( id: number ) {
    return this.membershipPlans.find(
      ( plan ) => plan.id === id,
    );
  }

  private upgradeText( planName: string ) {
    return `Great! You are about to upgrade to ${planName}. We'll take you to the cart to finish the payment`;
  }

  private downgradeText( planName: string ) {
    return `You are about to downgrade to ${planName}. Changes will apply instantly. This reassignment has a charge of $0.50.`;
  }

  private renewText( planName: string ) {
    return `You are about to renew ${planName}. Changes will apply instantly. This renewal has a charge of $0.50.`;
  }
}
