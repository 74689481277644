import React from 'react';

import { Separator } from '../index';
import styles from './styles.module.scss';

interface SectionTitleProps {
  title: string;
  actionLabel?: string;
  action?: () => unknown;
}

const SectionTitle: React.FC<SectionTitleProps> = ( { title, actionLabel, action } ) => (
  <div className={styles.sectionTitle}>
    <div className={styles.sectionTitleFirstRow}>
      <h4 className="subheader">{title}</h4>
      {!!action && (
        <button type="button" className="linkText" onClick={action}>
          {actionLabel}
        </button>
      )}
    </div>
    <Separator />
  </div>
);

export default SectionTitle;
