import { useState } from 'react';
import { FetchCurrentUserDataInteractor, GoToEditCardInteractor } from '../interactors';

import { useRootStore } from '../providers/RootStoreProvider';
import { Api, CurrentUserService } from '../services';
import { HomeView } from '../views';
import LogoutInteractor from '../interactors/LogoutInteractor';
import useErrorHandler from '../views/errors/useErrorHandler';

const useHomeCoordinator = () => {
  const { currentUserStore, authStore } = useRootStore();
  const errorHandler = useErrorHandler();

  const createView = () => {
    const api = new Api( { authStore } );
    const currentUserService = new CurrentUserService( { api } );
    const fetchCurrentUserData = new FetchCurrentUserDataInteractor( {
      store: currentUserStore,
      service: currentUserService,
    } );
    const logoutInteractor = new LogoutInteractor( { authStore } );
    const goToEditCardInteractor = new GoToEditCardInteractor( { service: currentUserService } );

    return new HomeView( {
      currentUserStore,
      fetchCurrentUserData,
      logoutInteractor,
      goToEditCardInteractor,
      errorHandler,
    } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default useHomeCoordinator;
