import React, { memo, HTMLInputTypeAttribute } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface InputProps {
  value?: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  placeholder: string;
  classname?: string;
  onChange: {
    ( e: React.ChangeEvent<HTMLInputElement> ): void;
  };
  onBlur: {
    ( e: React.FocusEvent<HTMLInputElement> ): void;
  };
  onPaste?: {
    ( e: React.ClipboardEvent<HTMLInputElement> ): boolean;
  };
}

const Input: React.FC<InputProps> = (
  {
    value,
    name,
    type,
    placeholder,
    classname,
    onChange,
    onBlur,
    onPaste,
    ...props
  }: InputProps,
) => (
  <input
    id={name}
    className={cn( styles.input, styles.body1, classname )}
    value={value}
    type={type}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    onBlur={onBlur}
    onPaste={onPaste}
    {...props}
  />
);

export default memo( Input );
