import { MembershipJson } from '../services/CurrentUserService';

export interface PlanBenefit {
  trainingTypeId: number;
  amount: number;
}

interface MembershipProps {
  id: number;
  name: string;
  price: string;
  durationInMonths: number;
  recurrence: boolean;
  description: string;
  versionNumber: number;
  membershipPlanId: number;
  benefits: PlanBenefit[];
}

export default class Membership {
  id: number;

  name: string;

  price: string;

  durationInMonths: number;

  recurrence: boolean;

  description: string;

  versionNumber: number;

  membershipPlanId: number;

  benefits: PlanBenefit[];

  constructor( {
    id,
    name,
    price,
    durationInMonths,
    recurrence,
    description,
    versionNumber,
    membershipPlanId,
    benefits,
  }: MembershipProps ) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.durationInMonths = durationInMonths;
    this.recurrence = recurrence;
    this.description = description;
    this.versionNumber = versionNumber;
    this.membershipPlanId = membershipPlanId;
    this.benefits = benefits;
  }

  static empty = () => new Membership( {
    id: 0,
    name: 'None',
    price: '0',
    durationInMonths: 0,
    recurrence: false,
    description: '',
    versionNumber: 0,
    membershipPlanId: 0,
    benefits: [],
  } );

  static fromJSON = ( json: MembershipJson ) => new Membership( {
    id: json.id,
    name: json.name,
    price: json.price,
    durationInMonths: json.duration_in_months,
    recurrence: json.recurrence,
    description: json.description,
    versionNumber: json.version_number,
    membershipPlanId: json.membership_plan_id,
    benefits: json.membership_benefits?.map(
      ( { training_type_id: trainingTypeId, amount } ) => ( { trainingTypeId, amount } ),
    ),
  } );

  isNull = () => this.id === 0;
}
