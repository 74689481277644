import { CurrentUserService } from '../services';

export default class SelectNewFacilityInteractor {
  service: CurrentUserService;

  constructor( { service = new CurrentUserService() } = {} ) {
    this.service = service;
  }

  execute( selectedFacilityId:number ) {
    return this.service.chooseNewFacility( selectedFacilityId );
  }
}
