import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { Icon } from '..';
import styles from './styles.module.scss';

interface BackButtonProps {
  className?: string;
  color?: 'black' | 'white';
  onClick?: () => void;
}

const BackButton: React.FC<BackButtonProps> = (
  {
    className,
    color = 'black',
    onClick,
  } : BackButtonProps,
) => {
  const history = useHistory();

  return (
    <Icon
      className={cn(
        styles.backButton,
        styles[color],
        className,
      )}
      name="back"
      onClick={( onClick ) || ( () => history.goBack() )}
    />
  );
};

export default BackButton;
