interface ExtraFeeProps {
  name:string;
  price:number;
  description:string;
}

export default class ExtraFee {
  name: string;

  price: number;

  description: string;

  constructor( {
    name,
    price,
    description,
  }: ExtraFeeProps ) {
    this.name = name;
    this.price = price;
    this.description = description;
  }

  static empty() {
    return new ExtraFee( { name: '', price: 0, description: '' } );
  }

  static fromJSON(
    extraFee?: { name: string, price: number, description: string },
  ) {
    return ( extraFee ) && new ExtraFee( {
      name: extraFee.name,
      price: extraFee.price,
      description: extraFee.description,
    } );
  }
}
