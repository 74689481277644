import { CurrentUserService } from '../services';

export default class LoginInteractor {
  service: CurrentUserService;

  constructor( { service = new CurrentUserService() } = {} ) {
    this.service = service;
  }

  execute( email: string, password: string ) {
    return this.service.login( email, password );
  }
}
