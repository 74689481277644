import React from 'react';
import cn from 'classnames';
import NoMembershipImage from '../../../assets/images/no-membership/no-membership.png';
import styles from './styles.module.scss';

const NoBillingInformationPlaceHolder : React.FC = () => (
  <div className={styles.noBillingInformationContainer}>
    <img
      src={NoMembershipImage}
      alt=""
      className={styles.noBillingInformationImage}
    />
    <p className={cn( 'body1', styles.noBillingInformationText )}>
      You currently don’t have any billing information
    </p>
  </div>
);

export default NoBillingInformationPlaceHolder;
