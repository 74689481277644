import React, { memo } from 'react';
import Loader, { LoaderProps } from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

interface LoadingProps {
  type?: LoaderProps['type'];
  color?: string;
}

const Loading: React.FC<LoadingProps> = (
  {
    color = 'white',
    type = 'Audio',
  }: LoadingProps,
) => (
  <Loader
    type={type}
    color={color}
    height={20}
    width={20}
  />
);

export default memo( Loading );
