import { CurrentUserService } from '../services';

export default class ValidateEmailInteractor {
  service: CurrentUserService;

  constructor( { service = new CurrentUserService() } = {} ) {
    this.service = service;
  }

  execute( email: string ) {
    return this.service.validateEmail( email );
  }
}
