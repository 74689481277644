import React from 'react';
import { Redirect } from 'react-router-dom';

import { useRootStore } from '../providers/RootStoreProvider';
import { HOME_URL } from '../constants/routes';

interface RequireUnauthProps {
  children: JSX.Element;
}

const RequireUnauth = ( { children }: RequireUnauthProps ) => {
  const { authStore } = useRootStore();

  return authStore.isAuthenticated
    ? <Redirect to={HOME_URL} />
    : children;
};

export default RequireUnauth;
