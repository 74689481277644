import { makeAutoObservable } from 'mobx';

import { Facility } from '../entities';

export default class FacilitiesStore {
  facilities?: Facility[];

  sameSportFacilities?: Facility[];

  constructor() {
    this.facilities = undefined;
    this.sameSportFacilities = undefined;
    makeAutoObservable( this );
  }

  setFacilities( facilities: Facility[] ) {
    this.facilities = facilities;
  }

  get( targetId: number ) {
    return this.facilities?.find( ( { id } ) => targetId === id );
  }

  setSameSportFacilities( sameSportFacilities: Facility[] ) {
    this.sameSportFacilities = sameSportFacilities;
  }
}
