import React, { memo } from 'react';
import cn from 'classnames';

import { Button } from '../../../../components';
import styles from './styles.module.scss';
import { ReactComponent as Sucessfull } from '../../../../assets/icons/success-icon/success-icon.svg';

interface PlayerBaseScreenProps {
  goToCreatePlayers: () => void;
}

const CreatePlayerBaseScreen: React.FC<PlayerBaseScreenProps> = (
  {
    goToCreatePlayers,
  }: PlayerBaseScreenProps,
) => (
  <div className={styles.container}>
    <Sucessfull />
    <h1 className={cn( styles.header, styles.megaHeader )}>
      {'Done!\nNow let\'s set up\nyour account'}
    </h1>
    <p className={cn( styles.description, styles.body1 )}>
      {/* tslint:disable-next-line:max-line-length */}
      {'In the next step you can configure your\nplayers so you can start booking classes\nfor them'}
    </p>
    <div className={styles.btnSection}>
      <Button label="CONTINUE" type="button" onClick={goToCreatePlayers} />
    </div>
  </div>
);

export default memo( CreatePlayerBaseScreen );
