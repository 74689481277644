import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import styles from './styles.module.scss';
import { WithHeader } from '../../hocs';
import {
  ResponsiveContainer, ConfirmationDialog, PromoCode,
} from '../../components';
import BackArrowWithLabel from '../../components/BackArrowWithLabel/BackArrowWithLabel.component';
import Disclaimer from './Component/Disclaimer';
import { usePromoCodeManagementCoordinator } from '../../view-coordinators';

const PromoCodeManagement: React.FC = () => {
  const view = usePromoCodeManagementCoordinator();

  useEffect( () => {
    view.fetchAccountHolderData();
  }, [ view ] );

  const changePromoCode = useCallback( ( newPromoCode: string ) => {
    view.changePromoCode( newPromoCode );
  }, [ view ] );

  const applyPromoCode = useCallback(
    () => view.applyPromoCode(),
    [ view ],
  );

  const deletePromoCodeDialogBody = (
    <p className={cn( 'body1', styles.dialogBody )}>
      You are about to detele an active promo code
    </p>
  );

  return (
    <section>
      <ResponsiveContainer sectionStyle={styles.container}>
        <BackArrowWithLabel label="Account" />
        <h2 className={cn( styles.megaHeader, styles.title )}>Manage Promo codes</h2>
        <div className={styles.promoCodeContainer}>
          <PromoCode
            applyPromoCode={applyPromoCode}
            onChangePromoCode={changePromoCode}
            resetPromoCode={view.openDeletePromoCodeConfirmationDialog}
            hasPromoCode={view.hasPromoCode}
            validUntil={view.promoCodeValidUntil}
            value={view.promoCode}
            invalidPromoCode={view.invalidPromoCode}
            validPromoCode={view.succesfullPromoCodeChange}
          />
        </div>
        <Disclaimer />
      </ResponsiveContainer>
      <ConfirmationDialog
        open={view.isDeletePromoCodeConfirmationDialogOpen}
        title="Delete promo code"
        body={deletePromoCodeDialogBody}
        confirmationButtonText="confirm"
        cancelButtonText="Cancel"
        onConfirm={view.onDeletePromoCodeConfirmation}
        onClose={view.closeDeletePromoCodeConfirmationDialog}
      />
    </section>
  );
};

export default WithHeader( observer( PromoCodeManagement ) );
