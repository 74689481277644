import React from 'react';
import cn from 'classnames';
import Alert from '../../../assets/icons/alert-triangle/alert-triangle.png';
import styles from '../styles.module.scss';

interface MembershipExpirationDateProps {
  date: string;
  isActive: boolean;
  isImminent: boolean;
}

const MembershipExpirationDate = ( {
  date,
  isActive = true,
  isImminent = false,
}: MembershipExpirationDateProps ) => {
  const activeText = 'Renews ';
  const inactiveText = isImminent ? 'Expires in ' : 'Expiration date: ';
  const text = isActive ? activeText : inactiveText;
  const leadingIcon = isImminent ? <img src={Alert} alt="warning" /> : null;

  return (
    <div className={cn( 'caption2', styles.expirationDate )}>
      {leadingIcon}
      {text}
      {date}
    </div>
  );
};

export default MembershipExpirationDate;
