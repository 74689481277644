import React from 'react';
import { Callback } from '.';
import styles from './styles.module.scss';

const TokenExpired: React.FC = () => (
  <Callback
    iconClassName={styles.circleCrossIcon}
    title="The recovery link is not valid"
    body="The link you requested has expired or is not valid. You can go back to the app and request a new one."
  />
);

export default TokenExpired;
