export default class AccessToken {
  token: string;

  refreshToken: string;

  tokenType: string;

  expiresIn: number;

  createdAt: number;

  constructor(
    token: string,
    refreshToken: string,
    tokenType: string,
    expiresIn: number,
    createdAt: number,
  ) {
    this.token = token;
    this.refreshToken = refreshToken;
    this.tokenType = tokenType;
    this.expiresIn = expiresIn;
    this.createdAt = createdAt;
  }

  static fromJSON = ( json: Record<string, never> ) => new AccessToken(
    json.access_token,
    json.refresh_token,
    json.token_type,
    json.expires_in * 1000,
    json.created_at * 1000,
  );
}
