import React, { memo } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface LabelProps {
  label: string;
  htmlFor?: string;
  className?: string;
}

const Label: React.FC<LabelProps> = (
  {
    label,
    className,
    htmlFor,
  }: LabelProps,
) => (
  <label
    className={cn( styles.body2, className )}
    htmlFor={htmlFor}
  >
    {label}
  </label>
);

export default memo( Label );
