import { CurrentUserService } from '../services';

export default class SendRecoverPasswordLinkInteractor {
  service: CurrentUserService;

  constructor( { service = new CurrentUserService() } = {} ) {
    this.service = service;
  }

  execute( email: string ) {
    return this.service.sendRecoverPasswordLink( email );
  }
}
