import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRootStore } from '../providers/RootStoreProvider';
import { useQuery } from './index';

const useGetTokenFromURL = () => {
  const [ isLoading, set ] = useState( true );
  const { authStore } = useRootStore();
  const queryParams = useQuery();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect( () => {
    const token = queryParams.get( 'token' );

    if ( token ) {
      authStore.setAccessTokenWithOnlyToken( token );
    }
    set( false );
    history.push( pathname );
    /* eslint-disable*/
  }, [] );
  /* eslint-enable */

  return isLoading;
};

export default useGetTokenFromURL;
