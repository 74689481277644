import React from 'react';
import cn from 'classnames';
import { Header } from '../../components';
import styles from './styles.module.scss';

const WithHeader = <P extends object>(
  Component: React.ComponentType<P>,
  {
    containerClassName,
    showLoginButton = true,
  }: { containerClassName?: string; showLoginButton?: boolean } = {},
): React.FC<P> => (
    ( props ) => (
      <div className={cn( styles.container, containerClassName )}>
        <Header showLoginButton={showLoginButton} />
        <Component {...props as P} />
      </div>
    ) );

export default WithHeader;
