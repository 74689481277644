import React, { memo } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface InfoMessageProps {
  infoText?: string;
  containerClassname?: string;
  textClassname?: string;
}

const InfoMessage: React.FC<InfoMessageProps> = (
  {
    infoText = '',
    containerClassname = '',
    textClassname = '',
  }: InfoMessageProps,
) => (
  <div className={cn( styles.container, containerClassname )}>
    <div className={styles.feedback} />
    <p className={cn( styles.caption1, styles.infoMessage, textClassname )}>{infoText}</p>
  </div>
);

export default memo( InfoMessage );
