import cn from 'classnames';
import React from 'react';
import { ReactComponent as Loop } from '../../../assets/icons/loop/loop.svg';
import styles from '../styles.module.scss';

interface LoopIconProps {
  className?: string,
  onClick: () => unknown,
}

const LoopIcon = ( { className, onClick, ...props } : LoopIconProps ) => (
  <Loop
    onClick={onClick}
    className={cn( styles.loopIcon, className )}
    role="button"
    {...props}
  />
);

export default LoopIcon;
