import Times from '../helpers/Times';
import { BillingInformation, PromoCode } from '.';

interface BillingInformationToDisplayProps {
  billingInformation : BillingInformation | null;
  promoCode : PromoCode | null;
}

class BillingInformationToDisplay {
  private billingInformation : BillingInformation | null;

  private promoCode: PromoCode | null;

  private times = new Times();

  constructor( {
    billingInformation,
    promoCode,
  } : BillingInformationToDisplayProps ) {
    this.billingInformation = billingInformation;
    this.promoCode = promoCode;
  }

  get hasBillingInformation() {
    return ( this.billingInformation !== null
      && this.billingInformation.hasBillingInformation ) || this.promoCode !== null;
  }

  get date() {
    if ( this.billingInformation === null ) return this.emptyValue;

    return this.billingInformation.date
      ? this.times.dateToString( this.billingInformation.date )
      : this.emptyValue;
  }

  get total() {
    if ( this.billingInformation === null ) return '$0';

    return `$${this.billingInformation.total}`;
  }

  get activeMemberships() {
    if ( this.billingInformation === null ) return this.emptyValue;

    return this.billingInformation.hasActiveMemberships
      ? this.billingInformation.activeMemberships.join( ', ' )
      : this.emptyValue;
  }

  get activePromoCode() {
    return this.promoCode
      ? this.promoCode.name
      : this.emptyValue;
  }

  get cardsLast4() {
    if ( this.billingInformation === null ) return this.emptyValue;

    return this.billingInformation.hasCardInformation
      ? `**** **** **** ${this.billingInformation.cardLast4}`
      : this.emptyValue;
  }

  private get emptyValue() {
    return 'None';
  }
}

export default BillingInformationToDisplay;
