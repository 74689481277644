import { History } from 'history';
import { SwishError } from '../../errors';
import { handleError } from '../../helpers/errors';
import { CHANGE_FACILITY_URL, ROOT } from '../../constants/routes';
import { AuthStore, CurrentUserStore } from '../../stores';

interface ErrorHandlerParams {
  currentUserStore?: CurrentUserStore;
  authStore?: AuthStore;
  history?: History;
}

export default class ErrorHandler {
  private currentUserStore: CurrentUserStore;

  private authStore: AuthStore;

  private history?: History;

  constructor( {
    currentUserStore = new CurrentUserStore(),
    authStore = new AuthStore(),
    history,
  } : ErrorHandlerParams = {} ) {
    this.currentUserStore = currentUserStore;
    this.authStore = authStore;
    this.history = history;
  }

  handleError( error: SwishError ) {
    if ( error.isUnauthorizedError() ) {
      this.handleAuthError();
    } else if ( error.isConflictError() ) {
      this.handleConflictError();
    } else {
      handleError( error );
    }
  }

  private handleAuthError() {
    this.authStore.removeAccessToken();
    this.currentUserStore.removeCurrentUser();
    this.history?.replace( ROOT );
  }

  private handleConflictError() {
    this.history?.replace( CHANGE_FACILITY_URL );
  }
}
