import React, { useCallback } from 'react';
import cn from 'classnames';
import { Formik } from 'formik';

import { FormDropdown, Button, Separator } from '../index';
import { getLabelFromDuration } from '../../helpers/discountHelpers';
import styles from './styles.module.scss';

export interface PaymentOptionForm {
  duration: string;
}

interface PaymentOptionProps {
  billingCycleOptions: number[];
  goToPayLabel: string;
  onSelectTerm: ( term: number ) => void;
  goToPay: () => Promise<void> | undefined;
  initialValue?: number;
}

const PaymentOption: React.FC<PaymentOptionProps> = ( {
  billingCycleOptions,
  goToPayLabel,
  onSelectTerm,
  goToPay,
  initialValue = 1,
}: PaymentOptionProps ) => {
  const initialValues: PaymentOptionForm = {
    duration: initialValue.toString(),
  };

  const getOptions = () => billingCycleOptions.map(
    ( billingOption ) => ( {
      id: billingOption,
      name: getLabelFromDuration( billingOption ),
    } ),
  );

  const onSubmit = () => {
    goToPay();
  };

  const handleTermChange = useCallback( ( handleChange ) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onSelectTerm( +e.target.value );
    handleChange( e );
  }, [ onSelectTerm ] );

  return (
    <div className={styles.sectionContainer}>
      <p className={cn( styles.subheader, styles.subtitle )}>Choose payment option</p>
      <Separator className={styles.separator} />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {( {
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
        } ) => (
          <form className={styles.paymentOptionForm} onSubmit={handleSubmit}>
            <FormDropdown
              onChange={handleTermChange( handleChange )}
              options={getOptions()}
              value={values.duration}
              dropdownId="duration"
            />
            <Button
              className={styles.submitBtn}
              type="submit"
              label={goToPayLabel}
              loading={isSubmitting}
              loadingType="Oval"
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentOption;
