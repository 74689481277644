import { makeObservable, observable, action } from 'mobx';

import {
  AccountHolder, BillingInformation, Facility, Membership, Player, PromoCode,
} from '../entities';
import { StorageService } from '../services';

export default class CurrentUserStore {
  storageService: StorageService;

  currentUser?: AccountHolder;

  private key = 'currentUser';

  constructor( storageService: StorageService = StorageService.localStorage() ) {
    this.storageService = storageService;
    this.currentUser = undefined;
    makeObservable( this, {
      currentUser: observable,
      assignCurrentUser: action,
      unassignCurrentUser: action,
    } );
    this.restoreCurrentUser();
  }

  private getPlayersFromJsonStore( playersJsonStore: Player[] ) {
    return playersJsonStore.map( ( playerJsonStore ) => new Player( {
      birthday: new Date( playerJsonStore.birthday ),
      membership: new Membership( playerJsonStore.membership ),
      membershipValidUntil: playerJsonStore.membershipValidUntil
          && new Date( playerJsonStore.membershipValidUntil ),
      id: playerJsonStore.id,
      firstName: playerJsonStore.firstName,
      lastName: playerJsonStore.lastName,
      school: playerJsonStore.school,
      grade: playerJsonStore.grade,
    } ) );
  }

  private restoreCurrentUser() {
    const currentUserStr = this.storageService.getItem( this.key );
    if ( currentUserStr ) {
      const currentUserJson = JSON.parse( currentUserStr );
      this.setCurrentUser( new AccountHolder( {
        id: currentUserJson.id,
        email: currentUserJson.email,
        firstName: currentUserJson.firstName,
        lastName: currentUserJson.lastName,
        phone: currentUserJson.phone,
        facility: new Facility( {
          id: currentUserJson.facility.id,
          name: currentUserJson.facility.name,
          address: currentUserJson.facility.address,
          sport: currentUserJson.facility.sport,
        } ),
        players: this.getPlayersFromJsonStore( currentUserJson.players ),
        subscriptionValidUntil: currentUserJson.subscriptionValidUntil
            && new Date( currentUserJson.subscriptionValidUntil ),
        promoCode: currentUserJson.promoCode
          ? new PromoCode( {
            discount: currentUserJson.promoCode.discount,
            name: currentUserJson.promoCode.name,
            expirationDate: currentUserJson.promoCode.expirationDate,
          } )
          : null,
        billingInformation: currentUserJson.billingInformation
          ? new BillingInformation( {
            date: currentUserJson.billingInformation.date
              && new Date( currentUserJson.billingInformation.date ),
            total: currentUserJson.billingInformation.total,
            activeMemberships: currentUserJson.billingInformation.activeMemberships,
            cardLast4: currentUserJson.billingInformation.cardLast4,
          } )
          : null,
      } ) );
    }
  }

  unassignCurrentUser() {
    this.currentUser = undefined;
  }

  assignCurrentUser( currentUser: AccountHolder ) {
    this.currentUser = currentUser;
  }

  removeCurrentUser() {
    this.storageService.removeItem( this.key );
    this.unassignCurrentUser();
  }

  setCurrentUser( currentUser: AccountHolder ) {
    this.storageService.setItem( this.key, JSON.stringify( currentUser ) );
    this.assignCurrentUser( currentUser );
  }

  playerById( playerId: number ) {
    return this.currentUser?.players.find( ( { id } ) => id === playerId );
  }
}
