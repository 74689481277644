import { FACILITIES_URL } from '../constants';
import { Facility } from '../entities';
import { Api } from '.';
import { FacilityJson } from './CurrentUserService';

export default class FacilitiesService {
  private facilitiesUrl: string;

  api: Api;

  builder: typeof Facility.fromJSON;

  constructor( {
    api = new Api(),
    builder = Facility.fromJSON,
    facilitiesUrl = FACILITIES_URL,
  } = {} ) {
    this.api = api;
    this.facilitiesUrl = facilitiesUrl;
    this.builder = builder;
  }

  async fetchFacilities() {
    const result: FacilityJson[] = await this.api.get( this.facilitiesUrl );

    return result.map(
      ( facility: FacilityJson ) => this.builder( facility ),
    );
  }
}
