import React from 'react';
import { Field } from 'formik';
import { Dropdown } from '..';
import { DropdownOption } from '../Dropdown/Dropdown.component';

interface FormDropdownProps {
  options: DropdownOption[];
  onChange?: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ( e: React.ChangeEvent<any> ): void;
  };
  onBlur?: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ( e: React.FocusEvent<any> ): void;
  };
  dropdownId?: string;
  index?: string;
  value? : string | number;
}

const FormDropdown: React.FC<FormDropdownProps> = (
  {
    dropdownId,
    index,
    ...props
  }: FormDropdownProps,
) => (
  <Field
    as={Dropdown}
    name={dropdownId || index}
    id={dropdownId}
    {...props}
  />
);

export default FormDropdown;
