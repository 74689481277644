import React from 'react';
import cn from 'classnames';
import { ConfirmationDialog } from '..';
import styles from './styles.module.scss';

interface UpgradeMembershipConfirmationDialogProps {
  open: boolean;
  membership: string;
  onConfirm: () => void;
  onClose: () => void;
}

const UpgradeMembershipConfirmationDialog :
  React.FC<UpgradeMembershipConfirmationDialogProps> = (
    { membership, ...props },
  ) => {
    const bodyComponent = (
      <p className={cn( 'body1', styles.dialogBodyText )}>
        Great! You are about to
        {' '}
        <b>upgrade</b>
        {' '}
        to
        {' '}
        <b>{membership}</b>
        . We&apos;ll take you to the cart to finish the payment.
      </p>
    );

    return (
      <ConfirmationDialog
        title="Change membership"
        body={bodyComponent}
        confirmationButtonText="go to cart"
        cancelButtonText="Cancel"
        {...props}
      />
    );
  };

export default UpgradeMembershipConfirmationDialog;
