import React from 'react';
import {
  Accordion,
  Separator,
  PricingInfo,
  MembershipBenefitsList,
} from '../..';
import { MembershipPlan } from '../../../entities';
import styles from '../styles.module.scss';

interface ExpandedContentProps {
  membershipPlan: MembershipPlan;
  accordionButtonClassName?: string
}

const ExpandedContent = ( { membershipPlan, accordionButtonClassName } : ExpandedContentProps ) => (
  <>
    <Separator className={styles.separator} />
    <Accordion buttonClassName={accordionButtonClassName}>
      <PricingInfo price={membershipPlan.price} />
      <MembershipBenefitsList benefits={membershipPlan.benefits} />
    </Accordion>
  </>
);

export default ExpandedContent;
