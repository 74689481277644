import React, { memo, HTMLInputTypeAttribute } from 'react';
import cn from 'classnames';
import {
  Input, Label, ErrorMessage, SuccessMessage,
} from '..';

import styles from './styles.module.scss';

interface InputContainerProps {
  type?: HTMLInputTypeAttribute;
  className?: string;
  label?: string;
  value: string;
  name: string;
  placeholder?: string;
  error?: string;
  touched: boolean;
  onChange: {
    ( e: React.ChangeEvent<HTMLInputElement> ): void;
  };
  onBlur: {
    ( e: React.FocusEvent<HTMLInputElement> ): void;
  };
  onPaste?: {
    ( e: React.ClipboardEvent<HTMLInputElement> ): boolean;
  };
  successText?: string;
  disabled?: boolean
  successClassName?: string;
  errorClassName?:string;
  containerClassName?: string;
}

const InputContainer: React.FC<InputContainerProps> = (
  {
    type = 'text',
    className = '',
    label = '',
    value,
    name,
    placeholder = '',
    error = '',
    touched,
    onChange,
    onBlur,
    successText = '',
    successClassName = '',
    errorClassName = '',
    containerClassName = '',
    onPaste,
    ...props
  }: InputContainerProps,
) => (
  <div className={cn( styles.inputContainer, containerClassName )}>
    <Input
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      classname={className}
      onPaste={onPaste}
      {...props}
    />
    <Label
      label={label}
      htmlFor={name}
      className={value ? styles.filled : ''}
    />
    <ErrorMessage errorText={error} touched={touched} className={errorClassName} />
    { ( !!successText )
        && <SuccessMessage successText={successText} className={successClassName} />}

  </div>
);

export default memo( InputContainer );
