import React from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import styles from './styles.module.scss';
import {
  BackButton, Button, BillingDetails, PromoCode, Separator,
} from '../../../components';
import CartView from '../../../views/CartView';

interface CartProps {
  view: CartView;
}

const SimpleCart = ( { view }: CartProps ) => (
  <div className={styles.sectionContainer}>
    <BackButton />
    <h2 className="header1">Cart</h2>
    <div className={styles.billingDetails}>
      <BillingDetails
        players={view.players}
        membershipPlans={view.membershipPlans}
        totalAmountHasDisclaimer
        totalCharges={view.totalCharges}
        extraFee={view.extraFee}
      />
    </div>
    <Separator className={styles.sectionSeparator} />

    {!view.userHasSavedPromoCode && (
    <>
      <PromoCode
        applyPromoCode={view.applyPromoCode}
        onChangePromoCode={view.changePromoCode}
        invalidPromoCode={view.invalidPromoCode}
        validPromoCode={view.validPromoCode}
        validUntil={view.promoCodeValidUntil}
        resetPromoCode={view.resetPromoCode}
        hasPromoCode={view.validPromoCode}
        value={view.promoCode}
      />
      <Separator className={styles.sectionSeparator} />
    </>
    )}
    <Button
      className={styles.action}
      onClick={() => {
        view.goToPay();
      }}
      label={view.goToPayLabel}
      loading={view.isSubmitting}
      loadingType="Oval"
    />
    <p className={cn( 'caption1', styles.disclaimer )}>
      *You will be charged the remaining days until your next billing cycle.
    </p>
  </div>
);

export default observer( SimpleCart );
