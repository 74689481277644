import React from 'react';
import { observer } from 'mobx-react-lite';

import { WithHeader } from '../../hocs';
import { useRecoverPasswordCoordinator } from '../../view-coordinators';
import { Form, Callback } from './components';
import styles from './styles.module.scss';

export type RecoverPasswordFormValues = {
  email: string;
};

const RecoverPassword: React.FC = () => {
  const view = useRecoverPasswordCoordinator();

  return (
    <section className={styles.container}>
      {view.renderCallback ? <Callback /> : (
        <Form
          onSubmit={view.sendRecoveryLink.bind( view )}
          validate={view.validateForm}
        />
      )}
    </section>
  );
};

export default WithHeader( observer( RecoverPassword ) );
