import { formatDistanceToNowStrict, isAfter, isBefore } from 'date-fns';

export default class Times {
  dateToString = ( date: Date ) => (
    new Intl.DateTimeFormat(
      'en',
      { month: '2-digit', day: '2-digit', year: 'numeric' },
    ).format( date )
  );

  getDateNDaysFromNow( n: number ) {
    const result = new Date();
    result.setDate( result.getDate() + n );
    return result;
  }

  formatDistanceInDays( date: Date ) {
    return formatDistanceToNowStrict(
      date,
      { unit: 'day', roundingMethod: 'ceil' },
    );
  }

  happensInTheNextSevenDays( date: Date ) {
    return isAfter( date, this.yesterday )
          && isBefore( date, this.aWeekFromNow );
  }

  private get yesterday() {
    return this.getDateNDaysFromNow( -1 );
  }

  private get aWeekFromNow() {
    return this.getDateNDaysFromNow( 8 );
  }
}
