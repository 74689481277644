import React from 'react';

import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { FAQ_URL } from '../../constants/routes';

const BottomLinks: React.FC = () => {
  const history = useHistory();

  return (
    <div className={styles.bottomSectionContainer}>
      <p className={styles.caption1}>Want to know more?</p>
      <button
        className={styles.linkText}
        type="button"
        onClick={() => { history.push( FAQ_URL ); }}
      >
        Visit our Frequently Asked Questions
      </button>
      <p className={styles.caption1}>
        {`Message ${process.env.REACT_APP_STAFF_PHONE_NUMBER}`}
      </p>
    </div>
  );
};

export default BottomLinks;
