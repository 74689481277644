/* eslint-disable react/button-has-type */
import React from 'react';
import cn from 'classnames';
import { LoaderProps } from 'react-loader-spinner';

import { Icon, Loading } from '../index';
import { IconNameType } from '../Icon/Icon.component';
import styles from './styles.module.scss';

type ButtonVariant = 'primary' | 'secondary';
type ButtonType = 'button' | 'submit' | 'reset';
type ButtonSize = 'large' | 'small';

interface ButtonProps {
  label?: string;
  disabled?: boolean;
  variant?: ButtonVariant;
  loading?: boolean;
  loadingType?: LoaderProps['type'];
  onClick?: () => unknown;
  type?: ButtonType;
  className?: string;
  size?: ButtonSize;
  icon?: IconNameType;
  buttonTextClassName?: string;
}

const Button: React.FC<ButtonProps> = ( {
  label,
  disabled = false,
  variant = 'primary',
  loading = false,
  loadingType = 'Audio',
  onClick,
  type = 'button',
  className,
  size = 'large',
  icon,
  buttonTextClassName,
}: ButtonProps ) => {
  const loadingColor = ( variant === 'primary' ) ? 'white' : 'black';

  const renderContent = () => {
    if ( loading ) {
      return <Loading type={loadingType} color={loadingColor} />;
    }
    if ( icon ) {
      return (
        <div className={styles.iconAndTextContainer}>
          <Icon name={icon} />
          <p className={buttonTextClassName}>{label}</p>
        </div>
      );
    }
    return label;
  };

  return (
    <button
      type={type}
      tabIndex={0}
      onClick={onClick}
      disabled={disabled || loading}
      className={cn(
        styles.btn,
        styles[variant],
        styles[size],
        className,
        size === 'large' ? 'buttonText' : 'body1',
      )}
    >
      {renderContent()}
    </button>
  );
};

export default Button;
