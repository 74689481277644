import { PlayersService } from '../services';
import { PlayerData } from '../services/PlayersService';

export default class CreatePlayersInteractor {
  service: PlayersService;

  constructor( {
    service = new PlayersService(),
  } = {} ) {
    this.service = service;
  }

  execute = ( playersInfo: PlayerData[] ) => this.service.createPlayers( playersInfo )
}
