export const getPriceAfterDiscount = (
  price: number,
  discount: number,
) => price * ( 1 - discount );

const roundTo2Decimals = ( num: number ) => Math.round( ( num ) * 100 ) / 100;

export const formatDiscount = ( discount: number ) => `-${roundTo2Decimals( discount * 100 )}%`;

export const getLabelFromDuration = ( duration: number ) => `${duration} Month${duration > 1 ? 's' : ''} / Recurring`;
