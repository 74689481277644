import React from 'react';

import LoginIcon from '../../assets/icons/login/login.svg';
import BackIcon from '../../assets/icons/back/back.svg';
import Trash from '../../assets/icons/trashfull/trashfull.svg';

// eslint-disable-next-line no-shadow
enum IconName { login, back, trash }
export type IconNameType = keyof typeof IconName;

const icons: { [ key in IconNameType ]: string } = {
  login: LoginIcon,
  back: BackIcon,
  trash: Trash,
};

interface IconProps {
  name: IconNameType;
  className?: string;
  onClick?: () => void;
}

const Icon: React.FC<IconProps> = ( { name, className, onClick } ) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  <img
    className={className}
    alt={`${name} icon`}
    src={icons[name]}
    onClick={onClick}
  />
);

export default Icon;
