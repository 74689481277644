import CurrencyJS, { Format } from 'currency.js';

const formatNoDecimals: Format = ( currency, options ) => {
  if ( !currency ) return '';
  if ( !options ) return '';
  // call original format function to get its string value
  const currencyString = CurrencyJS( currency ).format();

  if ( currency.cents() === 0 ) {
    // trim decimal and any trailing zeros
    return currencyString.slice( 0, currencyString.length - ( options.precision! + 1 ) );
  }

  return currencyString;
};

type Price = number | string | Currency;

export default class Currency {
  currency: CurrencyJS;

  constructor( price = 0 ) {
    this.currency = CurrencyJS( price );
  }

  get value() {
    return this.currency.value;
  }

  // typescript does not allow method overloading
  add( price: Price ) {
    if ( typeof price === 'number' || typeof price === 'string' ) {
      return new Currency( this.currency.add( price ).value );
    }
    if ( price instanceof Currency ) {
      return new Currency( this.currency.add( price.value ).value );
    }
    throw new Error( 'price should be a number, a string or a Currency' );
  }

  multiply( price: Price ) {
    if ( typeof price === 'number' || typeof price === 'string' ) {
      return new Currency( this.currency.multiply( price ).value );
    }
    if ( price instanceof Currency ) {
      return new Currency( this.currency.multiply( price.value ).value );
    }
    throw new Error( 'price should be a number, a string or a Currency' );
  }

  format() {
    return this.currency.format( formatNoDecimals );
  }
}
