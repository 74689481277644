import React from 'react';
import { WithHeader } from '../../hocs';
import successIcon from '../../assets/images/success-ball/success-ball.png';
import styles from './styles.module.scss';

const PaymentSuccess = () => (
  <section className={styles.container}>
    <img
      src={successIcon}
      alt="payment success icon"
      width={158}
    />
    <h4 className={styles.header2}>Payment completed</h4>
    <p className={styles.body1}>
      Your subscription was processed successfully.
      You can go to the app and start booking sessions.
    </p>
  </section>
);

export default WithHeader( PaymentSuccess, { containerClassName: styles.screenContainer } );
