import { makeAutoObservable, runInAction } from 'mobx';

import { SwishError } from '../errors';
import { FetchCurrentUserDataInteractor, GoToEditCardInteractor, LogoutInteractor } from '../interactors';
import { CurrentUserStore } from '../stores';
import ErrorHandler from './errors/ErrorHandler';
import { BillingInformationToDisplay } from '../entities';
import { HOME_URL } from '../constants/routes';

interface HomeViewParams {
  currentUserStore?: CurrentUserStore;
  fetchCurrentUserData?: FetchCurrentUserDataInteractor;
  logoutInteractor?: LogoutInteractor;
  goToEditCardInteractor?: GoToEditCardInteractor;
  errorHandler?: ErrorHandler;
}

export default class HomeView {
  private currentUserStore: CurrentUserStore;

  private fetchCurrentUserData: FetchCurrentUserDataInteractor;

  private logoutInteractor: LogoutInteractor;

  private goToEditCardInteractor: GoToEditCardInteractor;

  private errorHandler: ErrorHandler;

  gettingCardUrl = false;

  logoutSuccessful = false;

  constructor( {
    currentUserStore = new CurrentUserStore(),
    fetchCurrentUserData = new FetchCurrentUserDataInteractor(),
    logoutInteractor = new LogoutInteractor(),
    goToEditCardInteractor = new GoToEditCardInteractor(),
    errorHandler = new ErrorHandler(),
  }: HomeViewParams = {} ) {
    this.currentUserStore = currentUserStore;
    this.fetchCurrentUserData = fetchCurrentUserData;
    this.logoutInteractor = logoutInteractor;
    this.goToEditCardInteractor = goToEditCardInteractor;
    this.errorHandler = errorHandler;
    makeAutoObservable( this );

    this.getAccountHolderData();
  }

  private async getAccountHolderData() {
    try {
      await this.fetchCurrentUserData.execute();
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }

  get personalInformation() {
    return {
      name: this.currentUserStore.currentUser?.fullname ?? '',
      email: this.currentUserStore.currentUser?.email ?? '',
      phoneNumber: this.currentUserStore.currentUser?.phone ?? '',
      defaultFacility: this.currentUserStore.currentUser?.facility.name ?? '',
    };
  }

  get players() {
    return this.currentUserStore.currentUser?.players.map( ( player ) => ( {
      id: player.id,
      name: player.firstName,
      membership: player.membership.name,
    } ) ).sort( ( { name: a }, { name: b } ) => a.localeCompare( b ) ) ?? [];
  }

  get billingInformation() {
    return new BillingInformationToDisplay( {
      billingInformation: this.currentUserStore.currentUser
        ? this.currentUserStore.currentUser.billingInformation
        : null,
      promoCode: this.currentUserStore.currentUser
        ? this.currentUserStore.currentUser.promoCode
        : null,
    } );
  }

  async logout() {
    this.currentUserStore.removeCurrentUser();
    runInAction( () => { this.logoutSuccessful = true; } );
    try {
      await this.logoutInteractor.execute();
    } catch ( e ) { /* do nothing */ }
  }

  goToEditCard = async () => {
    if ( !this.gettingCardUrl ) {
      this.setIsGettingCardUrl( true );
      try {
        const goToEditUrl = await this.goToEditCardInteractor.execute(
          this.returnURL,
        );

        window.location.href = goToEditUrl;
      } catch ( error ) {
        this.errorHandler.handleError( error as SwishError );
      } finally {
        this.setIsGettingCardUrl( false );
      }
    }
  }

  private setIsGettingCardUrl( state:boolean ) {
    this.gettingCardUrl = state;
  }

  private get returnURL() {
    return `${process.env.REACT_APP_URL}${HOME_URL}`;
  }
}
