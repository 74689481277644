import { MembershipPlansStore } from '../stores';
import { MembershipPlan } from '../entities';

interface GetMembershipPlansFromStoreProps {
  plansStore?: MembershipPlansStore;
  getter?: ( store:MembershipPlansStore ) => MembershipPlan[];
}

export default class GetMembershipPlansFromStore {
  plansStore: MembershipPlansStore;

  getter: ( store:MembershipPlansStore ) => MembershipPlan[];

  constructor( {
    plansStore = new MembershipPlansStore(),
    getter = ( store:MembershipPlansStore ) => store.allFacilitiesMembershipPlans ?? [],
  }:GetMembershipPlansFromStoreProps = {} ) {
    this.plansStore = plansStore;
    this.getter = getter;
  }

  execute( ) {
    return this.getter( this.plansStore );
  }
}
