import Player from './Player';
import { IPlayerForm } from '../components/PlayerForm/PlayerForm.component';
import { DEFAULT_DATEPICKER_DATE } from '../constants/form';
import { PlayerData } from '../services/PlayersService';
import Membership from './Membership';

interface TemporalPlayerProps {
  id: number;
  name: string;
  lastName: string;
  birthday: Date;
  school: string;
  gradeId: string;
}

export default class TemporalPlayer {
  id: number;

  name: string;

  lastName: string;

  birthday: Date;

  school: string;

  gradeId: string;

  constructor( {
    id,
    name,
    lastName,
    birthday,
    school,
    gradeId,
  }: TemporalPlayerProps ) {
    this.id = id;
    this.name = name;
    this.lastName = lastName;
    this.birthday = birthday;
    this.school = school;
    this.gradeId = gradeId;
  }

  static fromPlayerForm = ( player:IPlayerForm, id:number ) => new TemporalPlayer(
    {
      id: ( id * -1 ),
      name: player.name,
      lastName: player.lastName,
      birthday: player.birthday,
      school: player.school,
      gradeId: player.gradeId,
    },
  )

  static empty = ( id:number ) => new TemporalPlayer( {
    id,
    name: '',
    lastName: '',
    birthday: new Date( DEFAULT_DATEPICKER_DATE ),
    school: '',
    gradeId: '',
  } )

  /* eslint-disable camelcase */
  parse(): PlayerData {
    return ( {
      first_name: this.name,
      last_name: this.lastName,
      birthday: this.birthday,
      school: this.school,
      grade: this.gradeId,
    } );
  }
  /* eslint-enable camelcase */

  static toPlayer = ( player: TemporalPlayer ) => new Player( {
    id: player.id,
    firstName: player.name,
    lastName: player.lastName,
    birthday: player.birthday,
    school: player.school,
    grade: player.gradeId,
    membership: Membership.empty(),
  } )
}
