import { makeAutoObservable, runInAction } from 'mobx';
import { CurrentUserStore } from '../stores';
import {
  DeleteAccountInteractor,
  FetchCurrentUserDataInteractor,
  FetchLeavingReasonsInteractor,
} from '../interactors';
import LogoutInteractor from '../interactors/LogoutInteractor';
import ErrorHandler from './errors/ErrorHandler';
import { SwishError } from '../errors';
import LeavingReason from '../entities/LeavingReason';

interface DeleteAccountViewParams {
  currentUserStore?: CurrentUserStore;
  fetchCurrentUserData?: FetchCurrentUserDataInteractor;
  logoutInteractor?: LogoutInteractor;
  fetchLeavingReasonsInteractor?: FetchLeavingReasonsInteractor;
  deleteAccountInteractor?: DeleteAccountInteractor;
  errorHandler?: ErrorHandler;
}

export default class DeleteAccountView {
  private currentUserStore: CurrentUserStore;

  private fetchCurrentUserData: FetchCurrentUserDataInteractor;

  private fetchLeavingReasonsInteractor: FetchLeavingReasonsInteractor;

  private deleteAccountInteractor: DeleteAccountInteractor;

  private errorHandler: ErrorHandler;

  selectableReasons: LeavingReason[] = [];

  selectedReasonId = 0;

  customReason = '';

  deletedSuccessful = false;

  constructor( {
    currentUserStore = new CurrentUserStore(),
    fetchCurrentUserData = new FetchCurrentUserDataInteractor(),
    deleteAccountInteractor = new DeleteAccountInteractor(),
    fetchLeavingReasonsInteractor = new FetchLeavingReasonsInteractor(),
    errorHandler = new ErrorHandler(),
  }: DeleteAccountViewParams = {} ) {
    this.currentUserStore = currentUserStore;
    this.fetchCurrentUserData = fetchCurrentUserData;
    this.fetchLeavingReasonsInteractor = fetchLeavingReasonsInteractor;
    this.deleteAccountInteractor = deleteAccountInteractor;
    this.errorHandler = errorHandler;

    this.selectReason = this.selectReason.bind( this );
    this.setCustomReason = this.setCustomReason.bind( this );

    makeAutoObservable( this );
  }

  async getAccountHolderData() {
    try {
      await this.fetchCurrentUserData.execute();
      await this.getLeavingReasons();
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }

  private async getLeavingReasons() {
    this.selectableReasons = await this.fetchLeavingReasonsInteractor.execute();
  }

  static get disclaimerText() {
    return 'Before you leave, please tell us why you are closing your account:';
  }

  static get customFeedbackText() {
    return 'Your feedback matters. Is there anything else you would like us to know?';
  }

  async deleteAccount() {
    const reasonId = this.selectedReasonId || undefined;
    const customReason = this.customReason || undefined;

    try {
      await this.deleteAccountInteractor.execute( reasonId, customReason );
      this.currentUserStore.removeCurrentUser();
      runInAction( () => { this.deletedSuccessful = true; } );
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }

  selectReason( reasonId: string ) {
    this.selectedReasonId = +reasonId;
  }

  setCustomReason( customReason:string ) {
    this.customReason = customReason;
  }

  get isConfirmationButtonDisabled() {
    return ( this.selectedReasonId === 0 ) && ( this.customReason === '' );
  }
}
