import cn from 'classnames';
import React from 'react';
import { ReactComponent as Trashfull } from '../../assets/icons/trashfull/trashfull.svg';
import styles from '../MembershipSection/styles.module.scss';

interface TrashIconProps {
  className?: string,
  onClick: () => unknown,
}

const TrashIcon = ( { className, onClick, ...props } : TrashIconProps ) => (
  <Trashfull
    onClick={onClick}
    className={cn( styles.trashIcon, className )}
    role="button"
    {...props}
  />
);

export default TrashIcon;
