import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { ErrorMessage, SuccessMessage } from '../index';

interface InputWithIconProps {
  value?: string;
  name: string;
  placeholder: string;
  classname?: string;
  disabled?:boolean;
  onChange: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ( e: React.ChangeEvent<HTMLInputElement> ): void;
  };
  onBlur: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ( e: React.FocusEvent<HTMLInputElement> ): void;
  };
  rightSection?: React.ReactNode,
  error?: string
  successText?: string;
}

const InputWithIcon: React.FC<InputWithIconProps> = ( {
  value,
  name,
  placeholder,
  classname,
  disabled = false,
  onChange,
  onBlur,
  rightSection,
  error = '',
  successText = '',
  ...props
}: InputWithIconProps ) => {
  const renderRightSection = () => ( !!rightSection ) && (
  <div className={styles.rightSection}>
    {rightSection}
  </div>
  );

  return (
    <div className={styles.container}>
      <div className={cn(
        styles.inputContainer,
        ( disabled ) && styles.disabledContainer,
      )}
      >
        <input
          id={name}
          type="text"
          className={cn(
            styles.input,
            styles.body1,
            classname,
          )}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          {...props}
        />
        {renderRightSection()}
      </div>
      <ErrorMessage errorText={error} touched />
      { ( !!successText )
            && <SuccessMessage successText={successText} />}
    </div>

  );
};

export default InputWithIcon;
