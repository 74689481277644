import {
  RootStoreI,
  AuthStore,
  CurrentUserStore,
  FacilitiesStore,
  MembershipPlansStore,
  BillingCycleStore,
} from '.';

export default class RootStore implements RootStoreI {
  authStore: AuthStore;

  currentUserStore: CurrentUserStore;

  facilitiesStore: FacilitiesStore;

  membershipPlansStore: MembershipPlansStore;

  billingCycleStore: BillingCycleStore;

  constructor() {
    this.authStore = new AuthStore();
    this.currentUserStore = new CurrentUserStore();
    this.facilitiesStore = new FacilitiesStore();
    this.membershipPlansStore = new MembershipPlansStore();
    this.billingCycleStore = new BillingCycleStore();
  }

  isInitialized = () => true;
}
