import React, { memo } from 'react';
import { MembershipPlan } from '../../../entities';
import { CompactMembershipPlanCard } from '../../index';
import TrashIcon from '../../TrashIcon/TrashIcon';
import LoopIcon from './LoopIcon';

interface ActiveMembershipPlanCardProps {
  activeMembershipPlan: MembershipPlan;
  onClickRemove?: () => unknown;
  isActive?: boolean;
  isExpirationImminent?: boolean;
  activeUntil?: string;
  onMembershipClick?: ( planId: number ) => unknown;
}

const ActiveMembershipPlanCard = ( {
  activeMembershipPlan,
  onClickRemove = undefined,
  isActive = false,
  isExpirationImminent = false,
  activeUntil = undefined,
  onMembershipClick,
} : ActiveMembershipPlanCardProps ) => {
  const DeleteIcon = memo( ( props ) => (
    <TrashIcon
      onClick={() => onClickRemove && onClickRemove()}
      aria-label="delete"
      {...props}
    />
  ) );

  const RenewIcon = memo( ( props ) => ( ( activeMembershipPlan.published ) ? (
    <LoopIcon
      onClick={() => onMembershipClick && onMembershipClick( activeMembershipPlan.id )}
      aria-label="renew"
      {...props}
    />
  ) : null ) );

  const Icon = isActive ? DeleteIcon : RenewIcon;

  return (
    <CompactMembershipPlanCard
      membershipPlan={activeMembershipPlan}
      expandable
      isExpirationImminent={isExpirationImminent}
      activeUntil={activeUntil}
      Icon={Icon}
      status={isActive ? 'active' : 'cancelled'}
    />
  );
};

export default ActiveMembershipPlanCard;
