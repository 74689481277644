import { PlayersService } from '../services';

export default class DeletePlayerInteractor {
  service: PlayersService;

  constructor( { service = new PlayersService() } = {} ) {
    this.service = service;
  }

  execute( playerId: number ) {
    return this.service.deletePlayer( playerId );
  }
}
