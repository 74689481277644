import React, { memo } from 'react';

import { formatDiscount } from '../../../helpers/discountHelpers';
import styles from '../styles.module.scss';

interface DiscountLabelProps {
  discount: number;
}

const DiscountLabel: React.FC<DiscountLabelProps> = ( {
  discount,
}: DiscountLabelProps ) => (
  <div className={styles.discountLabelContainer}>
    <span className={styles.caption2}>{formatDiscount( discount )}</span>
  </div>
);

export default memo( DiscountLabel );
