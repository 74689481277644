import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import React, { forwardRef } from 'react';

const TooltipWithMaxWidth = styled( forwardRef(
  ( { className, children, ...props }: TooltipProps, ref ) => (
    <Tooltip {...props} classes={{ popper: className }} ref={ref}>
      {children}
    </Tooltip>
  ),
) )( {
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
  },
} );

export default TooltipWithMaxWidth;
