/* eslint-disable camelcase */
import { makeAutoObservable } from 'mobx';
import ReactGA from 'react-ga';

import {
  FetchFacilitiesInteractor,
  CreateAccountInteractor,
  CreatePlayersInteractor,
  FetchCurrentUserDataInteractor,
  AssignMembershipPlanToPlayersInteractor,
  FetchTotalChargesInteractor,
  FetchMembershipPlansInteractor, ValidateEmailInteractor,
  GetMembershipPlansFromStore,
} from '../interactors';
import { BillingCycleStore, FacilitiesStore, MembershipPlansStore } from '../stores';
import { SwishError } from '../errors';
import { handleError } from '../helpers/errors';
import { AccountHolderFormValues, defaultInitialValues } from '../screens/CreateAccount/components/CreateAccountForm';
import { CreatePlayersStep2View } from '.';
import { SelectablePlayer } from './CreatePlayersStep2View';
import CartView, { PlayerInCart } from './CartView';
import ErrorHandler from './errors/ErrorHandler';
import { IPlayerForm } from '../components/PlayerForm/PlayerForm.component';
import TemporalPlayer from '../entities/TemporalPlayer';
import { Player } from '../entities';
import { PlayerJson } from '../services/CurrentUserService';

interface CreateAccountViewParams {
  fetchCurrentUserDataInteractor?: FetchCurrentUserDataInteractor;
  fetchFacilitiesInteractor?: FetchFacilitiesInteractor;
  createAccountInteractor?: CreateAccountInteractor;
  createPlayersInteractor?: CreatePlayersInteractor;
  assignMembershipPlansInteractor?: AssignMembershipPlanToPlayersInteractor;
  fetchMembershipPlansInteractor?: FetchMembershipPlansInteractor;
  facilitiesStore?: FacilitiesStore;
  membershipPlansStore?: MembershipPlansStore;
  billingCycleStore?: BillingCycleStore;
  errorHandler?: ErrorHandler;
  fetchTotalChargesInteractor?: FetchTotalChargesInteractor;
  validateEmailInteractor?: ValidateEmailInteractor;
}

export interface ErrorValues {
  email: string;
}

export default class CreateAccountView {
  private fetchCurrentUserDataInteractor: FetchCurrentUserDataInteractor;

  private fetchFacilitiesInteractor: FetchFacilitiesInteractor;

  private createAccountInteractor: CreateAccountInteractor;

  private createPlayersInteractor: CreatePlayersInteractor;

  private assignMembershipPlansInteractor: AssignMembershipPlanToPlayersInteractor;

  private fetchTotalChargesInteractor: FetchTotalChargesInteractor;

  private validateEmailInteractor: ValidateEmailInteractor;

  private facilitiesStore: FacilitiesStore;

  private step2View: CreatePlayersStep2View;

  accountInformation?: AccountHolderFormValues = defaultInitialValues;

  playersInformation: TemporalPlayer[] = [ TemporalPlayer.empty( 0 ) ];

  isFetchingData = true;

  isCreatingAccount = false;

  isCreatingPlayer = false;

  createAccountSuccessfullyCompleted = false;

  createPlayerSuccessfullyCompleted = false;

  currentStep = 0;

  isThePlayer = false;

  errors: ErrorValues = { email: '' };

  createdPlayers: Player[] = [];

  cartView : CartView

  redirectToHome = false;

  constructor( {
    fetchCurrentUserDataInteractor = new FetchCurrentUserDataInteractor(),
    fetchFacilitiesInteractor = new FetchFacilitiesInteractor(),
    createAccountInteractor = new CreateAccountInteractor(),
    createPlayersInteractor = new CreatePlayersInteractor(),
    assignMembershipPlansInteractor = new AssignMembershipPlanToPlayersInteractor(),
    fetchMembershipPlansInteractor = new FetchMembershipPlansInteractor(),
    facilitiesStore = new FacilitiesStore(),
    membershipPlansStore = new MembershipPlansStore(),
    billingCycleStore = new BillingCycleStore(),
    errorHandler = new ErrorHandler(),
    fetchTotalChargesInteractor = new FetchTotalChargesInteractor(),
    validateEmailInteractor = new ValidateEmailInteractor(),
  }: CreateAccountViewParams ) {
    this.fetchCurrentUserDataInteractor = fetchCurrentUserDataInteractor;
    this.fetchFacilitiesInteractor = fetchFacilitiesInteractor;
    this.createAccountInteractor = createAccountInteractor;
    this.createPlayersInteractor = createPlayersInteractor;
    this.assignMembershipPlansInteractor = assignMembershipPlansInteractor;
    this.facilitiesStore = facilitiesStore;
    this.fetchTotalChargesInteractor = fetchTotalChargesInteractor;
    this.validateEmailInteractor = validateEmailInteractor;

    this.step2View = new CreatePlayersStep2View( {
      membershipPlansStore,
      billingCycleStore,
      errorHandler,
      fetchMembershipPlansInteractor,
    } );

    this.cartView = new CartView( {
      assignMembershipPlansInteractor,
      fetchTotalChargesInteractor,
      membershipPlansStore,
      billingCycleStore,
      players: this.step2View.selectablePlayers as PlayerInCart[],
      onConfirm: () => this.logStep( 'confirmed cart' ),
      getMembershipPlansFromStore: new GetMembershipPlansFromStore( {
        plansStore: membershipPlansStore,
        getter: ( store:MembershipPlansStore ) => store.allFacilitiesMembershipPlans ?? [],
      } ),
    } );

    makeAutoObservable( this );

    this.fetchData();
  }

  private fetchingData() {
    this.isFetchingData = true;
  }

  private fetchingDataFinished() {
    this.isFetchingData = false;
  }

  private fetchData = async () => {
    this.fetchingData();

    try {
      await this.fetchFacilitiesInteractor.execute();
    } catch ( error ) {
      handleError( error as SwishError );
    } finally {
      this.fetchingDataFinished();
    }
  };

  private creatingPlayers() {
    this.isCreatingPlayer = true;
  }

  private creatingPlayersFinished() {
    this.isCreatingPlayer = false;
  }

  private creatingAccount() {
    this.isCreatingAccount = true;
  }

  private creatingAccountFinished() {
    this.isCreatingAccount = false;
  }

  private getNextStep() {
    this.currentStep += 1;
  }

  getStep = ( step:number ) => {
    this.currentStep = step;
  }

  goToAccountInformation() {
    this.getStep( 0 );
  }

  goToPlayersInformation() {
    this.getStep( 2 );
  }

  goToAssignMemberships() {
    this.getStep( 4 );
  }

  private logStep( name: string ) {
    ReactGA.event( {
      category: 'User',
      action: `Create account step: ${name}`,
    } );
  }

  private addSelectedPlayersToCart() {
    this.cartView.players = this.step2View.selectablePlayers
      .filter( ( player ) => player.selected ) as unknown as PlayerInCart[];
  }

  get selectablePlayers() {
    return this.step2View.selectablePlayers;
  }

  get billingCycles() {
    return this.step2View.billingCycles;
  }

  get facilities() {
    return this.facilitiesStore.facilities?.filter( ( facility ) => ( facility.sport === 'Basketball' ) ) ?? [];
  }

  get errorValues() {
    return this.errors;
  }

  get membershipPlans() {
    return this.step2View.membershipPlans;
  }

  goToCreatePlayers = () => {
    this.getNextStep();
  };

  goToSeeMemberships = async () => {
    await this.step2View.fetchData();
    this.getNextStep();
  };

  goToCart = () => {
    this.addSelectedPlayersToCart();
    this.fetchTotalCharges();
    this.getNextStep();
  };

  handleSelectPlayer = ( membershipPlanId: number, selectedPlayer: SelectablePlayer ) => {
    this.step2View.handleSelectPlayer( membershipPlanId, selectedPlayer );
  };

  handleRemovePlayer = ( removedPlayer: SelectablePlayer ) => {
    this.step2View.handleRemovePlayer( removedPlayer );
  };

  fetchTotalCharges() {
    this.cartView.fetchTotalCharges();
  }

  saveAccountInformation = async ( accountInformation: AccountHolderFormValues ) => {
    try {
      await this.validateEmailInteractor.execute( accountInformation.email );

      this.setAccountInformation( accountInformation );

      this.getNextStep();
    } catch ( e ) {
      const error = e as SwishError;
      if ( error.isInputError() ) {
        this.errors.email = 'This email is already in use';
      } else {
        handleError( error );
      }
    }
  }

  private setAccountInformation( accountInformation: AccountHolderFormValues ) {
    this.accountInformation = accountInformation;
  }

  savePlayersInformation = ( playersInfo: IPlayerForm[] ) => {
    this.playersInformation = playersInfo.map(
      (
        playerInfo,
        index,
      ): TemporalPlayer => TemporalPlayer.fromPlayerForm( playerInfo, index + 1 ),
    );
    this.initSelectablePlayers();

    this.getNextStep();
  }

  private initSelectablePlayers() {
    this.step2View.initSelectablePlayers(
      this.playersInformation.map( TemporalPlayer.toPlayer ),
    );

    this.step2View.selectFacility( Number( this.accountInformation!.facilityId ) );
  }

  earlyFinishAccountCreation = async () => {
    await this.createParentAccount();

    await this.createPlayers();
  }

  createParentAccount = async () => {
    this.creatingAccount();

    try {
      await this.createAccountInteractor.execute( this.accountInformation! );

      this.logStep( 'account created' );
    } catch ( e ) {
      const error = e as SwishError;
      if ( error.isInputError() ) {
        this.redirectToHome = true;
      } else {
        handleError( error );
      }
    } finally {
      this.creatingAccountFinished();
    }
  }

  createPlayers = async () => {
    this.creatingPlayers();

    try {
      this.setCreatedPlayers(
        await this.createPlayersInteractor.execute( this.parsedPlayersData ),
      );
      this.logStep( 'players created' );
    } catch ( error ) {
      const e = error as SwishError;
      handleError( e );
    } finally {
      this.creatingPlayersFinished();
    }
  }

  private get parsedPlayersData() {
    return this.playersInformation.map(
      ( temporalPlayer ) => temporalPlayer.parse(),
    );
  }

  private setCreatedPlayers( createdPlayers:PlayerJson[] ) {
    this.createdPlayers = createdPlayers.map( ( playerJson ) => Player.fromJSON( playerJson ) );
  }

  beforeGoToPay = async () => {
    await this.createParentAccount();

    await this.createPlayers();

    this.changeTemporaryPlayerIdForPermanentId();
  }

  private changeTemporaryPlayerIdForPermanentId() {
    this.cartView.players = this.cartView.players.map(
      ( player ) => {
        const oldPlayerId = player.id;
        const newPlayerId = this.createdPlayers.find( ( createdPlayer ) => (
          player.firstName === createdPlayer.firstName
              && player.lastName === createdPlayer.lastName
        ) )!.id;

        this.cartView.totalCharges.changePlayerIdInLines( oldPlayerId, newPlayerId );

        player.id = newPlayerId;
        return player;
      },
    );
  }
}
