import { useState } from 'react';
import { useRootStore } from '../providers/RootStoreProvider';
import useErrorHandler from '../views/errors/useErrorHandler';
import { Api, CurrentUserService } from '../services';
import {
  AssignPromoCodeInteractor,
  FetchCurrentUserDataInteractor,
  UnassignPromoCodeInteractor,
} from '../interactors';
import PromoCodeManagementView from '../views/PromoCodeManagementView';

const usePromoCodeManagementCoordinator = () => {
  const { currentUserStore, authStore } = useRootStore();
  const errorHandler = useErrorHandler();

  const createView = () => {
    const api = new Api( { authStore } );
    const service = new CurrentUserService( { api } );
    const fetchCurrentUserData = new FetchCurrentUserDataInteractor( {
      service,
      store: currentUserStore,
    } );

    const assignPromoCodeInteractor = new AssignPromoCodeInteractor( { service } );

    const unassignPromoCodeInteractor = new UnassignPromoCodeInteractor( { service } );

    return new PromoCodeManagementView( {
      currentUserStore,
      fetchCurrentUserData,
      assignPromoCodeInteractor,
      unassignPromoCodeInteractor,
      errorHandler,
    } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default usePromoCodeManagementCoordinator;
