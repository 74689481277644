/* eslint-disable camelcase */
import { FormikErrors } from 'formik';

import {
  PHONE_NUMBER_CRITERIA,
  EMAIL_CRITERIA,
  DEFAULT_GRADE_NAME,
} from '../constants/form';
import { AccountHolderFormValues } from '../screens/CreateAccount/components/CreateAccountForm';
import { IPlayerForm, IPlayerFormErrors, PlayersFormValues } from '../components/PlayerForm/PlayerForm.component';
import { PlayerData } from '../services/PlayersService';

export interface Passwords {
  password: string;
  confirmPassword: string;
}

export const validatePasswords = (
  values: Passwords,
  errors: Passwords,
) => {
  if ( !values.password ) errors.password = 'Required';
  if ( values.password.length > 0 && values.password.length < 6 ) {
    errors.password = 'Password is too short (minimum 6 characters)';
  }
  if ( !values.confirmPassword ) errors.confirmPassword = 'Required';
  if (
    values.password
      && values.confirmPassword
      && ( values.password !== values.confirmPassword )
  ) {
    errors.confirmPassword = 'Passwords must match';
  }
};

interface Emails {
  email: string;
  confirmEmail: string;
}

const validateEmails = (
  values: Emails,
  errors: Emails,
  emailError?: string | void,
) => {
  if ( !values.email ) errors.email = 'Required';
  else if (
    !EMAIL_CRITERIA.test( values.email )
  ) {
    errors.email = 'Invalid email address';
  } else if ( !values.confirmEmail ) {
    errors.confirmEmail = 'Required';
  } else if (
    values.email
      && values.confirmEmail
      && ( values.email !== values.confirmEmail )
  ) {
    errors.confirmEmail = 'Emails must match';
  } else if ( emailError ) errors.email = emailError;
};

export const errorValidationsAccount = (
  values: AccountHolderFormValues,
  emailError?: string | void,
) => {
  const errors: FormikErrors<AccountHolderFormValues> = {};
  if ( !values.firstName ) errors.firstName = 'Required';
  if ( !values.lastName ) errors.lastName = 'Required';

  validateEmails( values, errors as Emails, emailError );

  if ( !values.phone ) errors.phone = 'Required';
  else if (
    !PHONE_NUMBER_CRITERIA.test( values.phone )
  ) {
    errors.phone = 'Invalid phone number';
  }

  validatePasswords( values, errors as Passwords );

  if ( values.facilityId === '0' ) errors.facilityId = 'Required';

  return errors;
};

const anyPlayerFieldHasError = ( errors: FormikErrors<IPlayerFormErrors>[] ) => errors.some(
  ( playerErrors ) => Object.keys( playerErrors ).length,
);

export const errorValidationsPlayers = ( playersFormValues: PlayersFormValues ) => {
  const errors: FormikErrors<{ players: IPlayerFormErrors[] }> = {};
  errors.players = playersFormValues.players.map( ( player ) => {
    const playerErrors: FormikErrors<IPlayerFormErrors> = {};
    if ( !player.name || player.name === '' ) playerErrors.name = 'Required';
    if ( !player.lastName || player.lastName === '' ) playerErrors.lastName = 'Required';
    if ( !player.birthday ) playerErrors.birthday = 'Required';
    if ( !player.school || player.school === '' ) playerErrors.school = 'Required';
    if ( !player.gradeId || player.gradeId === DEFAULT_GRADE_NAME ) playerErrors.gradeId = 'Required';

    return playerErrors;
  } );

  return anyPlayerFieldHasError( errors.players ) ? errors : {};
};

export const parsePlayer = ( player : IPlayerForm ) => ( {
  first_name: player.name,
  last_name: player.lastName,
  birthday: player.birthday,
  school: player.school,
  grade: player.gradeId,
} );

export const parsePlayersData = ( players: IPlayerForm[] ) => {
  const playersParsedData: PlayerData[] = [];

  players.forEach( ( player ) => {
    playersParsedData.push(
      parsePlayer( player ),
    );
  } );

  return playersParsedData;
};
