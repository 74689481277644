import React, { ReactNode } from 'react';
import {
  Formik, FieldArray, FormikErrors, FormikTouched,
} from 'formik';

import {
  Button,
  FormDropdown,
  ErrorMessage,
  DatePicker,
  Label,
  InputContainer,
} from '..';
import { errorValidationsPlayers } from '../../helpers/formHelpers';
import { DEFAULT_DATEPICKER_DATE, gradesDropdown } from '../../constants/form';
import RemovePlayerFormBtn from './RemovePlayerFormBtn';
import styles from './styles.module.scss';

export interface IPlayerForm {
  name: string;
  lastName: string;
  birthday: Date;
  school: string;
  gradeId: string;
}

export interface IPlayerFormErrors {
  name: string;
  lastName: string;
  birthday: string;
  school: string;
  gradeId: string;
}

export interface PlayersFormValues {
  players: IPlayerForm[];
}

export const emptyPlayerValues: IPlayerForm = {
  name: '',
  lastName: '',
  birthday: new Date( DEFAULT_DATEPICKER_DATE ),
  school: '',
  gradeId: '',
};

const playerHasError = (
  errors: FormikErrors<PlayersFormValues>,
  index: number,
  key: string,
) => !!errors.players?.[index] && Object.keys( errors.players[index] ).includes( key );

const playerHasBeenTouched = (
  touched: FormikTouched<PlayersFormValues>,
  index: number,
  key: string,
) => !!touched.players?.[index] && Object.keys( touched.players[index] ).includes( key );

const fieldHasBeenTouched = (
  touched: FormikTouched<PlayersFormValues>,
  index: number,
  key: keyof IPlayerForm,
) => playerHasBeenTouched( touched, index, key ) && !!touched.players![index][key];

const getFieldError = (
  errors: FormikErrors<PlayersFormValues>,
  index: number,
  key: keyof IPlayerForm,
) => ( playerHasError( errors, index, key )
  ? ( errors.players![index] as IPlayerFormErrors )[key]
  : '' );

const isDisabled = (
  isSubmitting: boolean,
  values: PlayersFormValues,
) => isSubmitting || (
  values.players.filter(
    ( player ) => Object.values( player ).filter( ( v ) => ( v === '' ) ).length > 0,
  ).length > 0
);

interface PlayerFormProps {
  onSubmit: ( playersInfo: IPlayerForm[] ) => void|Promise<void>;
  initialValues?: PlayersFormValues;
  isEdit?: boolean;
  renderExtra?: () => ReactNode;
}

export interface PlayerFormRef {
  setValues: ( values: IPlayerForm[] ) => void;
}

const PlayerForm: React.FC<PlayerFormProps> = ( {
  onSubmit,
  initialValues = { players: [ emptyPlayerValues ] },
  isEdit = false,
  renderExtra = () => null,
} ) => {
  const renderRemovePlayerFormBtn = (
    index: number,
    onClick: () => void,
  ) => index > 0 && <RemovePlayerFormBtn onClick={onClick} />;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={( values ) => errorValidationsPlayers( values )}
      onSubmit={async ( values, { setSubmitting } ) => {
        await onSubmit( values.players );
        setSubmitting( false );
      }}
    >
      {( {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
      } ) => (
        <form onSubmit={handleSubmit}>
          <FieldArray name="players">
            {( { push, remove } ) => (
              <>
                {values.players.map(
                  ( player, index ) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`players.${index}.name`}>
                      {!isEdit && (
                        <div className={styles.playerRowHeader}>
                          <h4 className="subheader">
                            {`Player ${index + 1}`}
                          </h4>
                          {renderRemovePlayerFormBtn( index, () => remove( index ) )}
                        </div>
                      )}
                      <div className={styles.row}>
                        <div className={styles.col}>
                          <InputContainer
                            name={`players.${index}.name`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={player.name}
                            label="First Name"
                            error={getFieldError( errors, index, 'name' )}
                            touched={fieldHasBeenTouched( touched, index, 'name' )}
                          />
                        </div>
                        <div className={styles.col}>
                          <InputContainer
                            name={`players.${index}.lastName`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={player.lastName}
                            label="Last Name"
                            error={getFieldError( errors, index, 'lastName' )}
                            touched={fieldHasBeenTouched( touched, index, 'lastName' )}
                          />
                        </div>
                      </div>
                      <div className={styles.inputContainer}>
                        <DatePicker name={`players.${index}.birthday`} />
                        <Label
                          className="caption1"
                          label="Birthday"
                        />
                        <ErrorMessage
                          errorText={getFieldError( errors, index, 'birthday' )}
                          touched={fieldHasBeenTouched( touched, index, 'birthday' )}
                        />
                      </div>
                      <InputContainer
                        name={`players.${index}.school`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={player.school}
                        label="School / College"
                        error={getFieldError( errors, index, 'school' )}
                        touched={fieldHasBeenTouched( touched, index, 'school' )}
                      />
                      <FormDropdown
                        options={gradesDropdown}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={player.gradeId}
                        index={`players.${index}.gradeId`}
                      />
                      <ErrorMessage
                        errorText={getFieldError( errors, index, 'gradeId' )}
                        touched={fieldHasBeenTouched( touched, index, 'gradeId' )}
                      />
                    </div>
                  ),
                )}
                {!isEdit && (
                <Button
                  className={styles.addAnotherPlayer}
                  variant="secondary"
                  label="+ Add another player"
                  onClick={() => push( emptyPlayerValues )}
                  size="small"
                />
                )}
              </>
            )}
          </FieldArray>
          {renderExtra()}
          <Button
            className={styles.submitBtn}
            type="submit"
            disabled={isDisabled( isSubmitting, values )}
            label={isEdit ? 'save changes' : 'create players'}
            loading={isSubmitting && !isValidating}
            loadingType="Oval"
          />
        </form>
      )}
    </Formik>
  );
};

export default PlayerForm;
