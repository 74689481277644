import React from 'react';

import { observer } from 'mobx-react-lite';
import { WithHeader } from '../../hocs';
import { MainSection } from './components/sections';
import styles from './styles.module.scss';
import { BottomLinks } from '../../components';
import useLandingCoordinator from '../../view-coordinators/useLandingCoordinator';
import { useQuery } from '../../hooks';

const Landing: React.FC = () => {
  const view = useLandingCoordinator();
  const query = useQuery();

  const successfullyDeletedAccount = query.get( 'successfullyDeletedAccount' );

  return (
    <section className={styles.container}>
      <MainSection
        loginView={view.loginView}
        membershipView={view.viewMembershipView}
        successfullyDeletedAccount={!!successfullyDeletedAccount}
      />
      <BottomLinks />
    </section>
  );
};

export default WithHeader( observer( Landing ), {
  containerClassName: styles.screenContainer,
  showLoginButton: false,
} );
