import React from 'react';

import { observer } from 'mobx-react-lite';
import { Separator, BillingDetails, PromoCode } from '../index';
import PaymentOption from './PaymentOption';

import styles from './styles.module.scss';
import CartView from '../../views/CartView';

interface CartProps {
  view: CartView;
  beforeGoToPay?: () => Promise<void>;
}

const Cart: React.FC<CartProps> = ( {
  view,
  beforeGoToPay = async () => { /* do nothing */ },
}: CartProps ) => (
  <div className={styles.container}>
    <Separator className={styles.sectionSeparator} />
    <BillingDetails
      players={view.players}
      membershipPlans={view.membershipPlans}
      totalCharges={view.totalCharges}
      extraFee={view.extraFee}
    />
    <Separator className={styles.sectionSeparator} />
    {!view.userHasSavedPromoCode && (
    <>
      <PromoCode
        applyPromoCode={view.applyPromoCode}
        resetPromoCode={view.resetPromoCode}
        onChangePromoCode={view.changePromoCode}
        invalidPromoCode={view.invalidPromoCode}
        validPromoCode={view.validPromoCode}
        validUntil={view.promoCodeValidUntil}
        hasPromoCode={view.validPromoCode}
        value={view.promoCode}
      />
      <Separator className={styles.sectionSeparator} />
    </>
    )}
    <PaymentOption
      billingCycleOptions={view.billingCycleOptions}
      onSelectTerm={( term: number ) => view.updateBillingCycle( term )}
      goToPayLabel={view.goToPayLabel}
      goToPay={async () => {
        await beforeGoToPay();
        await view.goToPay();
      }}
      initialValue={view.billingCycle}
    />
  </div>
);

export default observer( Cart );
