import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from '../styles.module.scss';
import { Label } from '../../../components';
import LeavingReason from '../../../entities/LeavingReason';

interface SelectableReasonsProps {
  onChange: ( event: string ) => void;
  reasons: LeavingReason[];
  selectedReasonId: number;
}

const SelectableReasons: React.FC<SelectableReasonsProps> = ( {
  onChange,
  reasons,
  selectedReasonId,
}: SelectableReasonsProps ) => {
  const handleOptionChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    onChange( event.target.value );
  };

  return (
    <section className={styles.reasons}>
      <FormControl>
        <RadioGroup
          value={selectedReasonId}
          onChange={handleOptionChange}
        >
          {
              reasons.map( ( leavingReason ) => (
                <FormControlLabel
                  control={(
                    <Radio
                      size="small"
                      aria-label={`radio-button-${leavingReason.reason}`}
                    />
                      )}
                  label={<Label label={leavingReason.reason} className={styles.body1} />}
                  value={leavingReason.id}
                  key={leavingReason.id}
                />
              ) )
            }
        </RadioGroup>
      </FormControl>
    </section>
  );
};

export default observer( SelectableReasons );
