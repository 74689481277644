import { parse } from 'date-fns';
import { BillingInformationJSON } from '../services/CurrentUserService';

interface BillingInformationProps {
  date: Date | null;
  total: number;
  activeMemberships: string[];
  cardLast4: string;
}

class BillingInformation {
  date: Date | null;

  total: number;

  activeMemberships: string[];

  cardLast4: string;

  constructor( {
    date,
    total,
    activeMemberships,
    cardLast4,
  } : BillingInformationProps ) {
    this.date = date;
    this.total = total;
    this.activeMemberships = activeMemberships;
    this.cardLast4 = cardLast4;
  }

  get hasBillingInformation() {
    return (
      this.hasActiveMemberships
      || this.hasCardInformation
      || this.date !== null
      || this.total !== 0
    );
  }

  get hasActiveMemberships() {
    return this.activeMemberships.length > 0;
  }

  get hasCardInformation() {
    return this.cardLast4.length > 0;
  }

  static fromJSON( json : BillingInformationJSON ) {
    return new BillingInformation( {
      date: json.billing_date
        ? parse( json.billing_date, 'yyyy-MM-dd', new Date() )
        : null,
      total: json.billing_total || 0,
      activeMemberships: json.active_memberships,
      cardLast4: json.card_last4 || '',
    } );
  }

  static empty() {
    return new BillingInformation( {
      date: null,
      total: 0,
      activeMemberships: [],
      cardLast4: '',
    } );
  }
}

export default BillingInformation;
