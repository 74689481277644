import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { TextareaAutosize } from '@mui/material';
import { WithHeader } from '../../hocs';
import styles from './styles.module.scss';
import { ReactComponent as CryingBall } from '../../assets/icons/crying-ball/crying-ball.svg';
import { DeleteAccountView } from '../../views';
import useDeleteAccountCoordinator from '../../view-coordinators/useDeleteAccountCoordinator';
import { ROOT, HOME_URL } from '../../constants/routes';
import {
  Button, Separator,
} from '../../components';
import SelectableReasons from './components/SelectableReasons';

const DeleteAccount : React.FC = () => {
  const view = useDeleteAccountCoordinator();
  const history = useHistory();

  useEffect( () => {
    view.getAccountHolderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] );

  const cancelOnClick = useCallback( () => {
    history.push( HOME_URL );
  }, [ history ] );

  const deleteOnClick = useCallback( async () => {
    await view.deleteAccount();
    history.push( `${ROOT}?successfullyDeletedAccount=true` );
  }, [ view, history ] );

  const handleCustomFeedbackChange: React.FormEventHandler<HTMLTextAreaElement> = ( event ) => {
    view.setCustomReason( event.currentTarget.value );
  };

  return (
    <section className={styles.container}>
      <div className={styles.mainComponents}>
        <CryingBall />
        <h2 className="header2">We’re sorry to see you go</h2>
        <p className={cn( styles.body1, styles.disclaimerText )}>
          {DeleteAccountView.disclaimerText}
        </p>

        <SelectableReasons
          selectedReasonId={view.selectedReasonId}
          onChange={view.selectReason}
          reasons={view.selectableReasons}
        />

        <Separator className={styles.separator} />

        <section>
          <p className={cn( styles.body1, styles.customFeedbackText )}>
            {DeleteAccountView.customFeedbackText}
          </p>
          <TextareaAutosize
            placeholder="We read you."
            value={view.customReason}
            name="custom reason"
            className={styles.customMessage}
            onInput={handleCustomFeedbackChange}
            minRows={5}
          />
        </section>

        <div className={styles.buttonContainer}>
          <Button
            label="Yes, delete my account"
            onClick={deleteOnClick}
            disabled={view.isConfirmationButtonDisabled}
          />
          <Button label="Cancel" variant="secondary" onClick={cancelOnClick} />
        </div>
      </div>
    </section>
  );
};

export default WithHeader( observer( DeleteAccount ) );
