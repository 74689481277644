import ResponseDeserializer from './ResponseDeserializer';
import ErrorResponse from './ErrorResponse';

export default class AuthResponseDeserializer extends ResponseDeserializer {
  get responseBody() {
    return this.response.data;
  }

  get errorResponse() {
    return this.responseBody.error.detail
      ? new ErrorResponse( {
        statusCode: this.statusCode,
        name: this.responseBody.error.name,
        description: this.responseBody.error.detail,
      } )
      : new ErrorResponse( {
        statusCode: this.statusCode,
        name: this.responseBody.error,
        description: this.responseBody.error_description,
      } );
  }
}
