import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { WithHeader } from '../../hocs';
import { HOME_URL } from '../../constants/routes';
import { BackButton, PlayerForm, ResponsiveContainer } from '../../components';
import { useCreatePlayersCoordinator } from '../../view-coordinators';
import styles from './styles.module.scss';

const CreatePlayers: React.FC = () => {
  const view = useCreatePlayersCoordinator();
  const history = useHistory();

  useEffect( () => {
    if ( view.createPlayersSuccessful ) {
      history.replace( HOME_URL );
    }
  }, [ view.createPlayersSuccessful, history ] );

  return (
    <ResponsiveContainer sectionStyle={styles.container}>
      <BackButton />
      <h2 className="header1">Create Players</h2>
      <p className="body1">You can create more than one player.</p>
      <PlayerForm onSubmit={view.createPlayers.bind( view )} />
    </ResponsiveContainer>
  );
};

export default WithHeader( observer( CreatePlayers ) );
