import { useState } from 'react';
import { SetNewPasswordInteractor, ValidateResetPasswordTokenInteractor } from '../interactors';

import { Api, CurrentUserService } from '../services';
import { SetNewPasswordView } from '../views';

const useSetNewPasswordCoordinator = () => {
  const createView = () => {
    const api = new Api();
    const currentUserService = new CurrentUserService( { api } );
    const setNewPasswordInteractor = new SetNewPasswordInteractor(
      { service: currentUserService },
    );
    const validateResetPasswordTokenInteractor = new ValidateResetPasswordTokenInteractor( {
      service: currentUserService,
    } );

    return new SetNewPasswordView( {
      setNewPasswordInteractor,
      validateResetPasswordTokenInteractor,
    } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default useSetNewPasswordCoordinator;
