import { AccordionHandle } from '../components/Accordion/Accordion.component';

interface FAQSectionProps {
  title: string;
  options: string[];
  accordionHandle?: AccordionHandle;
}

export default class FAQSection {
  title: string;

  options: string[];

  accordionHandle?: AccordionHandle;

  constructor( {
    title,
    options,
    accordionHandle = undefined,
  }:FAQSectionProps ) {
    this.title = title;
    this.options = options;
    this.accordionHandle = accordionHandle;
  }

  hasTitle( title:string ) {
    return this.title === title;
  }

  setAccordionHandle( handler: AccordionHandle ) {
    this.accordionHandle = handler;
  }

  containsOption( option:string ):boolean {
    return this.options.includes( option );
  }

  open() {
    if ( this.accordionHandle ) {
      this.accordionHandle?.expand();
    }
  }

  close() {
    if ( this.accordionHandle ) {
      this.accordionHandle?.retract();
    }
  }
}
