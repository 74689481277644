import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface ComponentsBoxProps {
  sectionStyle?: string;
  boxStyle?: string;
  children : React.ReactNode;
}

const ResponsiveContainer : React.FC<ComponentsBoxProps> = ( {
  sectionStyle = '',
  boxStyle = '',
  children,
} : ComponentsBoxProps ) => (
  <section className={cn( styles.container, sectionStyle )}>
    <div className={cn( styles.box, boxStyle )}>
      {children}
    </div>
  </section>
);

export default ResponsiveContainer;
