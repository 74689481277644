import React from 'react';
import styles from './styles.module.scss';

interface DialogBodyProps {
  children : React.ReactNode;
}

const DialogBody : React.FC<DialogBodyProps> = ( { children } ) => (
  <div className={styles.bodyContainer}>{children}</div>
);

export default DialogBody;
