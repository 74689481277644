import { CurrentUserService } from '../services';

export default class UnassignPromoCodeInteractor {
  private service : CurrentUserService;

  constructor( { service = new CurrentUserService() } = {} ) {
    this.service = service;
  }

  execute = async () => this.service.unassignPromoCode()
}
