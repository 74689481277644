import React, { useCallback } from 'react';
import { useField } from 'formik';
import cn from 'classnames';
import DatePickerMUI from '@mui/lab/DatePicker';

import { TextField } from '@mui/material';
import styles from './styles.module.scss';

interface DatePickerProps {
  name: string;
  className?: string;
}

const DatePickerField: React.FC<DatePickerProps> = ( {
  name,
  className,
} ) => {
  const [ field, , { setValue } ] = useField( name );

  const handleChange = useCallback( ( val: Date | null ) => {
    setValue( val );
  }, [ setValue ] );

  return (
    <DatePickerMUI
      {...field}
      openTo="year"
      views={[ 'year', 'month', 'day' ]}
      inputFormat="MM/dd/yyyy"
      value={new Date( field.value )}
      onChange={handleChange}
      renderInput={( params ) => (
        <TextField
          {...params}
          fullWidth
          className={cn( styles.input, className )}
          InputProps={{
            classes: {
              focused: styles.focused,
            },
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export default DatePickerField;
