// tslint:disable max-line-length
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { WithHeader } from '../../hocs';
import { Separator } from '../../components';
import styles from './styles.module.scss';
import { PRIVACY_POLICY_URL } from '../../constants/routes';

const TermsAndConditions: React.FC = () => (
  <section className={styles.container}>
    <h2 className="megaHeader">Terms and Conditions</h2>
    <p className="caption1">Last updated: May 2022</p>
    <p className="caption1">Please read these terms and conditions carefully before using Our Service.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Interpretation and Definitions</h4>
    <p className="caption2">Interpretation</p>
    <p className="caption1">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
    <Separator className={styles.separator} />
    <p className="caption2">Definitions</p>
    <p className="caption1">For the purposes of these Terms and Conditions:</p>
    <ul className="caption1">
      <li>Application means the software program provided by the Company downloaded by You on any electronic device, named Swish 365.</li>
      <li>Application Store means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.</li>
      <li>Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
      <li>Account means a unique account created for You to access our Service or parts of our Service.</li>
      <li>Country refers to: Ohio, United States</li>
      <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Swish 365, 1255 N Carpenter Rd, Brunswick, OH 44212.</li>
      <li>Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
      <li>Feedback means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.</li>
      <li>Service refers to the Application.</li>
      <li>Subscriptions refer to the services or access to the Service offered on a subscription basis by the Company to You.</li>
      <li>Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
      <li>Third-party Social Media Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li>
      <li>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
    </ul>
    <Separator className={styles.separator} />
    <h4 className="subheader">Acknowledgment</h4>
    <p className="caption1">These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
    <p className="caption1">Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
    <p className="caption1">By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>
    <p className="caption1">You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
    <p className="caption1">Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Subscriptions</h4>
    <p className="caption2">Subscription period</p>
    <p className="caption1">The Service or some parts of the Service are available only with a paid Subscription. You will be billed in advance on a recurring and periodic basis (such as daily, weekly, monthly or annually), depending on the type of Subscription plan you select when purchasing the Subscription.</p>
    <p className="caption1">At the end of each period, Your Subscription will automatically renew under the exact same conditions unless You cancel it or the Company cancels it.</p>
    <p className="caption2">Subscription cancellations</p>
    <p className="caption1">You may cancel Your Subscription renewal either through Your Account settings page or by contacting the Company. You will not receive a refund for the fees You already paid for Your current Subscription period and You will be able to access the Service until the end of Your current Subscription period.</p>
    <p className="caption2">Billing</p>
    <p className="caption1">You shall provide the Company with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information.</p>
    <p className="caption1">Should automatic billing fail to occur for any reason, the Company will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>
    <p className="caption2">Fee Changes</p>
    <p className="caption1">The Company, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change will become effective at the end of the then-current Subscription period.</p>
    <p className="caption1">The Company will provide You with reasonable prior notice of any change in Subscription fees to give You an opportunity to terminate Your Subscription before such change becomes effective.</p>
    <p className="caption1">Your continued use of the Service after the Subscription fee change comes into effect constitutes Your agreement to pay the modified Subscription fee amount.</p>
    <p className="caption2">Refunds</p>
    <p className="caption1">Except when required by law, paid Subscription fees are non-refundable.</p>
    <p className="caption1">Certain refund requests for Subscriptions may be considered by the Company on a case-by-case basis and granted at the sole discretion of the Company.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">User Accounts</h4>
    <p className="caption1">When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.</p>
    <p className="caption1">You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>
    <p className="caption1">You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</p>
    <p className="caption1">You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Intellectual Property</h4>
    <p className="caption1">The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.</p>
    <p className="caption1">The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.</p>
    <p className="caption1">Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Your Feedback to Us</h4>
    <p className="caption1">You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Links to Other Websites</h4>
    <p className="caption1">Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
    <p className="caption1">The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
    <p className="caption1">We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Termination</h4>
    <p className="caption1">We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.</p>
    <p className="caption1">Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Limitation of Liability</h4>
    <p className="caption1">Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>
    <p className="caption1">To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
    <p className="caption1">Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">"AS IS" and "AS AVAILABLE" Disclaimer</h4>
    <p className="caption1">The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
    <p className="caption1">Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
    <p className="caption1">Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Governing Law</h4>
    <p className="caption1">The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Disputes Resolution</h4>
    <p className="caption1">If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">For European Union (EU) Users</h4>
    <p className="caption1">If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">United States Federal Government End Use Provisions</h4>
    <p className="caption1">If You are a U.S. federal government end user, our Service is a "Commercial Item" as that term is defined at 48 C.F.R. §2.101.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">United States Legal Compliance</h4>
    <p className="caption1">You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Severability and Waiver</h4>
    <p className="caption2">Severability</p>
    <p className="caption1">If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
    <p className="caption2">Waiver</p>
    <p className="caption1">Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Assumption of risk, waiver of liability, indemnity & marketing communications consent form</h4>
    <p className="caption1">Upon acceptance, the terms of this Assumption of Risk, Waiver of Liability, Indemnity & Marketing Communications Consent Form (the “Agreement”) create a binding contract between you (“you,” or “your”) and Swish 365, LLC (“Swish365,” “we,” or “us”). This Agreement governs your access to and use of our facilities, services and Swish365 Mobile Application, as well as our ability to send you communications relating thereto.</p>
    <p className="caption2">ACCEPTANCE</p>
    <p className="caption1">THIS AGREEMENT TAKES EFFECT WHEN YOU CLICK THE “I ACCEPT” BUTTON IN RESPONSE TO THE PROMPTS BELOW OR BY OTHWERWISE ACCESSING OR USING OUR SWISH365 MOBILE APPLICATION (the “Effective Date”). BY CLICKING ON THE “I ACCEPT” BUTTON BELOW IN RESPONSE TO THE ASSOCIATED PROMPTS OR BY OTHERWISE ACCESSING OR USING OUR SWISH365 MOBILE APPLICATION YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT AND, IF ENTERING INTO THIS AGREEMENT ON BEHALF OF A MINOR, THAT YOU HAVE THE LEGAL AUTHORITY AS THEIR GUARDIAN TO BIND THAT MINOR; (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS; (D) HAVE READ OUR PRIVACY POLICY AND AGREE TO ITS TERMS AND CONDITIONS; AND (E) AGREE THAT WE MAY SEND MARKETING COMMUNICATIONS TO YOU VIA EMAIL, SMS OR SIMILAR COMMUNICATION CHANNELS. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT SELECT THE “I ACCEPT” BUTTON BELOW IN RESPONSE TO THE ASSOCIATED PROMPTS. IF YOU DO NOT ACCEPT THESE TERMS, YOU MAY NOT BE GRANTED ACCESS TO OR USE OF OUR FACILITIES, SERVICES OR OUR SWISH365 MOBILE APPLICATION.</p>
    <p className="caption2">ASSUMPTION OF RISK, WAIVER OF LIABILITY & INDEMNITY</p>
    <p className="caption1">This Agreement serves to certify that I, as parent/guardian with legal responsibility for my minor child or ward, or as an individual of the age of majority with the legal capacity to enter into this Agreement (collectively referred to herein as “I,” “my,” or “myself”), acknowledge that I will be voluntarily engaged in activities that may involve risk of injury, including but not limited to death, through the use our Swish365 Mobile Application and/or access to or use of our facilities or services (the “Activities”), and that I do recognize and assume that risk, whether foreseeable or not reasonably foreseeable, on behalf of myself, my child or ward, as applicable. I consent on behalf of myself, to my participation in Activities associated with the use of the Swish365 Mobile Application and the access to and use of Swish365 facilities and services.</p>
    <p className="caption1">I AM AWARE AND UNDERSTAND THAT THE ACTIVITIES ARE POTENTIALLY DANGEROUS ACTIVITIES AND INVOLVE THE RISK OF SERIOUS INJURY, DISABILITY, DEATH, AND/OR PROPERTY DAMAGE. I ACKNOWLEDGE THAT ANY INJURIES THAT I SUSTAIN MAY RESULT FROM OR BE COMPOUNDED BY THE ACTIONS, OMISSIONS, OR NEGLIGENCE OF SWISH365, INCLUDING NEGLIGENT EMERGENCY RESPONSE OR RESCUE OPERATIONS OF SWISH365. NOTWITHSTANDING THE RISK, I ACKNOWLEDGE THAT I AM KNOWINGLY AND VOLUNTARILY PARTICIPATING IN THE ACTIVITIES WITH AN EXPRESS UNDERSTANDING OF THE DANGER INVOLVED AND HEREBY AGREE TO ACCEPT AND ASSUME ANY AND ALL RISKS OF INJURY, DISABILITY, DEATH, AND/OR PROPERTY DAMAGE ARISING FROM MY PARTICIPATION IN THE ACTIVITIES, WHETHER CAUSED BY OR THAT MAY RESULT FROM THE NEGLIGENCE OF SWISH365 OR ANY OTHER PARTICIPANT TO THE FULLEST EXTENT PERMITTED BY LAW.</p>
    <p className="caption1">In consideration of Swish365’s agreement to permit my access to and use of Swish365 facilities, services and the Swish365 Mobile Application and to engage in Activities relating thereto, for myself, my heirs, executors, insurers, agents, assigns, and on behalf of my child or ward and his/her legal representatives, I hereby waive, release and agree to indemnify Swish365 including, but not limited to, its administrators, directors, agents, coaches, and its employees, other participants, and sponsor agencies, predecessors, successors, and affiliates of any kind from and against any and all rights, claims and damages instituting or arising out of my involvement or participation in any Activities, whether or not arising as a result of the negligence of Swish365, and for any and all injuries, harm, losses, or damages which may be suffered by myself and/or my child or ward.</p>
    <p className="caption1">I understand there is a risk of danger and I assume that risk freely and voluntarily. I am not relying on any statements, inducements, promises, or representations made by Swish365 or not otherwise contained in this Agreement.</p>
    <p className="caption1">I hereby consent to receive medical treatment deemed necessary if I am injured or require medical attention during my participation in any Activity. I understand and agree that I am solely responsible for all costs related to such medical treatment and any related medical transportation and/or evacuation. I hereby release, forever discharge, and hold harmless Swish365 from any claim based on such treatment or other medical services.</p>
    <p className="caption2">CONSENT TO MARKETING COMMUNICATIONS</p>
    <p className="caption1">Swish365 would like to send you information about our services, upcoming events, and other news and information regarding our offerings by mail, phone, email, SMS, Swish365 Mobile Application notifications and similar communication methods. We will not send you these Swish365 communications unless you complete the form below indicating your consent to receive this information.</p>
    <p className="caption1">
      {'In order to provide you with the best marketing experience possible, from time to time, we may share your information with third parties. Any third parties that we may share your data with are obliged to keep your details secure and use them only to fulfill the service they provide you on our behalf. For more information regarding how we may collect, store and use your personal data, please visit our Privacy Policy by clicking '}
      <a href={PRIVACY_POLICY_URL}>here</a>
      . If at any time after you opt-in to Swish365 marketing communications, you no longer wish to receive such communications, you can withdraw your consent by contacting us in accordance with our Privacy Policy.
    </p>
    <p className="caption1">BY CLICKING THE “PRIVACY NOTICE AND CONDITIONS OF USE” CHECKBOX ON SIGN UP, YOU ACKNOWLEDGE THAT YOU HAVE READ AND FULLY UNDERSTAND THIS AGREEMENT AND OUR PRIVACY POLICY.  YOU ALSO ACKNOWLEDGE ACCEPTANCE OF THIS AGREEMENT, INLCUDING ALL OF ITS TERMS RELATING TO ASSUMPTION OF RISK, WAIVER OF LIABILITY, INDEMNITY AND CONSENT TO MARKETING COMMUNICATIONS AS OF THE EFFECTIVE DATE.</p>
    <ul className="caption1">
      <li>I have read this Agreement and hereby agree to be legally bound by its terms and conditions, including the assumption of all associated risks and the waiver of any liability on Swish365 regarding such risks.</li>
      <li>
        {'I have read the Swish365 '}
        <a href={PRIVACY_POLICY_URL}>Privacy Policy</a>
        {' '}
        {' regarding the processing of my personal information and agree to the terms and conditions therein.'}
      </li>
      <li>I agree to receive marketing communications from Swish365.</li>
    </ul>
    <Separator className={styles.separator} />
    <h4 className="subheader">Translation Interpretation</h4>
    <p className="caption1">These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Changes to These Terms and Conditions</h4>
    <p className="caption1">We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
    <p className="caption1">By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
    <Separator className={styles.separator} />
    <h4 className="subheader">Contact Us</h4>
    <p className="caption1">If you have any questions about these Terms and Conditions, You can contact us: By visiting this page on our website:</p>
    <a
      className="linkText"
      target="_blank"
      href="https://www.swish365.com"
      rel="noreferrer"
    >
      https://www.swish365.com
    </a>
  </section>
);

export default WithHeader( TermsAndConditions );
