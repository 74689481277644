import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface SeparatorProps {
  className?: string;
}

const Separator: React.FC<SeparatorProps> = ( {
  className = '',
}: SeparatorProps ) => (
  <div className={cn( styles.separator, className )} />
);

export default Separator;
