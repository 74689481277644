import cn from 'classnames';
import React from 'react';
import NoMembershipImage from '../../../assets/images/no-membership/no-membership.png';
import styles from '../styles.module.scss';

const NoMembership = () => (
  <div className={styles.noMembershipContainer}>
    <img
      src={NoMembershipImage}
      alt=""
      className={styles.noMembershipImage}
    />
    <p className={cn( 'body1', styles.noMembershipText )}>
      This player has nothing assigned. Select a plan now and save time on the next visit.
    </p>
  </div>
);

export default NoMembership;
