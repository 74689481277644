import React, {
  ReactNode, useCallback, useImperativeHandle, useRef, useState,
} from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as Chevron } from '../../assets/icons/chevron/chevron.svg';

interface AccordionProps {
  children: ReactNode;
  buttonClassName?: string;
  buttonLabels? : [string, string];
  chevronClassName?: string;
}

export type AccordionHandle = {
  expand: () => void;
  retract: () => void;
};

const Accordion = React.forwardRef<AccordionHandle, AccordionProps>( (
  {
    children,
    buttonClassName = '',
    buttonLabels = [ 'See more', 'See less' ],
    chevronClassName = '',
  }: AccordionProps,
  ref,
) => {
  const [ expanded, setExpanded ] = useState( false );
  const [ height, setHeight ] = useState( '0px' );

  const content = useRef<HTMLDivElement>( null );

  const toggleAccordion = useCallback( () => {
    setExpanded( !expanded );
    setHeight(
      !expanded ? `${content.current?.scrollHeight}px` : '0px',
    );
  }, [ expanded, setExpanded, setHeight ] );

  const onClick = useCallback( ( ev: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
    ev.stopPropagation();
    toggleAccordion();
  }, [ toggleAccordion ] );

  useImperativeHandle(
    ref,
    () => ( {
      expand: () => {
        if ( !expanded ) toggleAccordion();
      },
      retract: () => {
        if ( expanded ) toggleAccordion();
      },
    } ),
  );

  const buttonLabel = buttonLabels[+expanded];

  return (
    <div className={styles.accordionContainer}>
      <button
        type="button"
        className={cn( 'linkText', styles.accordionButton, buttonClassName )}
        onClick={onClick}
      >
        <span>{buttonLabel}</span>
        <Chevron className={expanded ? chevronClassName : cn( chevronClassName, styles.rotate )} />
      </button>
      <div
        ref={content}
        className={styles.accordionContent}
        style={{ maxHeight: `${height}` }}
      >
        {children}
      </div>
    </div>
  );
} );

export default Accordion;
