import { LoginView, ViewMembershipsView } from './index';

interface LandingViewProps {
  viewMembershipView?: ViewMembershipsView;
  loginView?: LoginView;
}

export default class LandingView {
  viewMembershipView: ViewMembershipsView;

  loginView: LoginView;

  constructor( {
    viewMembershipView = new ViewMembershipsView(),
    loginView = new LoginView(),
  }: LandingViewProps = {} ) {
    this.viewMembershipView = viewMembershipView;
    this.loginView = loginView;
  }
}
