import React, { ReactNode } from 'react';
import { Facility } from '../../../entities';
import FacilityOption from './FacilityOption';

interface SameSportFacilityOptionsProps {
  facilities: Facility[];
  onChange: ( facilityId:number ) => void;
  selectedFacilityId: number;
  rightIcon?: ReactNode;
}

const SameSportFacilityOptions = ( {
  facilities,
  onChange,
  selectedFacilityId,
  rightIcon,
}: SameSportFacilityOptionsProps ) => (
  <>
    {facilities.map( ( facility ) => (
      <FacilityOption
        facility={facility}
        onChange={onChange}
        selectedFacilityId={selectedFacilityId}
        key={facility.id}
        rightIcon={rightIcon}
      />
    ) )}
  </>
);

export default SameSportFacilityOptions;
