import React from 'react';
import cn from 'classnames';
import { ReactComponent as QuestionBall } from '../../../assets/icons/question-ball/question-ball.svg';
import styles from './styles.module.scss';

const FAQTitle = () => (
  <>
    <QuestionBall />
    <p
      className={cn( styles.caption1, styles.caption )}
    >
      {'Have any questions? We\'re here to help'}
    </p>
    <h2 className={styles.header2}>Frequently Asked Questions</h2>
  </>
);

export default FAQTitle;
