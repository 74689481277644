import React from 'react';
import cn from 'classnames';

import { observer } from 'mobx-react-lite';
import { BillingLine, Separator } from '../index';
import { MembershipPlan, TotalCharges } from '../../entities';
import { DeserializedLine } from '../../entities/TotalCharges';
import BillingDetailsForPlan from './components/BillingDetailsForPlan';
import styles from './styles.module.scss';
import Currency from '../../helpers/Currency';
import ExtraFee from '../../entities/ExtraFee';
import { PlayerInCart } from '../../views/CartView';

interface BillingDetailsProps {
  players: PlayerInCart[];
  membershipPlans: MembershipPlan[];
  totalAmountHasDisclaimer?: boolean;
  totalCharges: TotalCharges;
  extraFee?: ExtraFee;
}

interface ChargesByPlan{
  [key: number]: {
    membershipName: string,
    lines: DeserializedLine[],
  }
}

const BillingDetails: React.FC<BillingDetailsProps> = ( {
  players,
  membershipPlans,
  totalAmountHasDisclaimer = false,
  totalCharges,
  extraFee = ExtraFee.empty(),
}: BillingDetailsProps ) => {
  const totalAmount = totalCharges?.afterFees;

  const createChargesByMembership = ( result: ChargesByPlan ) => ( line: DeserializedLine ) => {
    const plan = membershipPlans.find( ( { id } ) => id === line.membershipPlanId );
    if ( plan ) {
      if ( result[plan.id] ) {
        result[plan.id] = {
          ...result[plan.id],
          lines: [ ...result[plan.id].lines, line ],
        };
      } else {
        result[plan.id] = {
          membershipName: plan.name,
          lines: [ line ],
        };
      }
    }
  };

  const linesByMembershipPlan = () => {
    const result: ChargesByPlan = {};

    totalCharges.lines.forEach( createChargesByMembership( result ) );

    return result;
  };

  return (
    <div className={styles.sectionContainer}>
      <p className={cn( styles.subheader, styles.subtitle )}>Billing details</p>
      <Separator className={styles.separator} />
      <div className={styles.billingDetailsContainer}>
        {Object.entries( linesByMembershipPlan() ).map( ( [ planId, entry ] ) => (
          <BillingDetailsForPlan
            key={planId}
            plan={{ id: +planId, membershipName: entry.membershipName }}
            players={players}
            lines={entry.lines}
          />
        ) )}
      </div>

      <Separator className={styles.separator} />

      {!!totalCharges?.promoCode && (
      <div>
        <BillingLine
          name="PROMO CODE"
          fullPrice={totalCharges.priceWithoutPromoCode}
          netPrice={totalCharges.netPrice}
          discountPercentage={totalCharges.promoCode.discount}
        />
      </div>
      )}

      { !!extraFee?.price && (
      <BillingLine
        name={extraFee.name}
        fullPrice={extraFee.price}
        disclaimerText={extraFee.description}
      />
      )}

      <div className={styles.totalContainer}>
        <span className={styles.body2}>Total payment:</span>
        <span className={styles.body2}>
          {
            totalAmountHasDisclaimer
              ? `${new Currency( totalAmount ).format()}*`
              : `${new Currency( totalAmount ).format()}`
        }
        </span>
      </div>
    </div>
  );
};

export default observer( BillingDetails );
