import React, { ReactNode } from 'react';
import Radio from '@mui/material/Radio';
import { Facility } from '../../../entities';
import styles from '../styles.module.scss';

interface FacilityOptionProps {
  facility: Facility;
  selectedFacilityId: number;
  onChange: ( facilityId:number ) => void;
  rightIcon?: ReactNode;
}

const FacilityOption : React.FC<FacilityOptionProps> = ( {
  facility,
  selectedFacilityId,
  onChange,
  rightIcon,
}: FacilityOptionProps ) => {
  const handleOnClick = () => {
    onChange( facility.id );
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleOnClick}
      className={styles.facilityOptionContainer}
    >
      <div className={styles.facilityNameAndAddressContainer}>
        <Radio
          checked={selectedFacilityId === facility.id}
          name="radio-buttons"
          inputProps={{ 'aria-label': `radio-button-${facility.id}` }}
        />
        <div>
          <p className={styles.body2}>{facility.name}</p>
          <p>{facility.address}</p>
        </div>
      </div>

      {rightIcon}

    </div>

  );
};

export default FacilityOption;
