import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { WithHeader } from '../../hocs';
import { useQuery } from '../../hooks';
import { useSetNewPasswordCoordinator } from '../../view-coordinators';
import { Form, NewPasswordSuccess, TokenInvalid } from './components';
import styles from './styles.module.scss';

const SetNewPassword: React.FC = () => {
  const view = useSetNewPasswordCoordinator();
  const history = useHistory();
  const query = useQuery();

  useEffect( () => {
    const token = query.get( 'token' );
    if ( token ) {
      view.validateToken( token );
    } else {
      history.replace( '/', {} );
    }
  }, [ query, history, view ] );

  const renderLoader = () => (
    <h4>Loading...</h4>
  );

  const renderContent = () => (
    view.renderCallback ? <NewPasswordSuccess /> : (
      <Form
        onSubmit={view.setNewPassword.bind( view )}
        validate={view.validateForm}
      />
    ) );

  const renderTokenExpired = () => <TokenInvalid />;

  return (
    <section className={styles.container}>
      {!view.token && !view.tokenHasExpired && renderLoader()}
      {view.token && renderContent()}
      {!view.token && view.tokenHasExpired && renderTokenExpired()}
    </section>
  );
};

export default WithHeader( observer( SetNewPassword ) );
