import React from 'react';
import { Button } from '../..';
import styles from './styles.module.scss';

interface DialogButtonsProps {
  confirmationButtonText: string;
  cancelButtonText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const DialogConfirmationAndCancelButtons : React.FC<DialogButtonsProps> = ( {
  confirmationButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
} ) => (
  <div className={styles.buttonsContainer}>
    <Button
      label={confirmationButtonText}
      onClick={onConfirm}
      variant="primary"
    />
    <button type="button" onClick={onCancel} className="linkText">
      {cancelButtonText}
    </button>
  </div>
);

export default DialogConfirmationAndCancelButtons;
