import { useState } from 'react';
import DeleteAccountView from '../views/DeleteAccountView';
import { useRootStore } from '../providers/RootStoreProvider';
import { Api, CurrentUserService } from '../services';
import { FetchCurrentUserDataInteractor, DeleteAccountInteractor } from '../interactors';
import useErrorHandler from '../views/errors/useErrorHandler';

const UseDeleteAccountCoordinator = () => {
  const { currentUserStore, authStore } = useRootStore();
  const errorHandler = useErrorHandler();

  const createView = () => {
    const api = new Api( { authStore } );
    const currentUserService = new CurrentUserService( { api } );
    const fetchCurrentUserData = new FetchCurrentUserDataInteractor( {
      store: currentUserStore,
      service: currentUserService,
    } );
    const deleteAccountInteractor = new DeleteAccountInteractor( {
      authStore,
      service: currentUserService,
    } );

    return new DeleteAccountView( {
      currentUserStore,
      fetchCurrentUserData,
      deleteAccountInteractor,
      errorHandler,
    } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default UseDeleteAccountCoordinator;
