/* eslint-disable camelcase */
import { AxiosResponseType, Line } from '../services/TotalChargesService';
import { PromoCode, ExtraFee } from '.';

export interface DeserializedLine {
  playerId: number,
  membershipPlanId: number,
  fullPrice: number,
  discount: number | null,
  discountPercentage: number | null,
  subtotal: number,
}

interface TotalChargesProps{
  lines: DeserializedLine[],
  promoCode: PromoCode | null,
  transactionFee: number,
  fullPrice: number,
  priceWithoutPromoCode: number,
  netPrice: number,
  extraFee?: ExtraFee;
  afterFees: number;
}

export default class TotalCharges {
  lines: DeserializedLine[];

  promoCode: PromoCode | null;

  transactionFee: number;

  fullPrice: number;

  priceWithoutPromoCode: number;

  netPrice: number;

  afterFees: number;

  extraFee?: ExtraFee;

  constructor( {
    lines,
    promoCode,
    transactionFee,
    fullPrice,
    priceWithoutPromoCode,
    netPrice,
    extraFee = ExtraFee.empty(),
    afterFees,
  }: TotalChargesProps ) {
    this.lines = lines;
    this.promoCode = promoCode;
    this.transactionFee = transactionFee;
    this.fullPrice = fullPrice;
    this.priceWithoutPromoCode = priceWithoutPromoCode;
    this.netPrice = netPrice;
    this.extraFee = extraFee;
    this.afterFees = afterFees;
  }

  static empty() {
    return new TotalCharges( {
      lines: [],
      promoCode: null,
      transactionFee: 0,
      fullPrice: 0,
      priceWithoutPromoCode: 0,
      netPrice: 0,
      extraFee: undefined,
      afterFees: 0,
    } );
  }

  static fromJSON( response: AxiosResponseType ) {
    return new TotalCharges( {
      lines: TotalCharges.deserializeLines( response.lines ),
      promoCode: response.promo_code
        ? PromoCode.fromJSON( response.promo_code )
        : null,
      transactionFee: response.transaction_fee,
      fullPrice: response.full_price,
      priceWithoutPromoCode: response.price_without_promo_code,
      netPrice: response.net_price,
      extraFee: ExtraFee.fromJSON( response.technology_platform_fee ),
      afterFees: response.price_after_fees,
    } );
  }

  private static deserializeLines( lines: Line[] ) : DeserializedLine[] {
    return lines.map( ( {
      player_id,
      membership_plan_id,
      full_price,
      discount,
      discount_percentage,
      subtotal,
    } ) => ( {
      discount,
      subtotal,
      playerId: player_id,
      membershipPlanId: membership_plan_id,
      fullPrice: full_price,
      discountPercentage: discount_percentage,
    } ) );
  }

  changePlayerIdInLines( oldPlayerId: number, newPlayerId:number ) {
    this.lines = this.lines.map( ( line ) => (
      ( line.playerId === oldPlayerId )
        ? {
          playerId: newPlayerId,
          membershipPlanId: line.membershipPlanId,
          fullPrice: line.fullPrice,
          discount: line.discount,
          discountPercentage: line.discountPercentage,
          subtotal: line.subtotal,
        }
        : line ) );
  }
}
