import { useHistory } from 'react-router-dom';

import { useRootStore } from '../../providers/RootStoreProvider';
import ErrorHandler from './ErrorHandler';

const useErrorHandler = () => {
  const { authStore, currentUserStore } = useRootStore();
  const history = useHistory();
  return new ErrorHandler( { currentUserStore, authStore, history } );
};

export default useErrorHandler;
