/* eslint-disable camelcase */
import {
  USERS_URL,
  CURRENT_USER_DATA_URL,
  RECOVER_PASSWORD_LINK_URL,
  SET_NEW_PASSWORD_URL,
  VALIDATE_RESET_PASSWORD_TOKEN_URL,
  AUTH_URL,
  LOGOUT_URL,
  EDIT_ACCOUNT_URL,
  DELETE_ACCOUNT_URL,
  ASSIGN_PROMO_CODE_URL,
  UNASSIGN_PROMO_CODE_URL,
  VALIDATE_EMAIL_URL,
  BILLING_PORTAL_URL, LEAVIMG_REASONS_URL, CHOOSE_NEW_FACILITY_URL,
} from '../constants/services';
import { AccountHolder } from '../entities';
import { AccountHolderFormValues } from '../screens/CreateAccount/components/CreateAccountForm';
import { Api } from '.';
import { MembershipPlanJson } from './MembershipPlansService';
import { AccountHolderValues } from '../views/EditAccountHolderView';

export interface FacilityJson {
  id: number;
  name: string;
  address_line_one: string;
  sport: string;
}

interface MembershipBenefitJson {
  id: number;
  training_type_id: number;
  amount: number;
}

export interface MembershipJson {
  id: number;
  membership_plan_id: number;
  name: string;
  price: string;
  duration_in_months: number;
  recurrence: boolean;
  description: string;
  version_number: number;
  membership_benefits: MembershipBenefitJson[];
}

export interface PlayerJson {
  id: number;
  first_name: string;
  last_name: string;
  birthday: string;
  school: string;
  grade: string;
  membership_plan_version?: MembershipJson;
  membership_plan_info?: MembershipPlanJson;
  membership_valid_until?: string;
}

export interface BillingInformationJSON {
  billing_date?: string;
  billing_total?: number;
  active_memberships: string[];
  card_last4?: string;
}

export interface PromoCodeJSON {
  value: number;
  name: string;
  expiration_date?: string;
}

export interface AccountHolderJson {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  password: string;
  facility: FacilityJson;
  players: PlayerJson[];
  subscription_valid_until: string;
  promo_code: PromoCodeJSON;
  billing_info: BillingInformationJSON;
}

export interface AccountHolderData {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  facility_id: number;
  subscription_valid_until: string;
}

export interface AccountHolderPost {
  account_holder_data: AccountHolderData;
}

export default class CurrentUserService {
  api: Api;

  builder: typeof AccountHolder.fromJSON;

  constructor( {
    api = new Api(),
    builder = AccountHolder.fromJSON,
  } = {} ) {
    this.api = api;
    this.builder = builder;
  }

  async fetchCurrentUserData() {
    const result: AccountHolderJson = await this.api.get( CURRENT_USER_DATA_URL );
    return this.builder( result );
  }

  login( email: string, password: string ) {
    return this.api.post( AUTH_URL, {
      email,
      password,
      grant_type: 'password',
    } );
  }

  logout( token: string ) {
    return this.api.post( LOGOUT_URL, undefined, {
      params: {
        token,
      },
    } );
  }

  createAccount( parentAccountData: AccountHolderFormValues ) {
    const account_holder_data = {
      first_name: parentAccountData.firstName,
      last_name: parentAccountData.lastName,
      email: parentAccountData.email,
      phone: parentAccountData.phone,
      password: parentAccountData.password,
      facility_id: +parentAccountData.facilityId,
    };

    return this.api.post( USERS_URL, { account_holder_data } );
  }

  sendRecoverPasswordLink( email: string ) {
    return this.api.post( RECOVER_PASSWORD_LINK_URL, { email } );
  }

  setNewPassword( token: string, password: string ) {
    return this.api.post(
      SET_NEW_PASSWORD_URL,
      { token, new_password: password },
    );
  }

  async validateResetPasswordToken( token: string ) {
    const result = await this.api.post( VALIDATE_RESET_PASSWORD_TOKEN_URL, { token } );
    return result.is_valid;
  }

  editAccount( accountData: AccountHolderValues ) {
    return this.api.patch(
      EDIT_ACCOUNT_URL,
      { account_holder_data: accountData },
    );
  }

  deleteAccount( reasonId?:number, customReason?:string ) {
    const headers = this.headersForDeleteAccount( reasonId, customReason );
    const config = ( headers ) ? { headers } : undefined;
    return this.api.delete(
      DELETE_ACCOUNT_URL,
      config,
    );
  }

  private headersForDeleteAccount( reasonId?:number, customReason?:string ) {
    if ( !!reasonId && !!customReason ) {
      return {
        'X-Reason-Id': reasonId,
        'X-Custom-Reason': customReason,
      };
    }
    if ( reasonId ) {
      return {
        'X-Reason-Id': reasonId,
      };
    }
    if ( customReason ) {
      return {
        'X-Custom-Reason': customReason,
      };
    }
    return undefined;
  }

  assignPromoCode( promocode:string ) {
    return this.api.post(
      ASSIGN_PROMO_CODE_URL,
      { promo_code_name: promocode },
    );
  }

  unassignPromoCode() {
    return this.api.post( UNASSIGN_PROMO_CODE_URL );
  }

  validateEmail( email:string ) {
    return this.api.post(
      VALIDATE_EMAIL_URL,
      { email },
    );
  }

  editCreditCard( returnURL:string ) {
    return this.api.post(
      BILLING_PORTAL_URL,
      { return_url: returnURL },
    );
  }

  leavingReasons() {
    return this.api.get(
      LEAVIMG_REASONS_URL,
    );
  }

  chooseNewFacility( selectedFacilityId:number ) {
    return this.api.patch(
      CHOOSE_NEW_FACILITY_URL,
      { facility_id: selectedFacilityId },
    );
  }
}
