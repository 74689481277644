export const CREATE_ACCOUNT_URL = '/create_account';
export const PAYMENT_CANCEL_URL = CREATE_ACCOUNT_URL;
export const PAYMENT_SUCCESS_URL = '/payment_success';
export const RECOVER_PASSWORD_URL = '/recover_password';
export const SET_NEW_PASSWORD_URL = '/new_password';
export const HOME_URL = '/home';
export const CREATE_PLAYERS_URL = '/create_players';
export const EDIT_PLAYER_URL = '/edit_player/:id';
export const ASSIGN_MEMBERSHIP_URL = '/players/:id/assign_membership';
export const PRIVACY_POLICY_URL = '/privacy_policy';
export const TERMS_AND_CONDITIONS_URL = '/terms_and_conditions';
export const ROOT = '/';
export const PLAYER = '/player/:id';
export const EDIT_ACCOUNT_HOLDER_URL = '/edit_account';
export const DELETE_ACCOUNT_URL = '/delete_account';
export const PROMO_CODE_MANAGEMENT = '/promo_code';
export const FAQ_URL = '/faq';
export const CHANGE_FACILITY_URL = '/change_facility';
