import React from 'react';
import cn from 'classnames';
import { ConfirmationDialog } from '..';
import styles from './styles.module.scss';

interface RenewMembershipConfirmationDialogProps {
  open: boolean;
  membership: string;
  onConfirm: () => void;
  onClose: () => void;
}

const RenewMembershipConfirmationDialog :
  React.FC<RenewMembershipConfirmationDialogProps> = (
    { membership, ...props },
  ) => {
    const bodyComponent = (
      <p className={cn( 'body1', styles.dialogBodyText )}>
        You are about to
        {' '}
        <b>renew</b>
        {' '}
        <b>{membership}</b>
        . Changes will apply instantly.
      </p>
    );

    return (
      <ConfirmationDialog
        title="Renew this membership"
        body={bodyComponent}
        confirmationButtonText="go to cart"
        cancelButtonText="Cancel"
        {...props}
      />
    );
  };

export default RenewMembershipConfirmationDialog;
