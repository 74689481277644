import React from 'react';

import { LoginButton, SwishButton } from './components';
import styles from './styles.module.scss';

interface HeaderProps {
  showLoginButton?: boolean;
}

const Header: React.FC<HeaderProps> = ( { showLoginButton = true } ) => (
  <header className={styles.navbar}>
    <SwishButton />
    {showLoginButton && <LoginButton />}
  </header>
);

export default Header;
