import { FormikErrors, FormikHelpers } from 'formik';
import { makeAutoObservable, runInAction } from 'mobx';

import { EMAIL_CRITERIA } from '../constants';
import { SwishError } from '../errors';
import { LoginInteractor } from '../interactors';
import { LoginFormValues } from '../screens/Landing/components/LoginForm';

interface LoginViewParams {
  loginInteractor?: LoginInteractor;
}

export default class LoginView {
  loginInteractor: LoginInteractor;

  errorMessage?: string;

  loginSuccessful = false;

  constructor( {
    loginInteractor = new LoginInteractor(),
  }: LoginViewParams = {} ) {
    this.loginInteractor = loginInteractor;
    this.errorMessage = undefined;

    this.login = this.login.bind( this );
    this.validateForm = this.validateForm.bind( this );
    makeAutoObservable( this );
  }

  private validateEmail( email: string, errors: FormikErrors<LoginFormValues> ) {
    if ( !email ) errors.email = 'Required';
    else if ( !EMAIL_CRITERIA.test( email ) ) {
      errors.email = 'Invalid email address';
    }
  }

  private validatePassword( password: string, errors: FormikErrors<LoginFormValues> ) {
    if ( !password ) errors.password = 'Required';
    else if ( password.length < 6 ) {
      errors.password = 'Password is too short (minimum 6 characters)';
    }
  }

  private badCredentialsError() {
    this.errorMessage = 'Incorrect username or password';
  }

  validateForm( values: LoginFormValues ) {
    const errors: FormikErrors<LoginFormValues> = {};

    this.validateEmail( values.email, errors );
    this.validatePassword( values.password, errors );

    return errors;
  }

  async login(
    values: LoginFormValues,
    { setSubmitting }: Partial<FormikHelpers<LoginFormValues>>,
  ) {
    try {
      this.errorMessage = undefined;
      await this.loginInteractor.execute( values.email, values.password );
      runInAction( () => { this.loginSuccessful = true; } );
    } catch ( error ) {
      const e = error as SwishError;
      if ( e.isInputError() ) this.badCredentialsError();
      setSubmitting!( false );
    }
  }
}
