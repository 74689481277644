import { CurrentUserService } from '../services';
import { AuthStore } from '../stores';

export default class LogoutInteractor {
  service: CurrentUserService;

  authStore: AuthStore;

  constructor( {
    service = new CurrentUserService(),
    authStore = new AuthStore(),
  } = {} ) {
    this.service = service;
    this.authStore = authStore;
  }

  execute() {
    const { token } = this.authStore;
    this.authStore.removeAccessToken();
    return this.service.logout( token as string );
  }
}
