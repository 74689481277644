import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as Coolicon } from '../../../assets/icons/coolicon/coolicon.svg';

interface LogoutButtonProps {
  onClick?: () => void;
}

const LogoutButton: React.FC<LogoutButtonProps> = ( { onClick } ) => (
  <button type="button" onClick={onClick} className={styles.logoutButton}>
    <p className={cn( styles.body1, styles.logoutText )}>Log out</p>
    <Coolicon className={styles.coolicon} />
  </button>
);

export default LogoutButton;
