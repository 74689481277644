import { makeAutoObservable } from 'mobx';

import { Player } from '../entities';
import { SwishError } from '../errors';
import { FetchMembershipPlansInteractor } from '../interactors';
import { BillingCycleStore, MembershipPlansStore } from '../stores';
import ErrorHandler from './errors/ErrorHandler';

export type SelectablePlayer = Player & {
  selected: boolean;
  membershipPlanId?: number;
}

interface CreatePlayersStep2ViewParams {
  fetchMembershipPlansInteractor?: FetchMembershipPlansInteractor;
  membershipPlansStore?: MembershipPlansStore;
  billingCycleStore?: BillingCycleStore;
  errorHandler?: ErrorHandler;
}

export default class CreatePlayersStep2View {
  private membershipPlansStore: MembershipPlansStore;

  private billingCycleStore: BillingCycleStore;

  private errorHandler: ErrorHandler;

  private fetchMembershipPlansInteractor: FetchMembershipPlansInteractor;

  selectablePlayers: SelectablePlayer[] = [];

  selectedFacilityId = 0;

  constructor( {
    membershipPlansStore = new MembershipPlansStore(),
    billingCycleStore = new BillingCycleStore(),
    errorHandler = new ErrorHandler(),
    fetchMembershipPlansInteractor = new FetchMembershipPlansInteractor(),
  }: CreatePlayersStep2ViewParams = {} ) {
    this.membershipPlansStore = membershipPlansStore;
    this.billingCycleStore = billingCycleStore;
    this.errorHandler = errorHandler;
    this.fetchMembershipPlansInteractor = fetchMembershipPlansInteractor;

    makeAutoObservable( this );
  }

  private selectPlayer( membershipPlanId: number, selectedPlayer: SelectablePlayer ) {
    this.selectablePlayers = this.selectablePlayers.map( ( player ) => (
      player.id === selectedPlayer.id
        ? { ...player, membershipPlanId, selected: true } as SelectablePlayer
        : player
    ) );
  }

  private unselectPlayer( removedPlayer: SelectablePlayer ) {
    this.selectablePlayers = this.selectablePlayers.map( ( player ) => (
      player.id === removedPlayer.id
        ? { ...player, selected: false, membershipPlanId: undefined } as SelectablePlayer
        : player
    ) );
  }

  get selectedPlayers() {
    return this.selectablePlayers.filter( ( { selected } ) => selected );
  }

  get billingCycles() {
    return this.billingCycleStore.billingCycles;
  }

  get membershipPlans() {
    return this.storedMembershipPlans
      .filter( plan => plan.facility.id === this.selectedFacilityId )
      .sort( ( a, b ) => a.price - b.price )
        ?? [];
  }

  private get storedMembershipPlans() {
    return this.membershipPlansStore.allFacilitiesMembershipPlans;
  }

  async fetchData() {
    try {
      await this.fetchMembershipPlansInteractor.execute();
    } catch ( e ) {
      this.errorHandler.handleError( e as SwishError );
    }
  }

  initSelectablePlayers( players: Player[] ) {
    this.selectablePlayers = players.map(
      ( player ) => ( {
        ...player,
        selected: false,
      } as SelectablePlayer ),
    ) ?? [];
  }

  handleSelectPlayer( membershipPlanId: number, selectedPlayer: SelectablePlayer ) {
    this.selectPlayer( membershipPlanId, selectedPlayer );
  }

  handleRemovePlayer( removedPlayer: SelectablePlayer ) {
    this.unselectPlayer( removedPlayer );
  }

  selectFacility( facility: number ) {
    this.selectedFacilityId = facility;
  }
}
