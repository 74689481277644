import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styles from '../styles.module.scss';
import TooltipWithMaxWidth from './TooltipWithMaxWidth';

interface DisclaimerTooltipProps {
  description: string;
}

const DisclaimerTooltip: React.FC<DisclaimerTooltipProps> = ( {
  description,
}: DisclaimerTooltipProps ) => {
  const [ open, setOpen ] = React.useState( false );

  const handleTooltipClose = () => {
    setOpen( false );
  };

  const handleTooltipOpen = () => {
    setOpen( true );
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <TooltipWithMaxWidth
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={description}
        arrow
        placement="top-end"
      >
        <button
          type="button"
          aria-label="disclaimer button"
          onClick={handleTooltipOpen}
          className={styles.disclaimerIcon}
        />
      </TooltipWithMaxWidth>
    </ClickAwayListener>
  );
};

export default DisclaimerTooltip;
