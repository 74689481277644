export const DEFAULT_FACILITY_NAME = 'Choose default facility';
export const DEFAULT_KID_NAME = 'Select players';
export const DEFAULT_GRADE_NAME = 'Grade / Year';
export const DEFAULT_DATEPICKER_DATE = '01/01/2010';

// Valid phone formats:
// (123) 456-7890
// (123)456-7890
// 123-456-7890
// 123.456.7890
// 1234567890
// +31636363634
// 075-63546725
export const PHONE_NUMBER_CRITERIA = /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/i;

export const EMAIL_CRITERIA = /^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,}$/i;

export const gradesDropdown = [
  { id: DEFAULT_GRADE_NAME, name: DEFAULT_GRADE_NAME },
  { id: '1st grade', name: '1st grade' },
  { id: '2nd grade', name: '2nd grade' },
  { id: '3rd grade', name: '3rd grade' },
  { id: '4th grade', name: '4th grade' },
  { id: '5th grade', name: '5th grade' },
  { id: '6th grade', name: '6th grade' },
  { id: '7th grade', name: '7th grade' },
  { id: '8th grade', name: '8th grade' },
  { id: '9th grade', name: '9th grade' },
  { id: '10th grade', name: '10th grade' },
  { id: '11th grade', name: '11th grade' },
  { id: '12th grade', name: '12th grade' },
  { id: '1st year', name: '1st year' },
  { id: '2nd year', name: '2nd year' },
  { id: '3rd year', name: '3rd year' },
  { id: '4th year', name: '4th year' },
  { id: '5th year', name: '5th year' },
];
