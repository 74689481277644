import { MembershipPlanJson } from '../services/MembershipPlansService';
import Facility from './Facility';

export interface PromotionalBenefits {
  description: string;
}

interface MembershipPlanProps {
  id: number;
  name: string;
  tier: string;
  icon: string;
  price: number;
  benefits: PromotionalBenefits[];
  facility: Facility;
  published: boolean;
}

export default class MembershipPlan {
  id: number;

  name: string;

  tier: string;

  icon: string;

  price: number;

  published: boolean;

  benefits: PromotionalBenefits[];

  facility: Facility;

  constructor( {
    id,
    name,
    tier,
    icon,
    price,
    benefits,
    facility,
    published,
  }: MembershipPlanProps ) {
    this.id = id;
    this.name = name;
    this.tier = tier;
    this.icon = icon;
    this.price = price;
    this.benefits = benefits;
    this.facility = facility;
    this.published = published;
  }

  static empty = () => new MembershipPlan( {
    id: 0,
    name: 'No Membership',
    tier: 'No Tier',
    icon: 'No Icon',
    price: 0,
    benefits: [],
    facility: Facility.empty(),
    published: false,
  } );

  static fromJSON = ( json: MembershipPlanJson ) => new MembershipPlan( {
    id: json.id,
    name: json.name,
    tier: json.tier,
    price: json.price,
    icon: json.icon,
    benefits: json.promotional_benefits?.map( ( description ) => description ),
    facility: Facility.fromJSON( json.facility ),
    published: json.published,
  } );
}
