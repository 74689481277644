import React, { useMemo } from 'react';

import { NullRootStore, RootStore, RootStoreI } from '../stores';

const RootStoreContext = React.createContext<RootStoreI>( new NullRootStore() );

interface RootStoreProviderProps {
  children: React.ReactNode;
}

const RootStoreProvider: React.FC<RootStoreProviderProps> = (
  { children }: RootStoreProviderProps,
) => {
  const rootStore = useMemo( () => new RootStore(), [] );
  return (
    <RootStoreContext.Provider value={rootStore}>
      {children}
    </RootStoreContext.Provider>
  );
};

export const useRootStore = () => {
  const store = React.useContext( RootStoreContext );
  if ( !store.isInitialized() ) {
    throw new Error( 'useRootStore must be used within a RootStoreProvider.' );
  }
  return store;
};

export default RootStoreProvider;
