import { FacilityJson } from '../services/CurrentUserService';

interface FacilityProps {
  id: number;
  name: string;
  address: string;
  sport: string;
}

export default class Facility {
  id: number;

  name: string;

  address: string;

  sport: string;

  constructor( {
    id, name, address, sport,
  }: FacilityProps ) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.sport = sport;
  }

  static fromJSON = ( json: FacilityJson ) => new Facility( {
    id: json.id,
    name: json.name,
    address: json.address_line_one,
    sport: json.sport,
  } );

  static empty = () => new Facility( {
    id: 0,
    name: '',
    address: '',
    sport: 'Basketball',
  } )
}
