import React, { memo } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';
import alert from '../../assets/icons/alert-triangle/alert-triangle.png';

interface ErrorMessageProps {
  errorText?: string;
  touched?: boolean;
  className?:string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = (
  {
    errorText = '',
    touched = false,
    className = '',
  }: ErrorMessageProps,
) => (
  !!errorText && touched
    ? (
      <div className={cn( styles.container, className )}>
        <img className={styles.alertTriangle} src={alert} alt="alert" />
        <span className={cn( styles.errorMessage, 'body1' )}>{errorText}</span>
      </div>
    )
    : null
);

export default memo( ErrorMessage );
