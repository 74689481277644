import {
  RootStoreI,
  AuthStore,
  CurrentUserStore,
  FacilitiesStore,
  MembershipPlansStore,
  BillingCycleStore,
} from '.';

export default class NullRootStore implements RootStoreI {
  authStore: AuthStore = {} as AuthStore;

  currentUserStore: CurrentUserStore = {} as CurrentUserStore;

  facilitiesStore: FacilitiesStore = {} as FacilitiesStore;

  membershipPlansStore: MembershipPlansStore = {} as MembershipPlansStore;

  billingCycleStore: BillingCycleStore = {} as BillingCycleStore;

  isInitialized = () => false;
}
