/* eslint-disable camelcase */
import React from 'react';

import { Formik, FormikErrors } from 'formik';
import cn from 'classnames';
import styles from '../../CreateAccount/components/styles.module.scss';
import {
  Button, FormDropdown, InputContainer,
} from '../../../components';
import { PHONE_NUMBER_CRITERIA } from '../../../constants';
import { AccountHolderValues } from '../../../views/EditAccountHolderView';
import { Facility } from '../../../entities';

const isDisabled = (
  isSubmitting: boolean,
  values: AccountHolderValues,
  dirty: boolean,
) => isSubmitting || (
  Object.values( values ).filter( v => ( v === '' ) ).length > 0
    || !dirty
);

const errorValidationsEditAccount = ( values:AccountHolderValues ) => {
  const errors: FormikErrors<AccountHolderValues> = {};

  if ( !values.first_name ) errors.first_name = 'Required';
  if ( !values.last_name ) errors.last_name = 'Required';

  if ( !values.phone ) errors.phone = 'Required';
  else if (
    !PHONE_NUMBER_CRITERIA.test( values.phone )
  ) {
    errors.phone = 'Invalid phone number';
  }

  return errors;
};

interface EditAccountFormProps {
  onSubmit?: ( accountData: AccountHolderValues ) => Promise<void>;
  initialValues : AccountHolderValues;
  sameSportFacilities?: Facility[];
}

const EditAccountForm :React.FC<EditAccountFormProps> = (
  {
    onSubmit = async () => { /* do nothing */ },
    initialValues,
    sameSportFacilities = [],
  }:EditAccountFormProps,
) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    validate={values => errorValidationsEditAccount( values )}
    onSubmit={async ( values, { setSubmitting } ) => {
      await onSubmit( values );
      setSubmitting( false );
    }}
  >
    {( {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      isValidating,
      dirty,
    } ) => (
      <form onSubmit={handleSubmit}>
        <div className={styles.row}>
          <div className={styles.col}>
            <InputContainer
              name="first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
              label="First Name"
              error={errors.first_name}
              touched={!!touched.first_name}
            />
          </div>
          <div className={styles.col}>
            <InputContainer
              name="last_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_name}
              label="Last Name"
              error={errors.last_name}
              touched={!!touched.last_name}
            />
          </div>
        </div>
        <InputContainer
          type="phone"
          name="phone"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone}
          label="Phone Number"
          error={errors.phone}
          touched={!!touched.phone}
        />

        <h4 className={cn( 'subheader', styles.subHeader )}>Default Facility</h4>

        <div>
          <FormDropdown
            options={sameSportFacilities}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.booking_facility_id}
            index={values.booking_facility_id}
            dropdownId="booking_facility_id"
          />
        </div>

        <Button
          className={styles.submitBtn}
          type="submit"
          disabled={isDisabled( isSubmitting, values, dirty )}
          label="SAVE CHANGES"
          loading={isSubmitting && !isValidating}
          loadingType="Oval"
        />
      </form>
    )}
  </Formik>
);

export default EditAccountForm;
