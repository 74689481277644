import { SxProps } from '@mui/material';

const rootSx : SxProps = {
  '& .MuiPaper-root': {
    padding: '2rem',
    borderRadius: '12px',
  },
};

const closeIconSx : SxProps = {
  position: 'absolute',
  right: 10,
  top: 10,
  color: 'black',
};

export {
  rootSx,
  closeIconSx,
};
