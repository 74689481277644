/* eslint-disable camelcase */
import { parse } from 'date-fns';
import { Facility, Player, BillingInformation } from '.';
import { AccountHolderJson } from '../services/CurrentUserService';
import PromoCode from './PromoCode';

interface AccountHolderProps {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  facility: Facility;
  players: Player[];
  subscriptionValidUntil?: Date
  promoCode: PromoCode | null ;
  billingInformation: BillingInformation | null;
}

export default class AccountHolder {
  id: number;

  email: string;

  firstName: string;

  lastName: string;

  phone: string;

  facility: Facility;

  players: Player[];

  subscriptionValidUntil?: Date;

  promoCode: PromoCode | null;

  billingInformation: BillingInformation | null;

  constructor( {
    id,
    email,
    firstName,
    lastName,
    phone,
    facility,
    players,
    subscriptionValidUntil,
    promoCode,
    billingInformation,
  }: AccountHolderProps ) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.facility = facility;
    this.players = players;
    this.subscriptionValidUntil = subscriptionValidUntil;
    this.promoCode = promoCode;
    this.billingInformation = billingInformation;
  }

  static fromJSON( json: AccountHolderJson ) {
    return new AccountHolder( {
      id: json.id,
      email: json.email,
      firstName: json.first_name,
      lastName: json.last_name,
      phone: json.phone,
      facility: Facility.fromJSON( json.facility || { id: 0, name: '', address_line_one: '' } ),
      players: json.players?.map( ( player ) => Player.fromJSON( player ) ),
      subscriptionValidUntil: json.subscription_valid_until
        ? parse( json.subscription_valid_until, 'yyyy-MM-dd', new Date() )
        : undefined,
      promoCode: json.promo_code
        ? PromoCode.fromJSON( json.promo_code )
        : null,
      billingInformation: json.billing_info
        ? BillingInformation.fromJSON( json.billing_info )
        : null,
    } );
  }

  get fullname() {
    return `${this.firstName} ${this.lastName}`;
  }

  anyPlayerHasMembership() {
    return this.players.some( ( player ) => player.hasMembership() );
  }
}
