import { PlayersService } from '../services';
import { PlayerData } from '../services/PlayersService';

export default class EditPlayerInteractor {
  service: PlayersService;

  constructor( { service = new PlayersService() } = {} ) {
    this.service = service;
  }

  async execute( playerId: number, playerData: PlayerData ) {
    return this.service.editPlayer( playerId, playerData );
  }
}
