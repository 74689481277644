import { CurrentUserService } from '../services';

export default class GoToEditCardInteractor {
  service: CurrentUserService;

  constructor( { service = new CurrentUserService() } = {} ) {
    this.service = service;
  }

  execute( returnURL : string ) {
    return this.service.editCreditCard( returnURL );
  }
}
