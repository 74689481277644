/* eslint-disable camelcase */
import { makeAutoObservable } from 'mobx';
import ReactGA from 'react-ga';
import { CurrentUserStore } from '../stores';
import { SwishError } from '../errors';
import { FetchCurrentUserDataInteractor, FetchFacilitiesInteractor } from '../interactors';
import ErrorHandler from './errors/ErrorHandler';
import EditAccountInteractor from '../interactors/EditAccountInteractor';
import FacilitiesStore from '../stores/FacilitiesStore';

export type AccountHolderValues = {
  first_name: string;
  last_name: string;
  phone: string;
  booking_facility_id: string;
};

interface EditAccountHolderViewParams{
  currentUserStore?: CurrentUserStore;
  fetchCurrentUserData?: FetchCurrentUserDataInteractor;
  errorHandler?: ErrorHandler;
  editAccountInteractor?: EditAccountInteractor;
  fetchFacilitiesInteractor?: FetchFacilitiesInteractor;
  facilitiesStore?: FacilitiesStore;
}

export default class EditAccountHolderView {
  private currentUserStore : CurrentUserStore;

  private fetchCurrentUserData: FetchCurrentUserDataInteractor;

  private errorHandler: ErrorHandler;

  private editAccountInteractor: EditAccountInteractor;

  private facilitiesStore: FacilitiesStore;

  private fetchFacilitiesInteractor: FetchFacilitiesInteractor;

  editAccountSuccessful = false;

  constructor( {
    currentUserStore = new CurrentUserStore(),
    fetchCurrentUserData = new FetchCurrentUserDataInteractor(),
    errorHandler = new ErrorHandler(),
    editAccountInteractor = new EditAccountInteractor(),
    facilitiesStore = new FacilitiesStore(),
    fetchFacilitiesInteractor = new FetchFacilitiesInteractor(),
  }:EditAccountHolderViewParams ) {
    this.currentUserStore = currentUserStore;
    this.fetchCurrentUserData = fetchCurrentUserData;
    this.errorHandler = errorHandler;
    this.editAccountInteractor = editAccountInteractor;
    this.facilitiesStore = facilitiesStore;
    this.fetchFacilitiesInteractor = fetchFacilitiesInteractor;
    makeAutoObservable( this );

    this.getAccountHolderData();
  }

  private async getAccountHolderData() {
    try {
      await this.fetchCurrentUserData.execute();
      await this.fetchFacilitiesInteractor.execute();
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
      this.setPlaceholderFacilities();
    }
  }

  private setPlaceholderFacilities() {
    this.facilitiesStore.setFacilities( [] );
  }

  get personalInformation() {
    return {
      first_name: this.currentUserStore.currentUser?.firstName ?? '',
      last_name: this.currentUserStore.currentUser?.lastName ?? '',
      phone: this.currentUserStore.currentUser?.phone ?? '',
      booking_facility_id: this.currentDefaultFacilityId
          ?? '',
    };
  }

  private get currentDefaultFacilityId() {
    return this.currentUserStore.currentUser?.facility.id.toString();
  }

  async editAccount( accountData: AccountHolderValues ) {
    this.editAccountSuccessful = false;
    try {
      await this.editAccountInteractor.execute( accountData );
      this.afterEditing( accountData );
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }

  get facilities() {
    return this.facilitiesStore.sameSportFacilities;
  }

  private afterEditing( accountData: AccountHolderValues ) {
    this.logEventToAnalytics( accountData );
    this.editAccountSuccessful = true;
  }

  private logEventToAnalytics( accountData:AccountHolderValues ) {
    if ( accountData.booking_facility_id !== this.currentDefaultFacilityId ) {
      this.logDefaultFacilityChange(
          this.currentDefaultFacilityId!,
          accountData.booking_facility_id,
      );
    }
  }

  private logDefaultFacilityChange( old_facility_id: string, new_facility_id:string ) {
    ReactGA.event( {
      category: 'User',
      action: `change Default Facility: ${old_facility_id} to ${new_facility_id}`,
    } );
  }
}
