import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';
import { closeIconSx } from '../styles';
import styles from './styles.module.scss';

interface DialogTitleProp {
  title : string;
  onClose: () => void;
}
const DialogTitleWithCloseIcon : React.FC<DialogTitleProp> = ( { title, onClose } ) => (
  <div className={styles.titleContainer}>
    <h2 className={cn( 'header2', styles.dialogTitleText )}>{title}</h2>
    <IconButton onClick={onClose} sx={closeIconSx} aria-label="close">
      <CloseIcon />
    </IconButton>
  </div>
);

export default DialogTitleWithCloseIcon;
