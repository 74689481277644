import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './styles.module.scss';
import { ResponsiveContainer } from '../../components';
import { WithHeader } from '../../hocs';
import {
  FAQTitle, FAQList,
} from './components';
import { useFAQCoordinator } from '../../view-coordinators';

const FAQ: React.FC = () => {
  const view = useFAQCoordinator();

  return (
    <ResponsiveContainer sectionStyle={styles.backgroundContainer} boxStyle={styles.box}>

      <FAQTitle />

      <FAQList view={view} />

    </ResponsiveContainer>
  );
};

export default WithHeader( observer( FAQ ) );
