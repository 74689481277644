/* eslint-disable no-restricted-globals */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { WithHeader } from '../../hocs';
import { HOME_URL } from '../../constants/routes';
import { PlayerForm, ResponsiveContainer } from '../../components';
import { IPlayerForm, PlayersFormValues } from '../../components/PlayerForm/PlayerForm.component';
import { useEditPlayerCoordinator } from '../../view-coordinators';
import styles from './styles.module.scss';
import BackArrowWithLabel from '../../components/BackArrowWithLabel/BackArrowWithLabel.component';

interface Params {
  id: string;
}

const EditPlayer: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const view = useEditPlayerCoordinator();
  const [ initialValues, setInitialValues ] = useState<PlayersFormValues>();

  useEffect( () => {
    view.fetchMembershipPlans();
  }, [ view ] );

  useEffect( () => {
    if ( Number.isNaN( +id ) ) {
      history.replace( HOME_URL );
    } else view.loadPlayer( +id );
  }, [ id, view, history ] );

  useEffect( () => {
    if ( view.player ) {
      setInitialValues( { players: [ view.player.toForm() ] } );
    }
  }, [ view.player ] );

  useEffect( () => {
    if ( view.editPlayerSuccessful ) {
      history.replace( HOME_URL );
    }
  }, [ view.editPlayerSuccessful, history ] );

  const onSubmit = useCallback( ( playersInfo: IPlayerForm[] ) => (
    view.editPlayer( playersInfo[0] )
  ), [ view ] );

  return (
    <ResponsiveContainer sectionStyle={styles.container}>
      <BackArrowWithLabel />
      <h2 className="header1">{view.player?.firstName}</h2>
      <h4 className="subheader">Personal Information</h4>
      <PlayerForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        isEdit
      />
    </ResponsiveContainer>
  );
};

export default WithHeader( observer( EditPlayer ) );
