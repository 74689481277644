import React, { useCallback } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import styles from './styles.module.scss';
import { InputWithIcon, Separator } from '../index';
import TrashIcon from '../TrashIcon/TrashIcon';

interface PromoCodeProps {
  applyPromoCode: () => void;
  resetPromoCode?: () => void;
  onChangePromoCode: ( newPromoCode: string ) => void;
  invalidPromoCode?: boolean;
  hasPromoCode?: boolean;
  validPromoCode?: boolean;
  validUntil?: string;
  value?: string;
}

const PromoCode = ( {
  applyPromoCode,
  resetPromoCode = () => { /* do nothing */ },
  onChangePromoCode,
  invalidPromoCode = false,
  hasPromoCode = false,
  validPromoCode = false,
  validUntil = '',
  value = '',
}: PromoCodeProps ) => {
  const onChange = useCallback( ( e: React.ChangeEvent<HTMLInputElement> ) => {
    onChangePromoCode( e.target.value );
  }, [ onChangePromoCode ] );

  const promoCodeError = ( invalidPromoCode ) ? 'Promo code seems to be invalid' : undefined;
  const promoCodeValidUntil = ( validUntil ) ? `Valid until ${validUntil}` : '';
  const promoCodeSuccessMessage = ( validPromoCode ) ? `Promo applied succesfully. ${promoCodeValidUntil}` : undefined;

  const rightSection = ( !hasPromoCode ) ? (
    <button type="submit" className="linkText" onClick={applyPromoCode}>
      Apply code
    </button>
  )
    : (
      <TrashIcon
        onClick={resetPromoCode}
        className={styles.trashIcon}
      />
    );

  return (
    <div className={styles.promoCodeContainer}>
      <p className={cn( styles.subheader, styles.subtitle )}>Promo code</p>
      <Separator className={styles.separator} />
      <InputWithIcon
        name="promoCode"
        placeholder="Enter a promo code"
        value={value}
        onChange={onChange}
        onBlur={() => { /* do nothing */ }}
        rightSection={rightSection}
        disabled={hasPromoCode}
        error={promoCodeError}
        successText={promoCodeSuccessMessage}
        classname={styles.input}
      />
    </div>
  );
};

export default observer( PromoCode );
