import FacilitiesService from '../FacilitiesService';
import { FACILITIES_URL, SAME_SPORTS_FACILITIES_URL } from '../../constants/services';
import { Api } from '../index';
import { Facility } from '../../entities';

interface FacilitiesServiceFactoryProps {
  api?: Api;
  builder?: typeof Facility.fromJSON;
}

export default class FacilitiesServiceFactory {
  static forAllSports( {
    api = new Api(),
    builder = Facility.fromJSON,
  }: FacilitiesServiceFactoryProps = {} ) {
    return new FacilitiesService( {
      api,
      builder,
      facilitiesUrl: FACILITIES_URL,
    } );
  }

  static forSameSports( {
    api = new Api(),
    builder = Facility.fromJSON,
  }: FacilitiesServiceFactoryProps = {} ) {
    return new FacilitiesService( {
      api,
      builder,
      facilitiesUrl: SAME_SPORTS_FACILITIES_URL,
    } );
  }
}
