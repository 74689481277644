import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { SectionTitle } from '../../../components/index';
import styles from './styles.module.scss';
import { CREATE_PLAYERS_URL } from '../../../constants/routes';
import { ReactComponent as Kid } from '../../../assets/icons/kid/kid.svg';
import { ReactComponent as Chevron } from '../../../assets/icons/chevron/chevron.svg';

interface PlayersProps {
  data: {
    id: number;
    name: string;
    membership: string;
  }[];
}

const Players: React.FC<PlayersProps> = ( { data } ) => {
  const history = useHistory();

  return (
    <div className={styles.infoContainer}>
      <SectionTitle
        title="Players"
        actionLabel="Add new player"
        action={() => history.push( CREATE_PLAYERS_URL )}
      />
      {data.map( ( player ) => (
        <button
          type="button"
          key={player.id}
          onClick={() => history.push( `/player/${player.id}` )}
          className={cn( styles.playerCard, 'body1' )}
        >
          <span>
            <Kid className="kid" />
            <p className={styles.playerButtonText}>{player.name}</p>
          </span>
          <Chevron className={styles.chevron} />
        </button>
      ) )}
      {data.length === 0 && <div className={cn( 'body1', styles.emptyData )}>None</div>}
    </div>
  );
};

export default Players;
