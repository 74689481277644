export const ID_PATTERN = '{id}';
export const FACILITIES_URL = '/1/facilities';
export const SAME_SPORTS_FACILITIES_URL = `${FACILITIES_URL}/availables`;
export const MEMBERSHIP_PLANS_URL = '1/membership_plans';
export const USERS_URL = '/1/users';
export const CURRENT_USER_DATA_URL = `${USERS_URL}/me`;
export const PLAYERS_URL = '/1/players';
export const PLAYER_URL = `${PLAYERS_URL}/${ID_PATTERN}`;
export const ASSIGN_PLANS_TO_PLAYERS_URL = `${PLAYERS_URL}/assign_memberships`;
export const REMOVE_MEMBERSHIP_URL = `${PLAYER_URL}/remove_membership`;
export const DISCOUNTS_URL = '/1/membership_plans/discounts';
const PASSWORDS_URL = '/1/passwords';
export const RECOVER_PASSWORD_LINK_URL = `${PASSWORDS_URL}/get_reset_password_instructions`;
export const SET_NEW_PASSWORD_URL = `${PASSWORDS_URL}/reset_user_password`;
export const VALIDATE_RESET_PASSWORD_TOKEN_URL = `${PASSWORDS_URL}/validate_reset_password_token`;
export const AUTH_URL = '/oauth/token';
export const LOGOUT_URL = '/oauth/revoke';
export const REFRESH_TOKEN_GAP_IN_MINUTES = 10;
export const EDIT_ACCOUNT_URL = '/1/users/me';
export const BILLING_DETAILS_URL = `${PLAYERS_URL}/billing_details`;
export const ALL_MEMBERSHIP_PLANS_URL = '/1/membership_plans/availables';
export const DELETE_ACCOUNT_URL = '1/users/me';
export const ASSIGN_PROMO_CODE_URL = '/1/promo_codes/assign';
export const UNASSIGN_PROMO_CODE_URL = '/1/promo_codes/unassign';
export const VALIDATE_EMAIL_URL = '/1/users/validate_email';
export const BILLING_PORTAL_URL = `${CURRENT_USER_DATA_URL}/billing_portal`;
export const LEAVIMG_REASONS_URL = `${CURRENT_USER_DATA_URL}/leaving_reasons`;
export const CHOOSE_NEW_FACILITY_URL = `${CURRENT_USER_DATA_URL}/choose_new_facility`;
