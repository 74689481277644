import { CurrentUserService } from '../services';
import { AccountHolderValues } from '../views/EditAccountHolderView';

export default class EditAccountInteractor {
  service: CurrentUserService;

  constructor( {
    service = new CurrentUserService(),
  } = {} ) {
    this.service = service;
  }

  async execute( accountData: AccountHolderValues ) {
    return this.service.editAccount( accountData );
  }
}
