import React, { useCallback } from 'react';

import { Card, MembershipHeader } from '..';
import { MembershipPlan } from '../../entities';
import ExpandedContent from './components/ExpandedContent';
import styles from './styles.module.scss';
import MembershipStatus from './components/MembershipStatus';

interface CompactMembershipPlanCardProps {
  membershipPlan: MembershipPlan;
  onClick?: () => unknown;
  isSelected?: boolean;
  expandable?: boolean;
  isExpirationImminent?: boolean;
  activeUntil?: string;
  Icon?: React.ComponentType<{className: string}>;
  status?: 'active' | 'cancelled'
}

const accordionButtonStylesByStatus : {
    [key in Exclude<CompactMembershipPlanCardProps['status'], undefined>] : string
} = {
  active: styles.statusActiveAccordionButton,
  cancelled: styles.statusCancelledAccordionButton,
};

const cardStylesByStatus : {
    [key in Exclude<CompactMembershipPlanCardProps['status'], undefined>] : string
} = {
  active: styles.statusActiveCard,
  cancelled: styles.statusCancelledCard,
};

const CompactMembershipPlanCard: React.FC<CompactMembershipPlanCardProps> = ( {
  membershipPlan,
  onClick = () => { /* do nothing */ },
  isSelected,
  expandable = false,
  isExpirationImminent = false,
  activeUntil,
  Icon,
  status,
} ) => {
  const handleClick = useCallback( ( event ) => {
    event.preventDefault();
    onClick();
  }, [ onClick ] );

  return (
    <Card className={status && cardStylesByStatus[status]}>
      <div role="button" tabIndex={0} onClick={handleClick} className={styles.clickableContainer}>
        {status && activeUntil && (
        <MembershipStatus
          status={status}
          activeUntil={activeUntil}
          isExpirationImminent={isExpirationImminent}
        />
        )}
        <MembershipHeader
          name={membershipPlan.name}
          type={membershipPlan.tier}
          iconUrl={membershipPlan.icon}
          isSelected={isSelected}
          Icon={Icon}
        />
        {expandable && (
        <ExpandedContent
          membershipPlan={membershipPlan}
          accordionButtonClassName={status && accordionButtonStylesByStatus[status]}
        />
        )}
      </div>
    </Card>
  );
};

export default CompactMembershipPlanCard;
