import { PlayersService } from '../services';
import { BillingSession } from '../services/PlayersService';

export default class AssignMembershipPlanToPlayersInteractor {
  service: PlayersService;

  constructor( { service = new PlayersService() } = {} ) {
    this.service = service;
  }

  execute = async ( billingSession: BillingSession ) => (
    this.service.assignMembershipPlans( billingSession )
  );
}
