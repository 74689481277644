/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';

import {
  AxiosClient,
  ErrorResponse,
  RecordErrorResponse,
  ResponseDeserializer,
} from '.';
import {
  NetworkError,
  UnknownError,
  InputError,
  AvailabilityError,
  ServerError,
  UnauthorizedError, ConflictError,
} from '../errors';
import { AuthStore } from '../stores';

const errorsByStatusCode: ( response: ErrorResponse ) => () => never = ( response ) => {
  const { statusCode, name = '', description = '' } = response;

  const handlers: { [key in number | 'unknown']: () => never } = {
    400: () => { throw new InputError( { name, statusCode, message: description } ); },
    401: () => { throw new UnauthorizedError( { name, statusCode, message: description } ); },
    404: () => { throw new AvailabilityError( { name, statusCode, message: description } ); },
    409: () => { throw new ConflictError( { name, statusCode, message: description } ); },
    422: () => {
      throw new InputError( {
        name, statusCode, message: description, error: ( response as RecordErrorResponse ),
      } );
    },
    500: () => { throw new ServerError( { name, statusCode, message: description } ); },
    502: () => { throw new ServerError( { name, statusCode, message: description } ); },
    unknown: () => { throw new UnknownError( { name, message: description } ); },
  };

  return statusCode in handlers
    ? handlers[statusCode]
    : handlers.unknown;
};

interface ApiParameters {
  client?: AxiosClient;
  responseDeserializerKlass?: new ( response: AxiosResponse ) => ResponseDeserializer;
  authStore?: AuthStore;
}

export default class Api {
  client: AxiosClient;

  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  ResponseDeserializerKlass: new ( response: AxiosResponse ) => ResponseDeserializer;

  authStore: AuthStore;

  constructor( {
    client,
    responseDeserializerKlass = ResponseDeserializer,
    authStore = new AuthStore(),
  }: ApiParameters = {} ) {
    this.client = client || new AxiosClient( { authStore } );
    this.ResponseDeserializerKlass = responseDeserializerKlass;
    this.authStore = authStore;
  }

  get( url: string, config?: AxiosRequestConfig ): Promise<any> {
    return this.makeRequest(
      () => this.client.instance.get( url, config ),
    );
  }

  post( url: string, data?: any, config?: AxiosRequestConfig ): Promise<any> {
    return this.makeRequest(
      () => this.client.instance.post( url, data, config ),
    );
  }

  patch( url: string, data?: any, config?: AxiosRequestConfig ): Promise<any> {
    return this.makeRequest(
      () => this.client.instance.patch( url, data, config ),
    );
  }

  delete( url: string, config?: AxiosRequestConfig ): Promise<any> {
    return this.makeRequest(
      () => this.client.instance.delete( url, config ),
    );
  }

  private makeRequest = async ( request: () => Promise<AxiosResponse> ): Promise<any> => {
    try {
      const response = await request();
      const responseDeserializer = new this.ResponseDeserializerKlass( response );
      return responseDeserializer.deserializeResponse();
    } catch ( error ) {
      return this.processErrorResponse( error );
    }
  };

  private handleAxiosError = ( error: AxiosError ) => {
    if ( error.message === 'Network Error' ) {
      throw new NetworkError( { name: error.name, message: error.message } );
    }

    const responseDeserializer = new this.ResponseDeserializerKlass( error.response! );
    const response = responseDeserializer.deserializeResponse() as ErrorResponse;

    const errorThrower = errorsByStatusCode( response );

    return errorThrower();
  };

  private handleUnexpectedError = () => {
    throw new UnknownError();
  };

  private processErrorResponse = ( error: any ): never => {
    if ( axios.isAxiosError( error ) ) {
      return this.handleAxiosError( error );
    }
    return this.handleUnexpectedError();
  };
}
