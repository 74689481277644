import React from 'react';
import { SectionRow } from '../../../components';
import { BillingInformationToDisplay } from '../../../entities';

interface BillingInformationRowsProps {
  billingInformation : BillingInformationToDisplay;
  onCardEdit: () => void;

}

const BillingInformationRows : React.FC<BillingInformationRowsProps> = ( {
  billingInformation,
  onCardEdit,
} ) => (
  <>
    <SectionRow
      valueName="Payment method"
      value={billingInformation.cardsLast4}
      onEditClick={onCardEdit}
    />
    <SectionRow
      valueName="Active memberships"
      value={billingInformation.activeMemberships}
    />
    <SectionRow
      valueName="Next billing date"
      value={billingInformation.date}
    />
    <SectionRow
      valueName="Total Payment"
      value={billingInformation.total}
    />
    <SectionRow
      valueName="Active Promo Code"
      value={billingInformation.activePromoCode}
    />
  </>
);

export default BillingInformationRows;
