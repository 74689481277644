import React from 'react';
import { useHistory } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useAccountHolderName } from '../../../hooks';
import { Button } from '../..';
import { HOME_URL } from '../../../constants/routes';
import styles from './styles.module.scss';

const LoginButton: React.FC = () => {
  const history = useHistory();
  const accountHolderName = useAccountHolderName();

  return (
    <Button
      label={accountHolderName ?? 'Log In'}
      variant="secondary"
      size="small"
      icon="login"
      onClick={() => { history.push( HOME_URL ); }}
      buttonTextClassName={styles.loginButtonText}
    />
  );
};

export default observer( LoginButton );
