import React from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { RootStoreProvider } from '.';

interface AppProviderProps {
  children: React.ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ( { children }: AppProviderProps ) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <RootStoreProvider>
      {children}
    </RootStoreProvider>
  </LocalizationProvider>
);

export default AppProvider;
