import React from 'react';
import { SectionTitle } from '../../../components';
import { BillingInformationToDisplay } from '../../../entities';
import { BillingInformationRows, NoBillingInformationPlaceHolder, ManagePromoCodesButton } from '.';
import styles from './styles.module.scss';

interface BillingAndPaymentProps {
  billingInformation : BillingInformationToDisplay;
  onCardEdit: () => void;
}

const BillingAndPayments : React.FC<BillingAndPaymentProps> = ( {
  billingInformation,
  onCardEdit,
} ) => (
  <div className={styles.infoContainer}>
    <SectionTitle title="Billing & Payments" />
    <ManagePromoCodesButton />
    {
      billingInformation.hasBillingInformation
        ? (
          <BillingInformationRows
            billingInformation={billingInformation}
            onCardEdit={onCardEdit}
          />
        )
        : <NoBillingInformationPlaceHolder />
    }

  </div>
);

export default BillingAndPayments;
