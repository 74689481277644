import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export type DropdownOption = {
  name: string;
  id: string | number;
};

interface DropdownProps {
  options : DropdownOption[],
  onChange? : {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ( e: React.ChangeEvent<any> ): void;
  };
  onBlur? : {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ( e: React.FocusEvent<any> ): void;
  };
  name? : string;
  id? : string;
  value? : string | number;
  className?: string;
}

const Dropdown : React.FC<DropdownProps> = (
  {
    options,
    id,
    onChange,
    onBlur,
    name,
    value,
    className,
  },
) => {
  const dropdownOptions = options.map( option => (
    <option
      value={option.id}
      key={option.id}
    >
      {option.name}
    </option>
  ) );

  return (
    <select
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      id={id}
      value={value}
      className={cn( styles.dropdown, styles.body1, className )}
      data-testid="dropdown"
    >
      {dropdownOptions}
    </select>
  );
};

export default Dropdown;
