import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { WithHeader } from '../../hocs';
import { useHomeCoordinator } from '../../view-coordinators';
import { DELETE_ACCOUNT_URL, ROOT } from '../../constants/routes';
import {
  PersonalInformation, Players, BillingAndPayments, LogoutButton, DeleteAccountButton,
} from './components';
import styles from './styles.module.scss';
import { ResponsiveContainer, Separator } from '../../components';

const Home: React.FC = () => {
  const view = useHomeCoordinator();
  const history = useHistory();

  useEffect( () => {
    if ( view.logoutSuccessful ) {
      history.replace( ROOT );
    }
  }, [ view.logoutSuccessful, history ] );

  const goToDeleteAccount = useCallback( () => {
    history.push( DELETE_ACCOUNT_URL );
  }, [ history ] );

  return (
    <ResponsiveContainer sectionStyle={styles.container}>
      <h2 className="header2">Account</h2>
      <div className={styles.infoSections}>
        <PersonalInformation data={view.personalInformation} />
        <Players data={view.players} />
        <BillingAndPayments
          billingInformation={view.billingInformation}
          onCardEdit={view.goToEditCard}
        />
      </div>
      <div className={styles.actionsContainer}>
        <LogoutButton onClick={() => view.logout()} />
        <Separator className={styles.separator} />
        <DeleteAccountButton onClick={goToDeleteAccount} />
      </div>
    </ResponsiveContainer>
  );
};

export default WithHeader( observer( Home ) );
