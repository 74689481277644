import React from 'react';
import styles from '../styles.module.scss';
import { RECOVER_PASSWORD_URL } from '../../../constants/routes';

const RecoverPasswordLink: React.FC = () => (
  <div className={styles.RecoverPasswordContainer}>
    <p className="body1">
      <a href={RECOVER_PASSWORD_URL} className={styles.recoverPasswordLink}>I forgot my password</a>
    </p>

  </div>
);

export default RecoverPasswordLink;
