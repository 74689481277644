import { TotalChargesService } from '../services';
import { ChargesRequest } from '../services/TotalChargesService';

export default class FetchTotalChargesInteractor {
  service: TotalChargesService;

  constructor( {
    service = new TotalChargesService(),
  } = {} ) {
    this.service = service;
  }

  execute = async ( request: ChargesRequest ) => this.service.fetchCharges( request );
}
