import { CurrentUserService } from '../services';
import { CurrentUserStore } from '../stores';

export default class FetchCurrentUserDataInteractor {
  store: CurrentUserStore;

  service: CurrentUserService;

  constructor( {
    store = new CurrentUserStore(),
    service = new CurrentUserService(),
  } = {} ) {
    this.store = store;
    this.service = service;
  }

  async execute() {
    const user = await this.service.fetchCurrentUserData();
    this.store.setCurrentUser( user );
    return user;
  }
}
