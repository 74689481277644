import { FormikErrors, FormikHelpers } from 'formik';
import { makeAutoObservable, runInAction } from 'mobx';

import { SwishError } from '../errors';
import { handleError } from '../helpers/errors';
import { Passwords, validatePasswords } from '../helpers/formHelpers';
import { SetNewPasswordInteractor, ValidateResetPasswordTokenInteractor } from '../interactors';
import { SetNewPasswordFormValues } from '../screens/SetNewPassword/components/Form';

interface SetNewPasswordViewParams {
  setNewPasswordInteractor?: SetNewPasswordInteractor;
  validateResetPasswordTokenInteractor?: ValidateResetPasswordTokenInteractor;
}

export default class SetNewPasswordView {
  private setNewPasswordInteractor: SetNewPasswordInteractor;

  private validateResetPasswordTokenInteractor: ValidateResetPasswordTokenInteractor;

  renderCallback = false;

  tokenHasExpired = false;

  token?: string;

  constructor( {
    setNewPasswordInteractor = new SetNewPasswordInteractor(),
    validateResetPasswordTokenInteractor = new ValidateResetPasswordTokenInteractor(),
  }: SetNewPasswordViewParams ) {
    this.setNewPasswordInteractor = setNewPasswordInteractor;
    this.validateResetPasswordTokenInteractor = validateResetPasswordTokenInteractor;
    this.token = undefined;
    makeAutoObservable( this );
  }

  validateForm( values: SetNewPasswordFormValues ) {
    const errors: FormikErrors<SetNewPasswordFormValues> = {};

    validatePasswords( values, errors as Passwords );

    return errors;
  }

  async validateToken( token: string ) {
    try {
      const isValid = await this.validateResetPasswordTokenInteractor.execute( token );
      if ( isValid ) {
        runInAction( () => { this.token = token; } );
      } else {
        runInAction( () => { this.tokenHasExpired = true; } );
      }
    } catch ( error ) {
      const e = error as SwishError;
      handleError( e );
    }
  }

  async setNewPassword(
    values: SetNewPasswordFormValues,
    { setSubmitting }: Partial<FormikHelpers<SetNewPasswordFormValues>>,
  ) {
    try {
      await this.setNewPasswordInteractor.execute( this.token!, values.password );
      runInAction( () => { this.renderCallback = true; } );
    } catch ( error ) {
      const e = error as SwishError;
      handleError( e );
    } finally {
      setSubmitting!( false );
    }
  }
}
