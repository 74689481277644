import { makeAutoObservable, runInAction } from 'mobx';
import { CurrentUserStore } from '../stores';
import {
  FetchCurrentUserDataInteractor,
  AssignPromoCodeInteractor,
  UnassignPromoCodeInteractor,
} from '../interactors';
import ErrorHandler from './errors/ErrorHandler';
import { SwishError } from '../errors';

interface PromoCodeManagementViewParams {
  currentUserStore?: CurrentUserStore;
  fetchCurrentUserData?: FetchCurrentUserDataInteractor;
  assignPromoCodeInteractor?: AssignPromoCodeInteractor;
  unassignPromoCodeInteractor?: UnassignPromoCodeInteractor;
  errorHandler?: ErrorHandler;
}

export default class PromoCodeManagementView {
  private currentUserStore: CurrentUserStore;

  private fetchCurrentUserData: FetchCurrentUserDataInteractor;

  private assignPromoCodeInteractor: AssignPromoCodeInteractor;

  private unassignPromoCodeInteractor: UnassignPromoCodeInteractor;

  private errorHandler: ErrorHandler;

  private loading = false;

  private isDialogOpen = false;

  promoCode = '';

  invalidPromoCode = false;

  succesfullPromoCodeChange = false;

  promoCodeValidUntil = '';

  constructor( {
    currentUserStore = new CurrentUserStore(),
    fetchCurrentUserData = new FetchCurrentUserDataInteractor(),
    assignPromoCodeInteractor = new AssignPromoCodeInteractor(),
    unassignPromoCodeInteractor = new UnassignPromoCodeInteractor(),
    errorHandler = new ErrorHandler(),
  }: PromoCodeManagementViewParams = {} ) {
    this.currentUserStore = currentUserStore;
    this.fetchCurrentUserData = fetchCurrentUserData;
    this.assignPromoCodeInteractor = assignPromoCodeInteractor;
    this.unassignPromoCodeInteractor = unassignPromoCodeInteractor;
    this.errorHandler = errorHandler;
    makeAutoObservable( this );
  }

  async fetchAccountHolderData() {
    try {
      await this.fetchCurrentUserData.execute();
      this.setUserPromocode();

      runInAction( () => {
        this.invalidPromoCode = false;
        this.succesfullPromoCodeChange = false;
      } );
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }

  private setUserPromocode() {
    this.changePromoCode( this.userPromoCode?.name || '' );
    this.promoCodeValidUntil = this.userPromoCode?.expirationDate || '';
  }

  private get userPromoCode() {
    return this.currentUserStore.currentUser?.promoCode;
  }

  changePromoCode( newPromoCode:string ) {
    this.promoCode = newPromoCode;
  }

  applyPromoCode = async () => {
    if ( this.loading ) return;

    this.loading = true;
    try {
      await this.assignPromoCodeInteractor.execute( this.promoCode );
      await this.fetchAccountHolderData();
      runInAction( () => {
        this.succesfullPromoCodeChange = true;
      } );
    } catch ( e ) {
      const error = e as SwishError;
      if ( error.isInputError() ) {
        this.handlePromoCodeError();
      } else {
        this.errorHandler.handleError( error );
      }
    } finally {
      this.loading = false;
    }
  }

  private handlePromoCodeError() {
    this.invalidPromoCode = true;
  }

  deletePromoCode = async () => {
    if ( this.loading ) return;

    this.loading = true;
    try {
      await this.unassignPromoCodeInteractor.execute();
      await this.fetchAccountHolderData();
    } catch ( e ) {
      this.errorHandler.handleError( e as SwishError );
    } finally {
      this.loading = false;
    }
  }

  get hasPromoCode() {
    return !!this.userPromoCode;
  }

  get isDeletePromoCodeConfirmationDialogOpen() {
    return this.isDialogOpen;
  }

  openDeletePromoCodeConfirmationDialog = () => {
    if ( !this.isDialogOpen ) this.isDialogOpen = true;
  }

  closeDeletePromoCodeConfirmationDialog = () => {
    if ( this.isDialogOpen ) this.isDialogOpen = false;
  }

  onDeletePromoCodeConfirmation = () => {
    this.deletePromoCode();
    this.closeDeletePromoCodeConfirmationDialog();
  }
}
