import { CurrentUserService } from '../services';

export default class AssignPromoCodeInteractor {
  private service : CurrentUserService;

  constructor( { service = new CurrentUserService() } = {} ) {
    this.service = service;
  }

  execute = async ( promocode: string ) => this.service.assignPromoCode( promocode )
}
