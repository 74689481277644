import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Button, ResponsiveContainer } from '../../components';
import { ReactComponent as Whistle } from '../../assets/icons/whistle/whistle.svg';
import styles from './styles.module.scss';
import useChangeFacilityCoordinator from '../../view-coordinators/useChangeFacilityCoordinator';
import SameSportFacilityOptions from './components/SameSportFacilityOptions';
import { ReactComponent as Basketball } from '../../assets/icons/basketball-ball/basketball-ball.svg';
import { HOME_URL } from '../../constants/routes';

const ChangeFacility:React.FC = () => {
  const view = useChangeFacilityCoordinator();
  const history = useHistory();

  useEffect( () => {
    if ( view.finishedSendingNewFacility ) {
      history.replace( HOME_URL );
    }
  }, [ history, view.finishedSendingNewFacility ] );

  useEffect( () => {
    view.fetchFacilities();
  }, [ view ] );

  return (
    <ResponsiveContainer>
      <div className={styles.titleContainer}>
        <Whistle />
        <h1 className={styles.header2}>We need you to make some changes</h1>
        <p className={styles.body1}>
          Your default facility is out of order. To continue booking, please choose another one.
        </p>
      </div>
      <section className={styles.facilitiesSection}>
        { ( view.basketballFacilities.length > 0 )
             && (
             <SameSportFacilityOptions
               facilities={view.basketballFacilities}
               onChange={view.selectFacility}
               selectedFacilityId={view.selectedFacilityId}
               rightIcon={<Basketball className={styles.facilityIcon} />}
             />
             )}
        <Button
          disabled={view.isButtonDisabled()}
          loading={view.isSendingNewFacility}
          label="CHANGE TO THIS FACILITY"
          className={styles.selectFacilityButton}
          onClick={view.chooseNewFacility}
        />
      </section>

    </ResponsiveContainer>
  );
};

export default observer( ChangeFacility );
