import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  AUTH_URL,
  USERS_URL,
  FACILITIES_URL,
  RECOVER_PASSWORD_LINK_URL,
  SET_NEW_PASSWORD_URL,
} from '../constants/services';
import { AccessToken } from '../entities';
import { AuthStore } from '../stores';

interface AxiosClientParams {
  instance?: AxiosInstance;
  authStore?: AuthStore;
}

export default class AxiosClient {
  instance: AxiosInstance;

  authStore: AuthStore;

  constructor( {
    instance,
    authStore = new AuthStore(),
  }: AxiosClientParams = {} ) {
    this.instance = instance || axios.create( {
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        'Content-type': 'application/json',
      },
    } );
    this.authStore = authStore;

    this.setInterceptors();
  }

  private setInterceptors() {
    this.includeAuthorizationHeaderInterceptor();
    this.setTokenInterceptor();
  }

  private includeAuthorizationHeaderInterceptor() {
    this.instance.interceptors.request.use(
      ( config ) => {
        if (
          !this.isAuthRequest( config )
          && !this.isCreateAccountRequest( config )
          && !this.isFacilitiesRequest( config )
          && !this.isARecoverPasswordRequest( config )
        ) {
          config.headers.Authorization = `${this.authStore.tokenType} ${this.authStore.token}`;
        }
        return config;
      },
      ( error ) => Promise.reject( error ),
    );
  }

  private setTokenInterceptor() {
    this.instance.interceptors.response.use(
      ( response ) => {
        if ( this.isCreateAccountResponse( response ) || this.isLoginResponse( response ) ) {
          this.setAccessToken( response );
        }
        return response;
      },
      ( error ) => Promise.reject( error ),
    );
  }

  private setAccessToken( response: AxiosResponse ) {
    const accessTokenJson = response.data;
    const accessToken = AccessToken.fromJSON( accessTokenJson );
    this.authStore.setAccessToken( accessToken );
  }

  private isAuthRequest( config: AxiosRequestConfig ) {
    return config.url! === AUTH_URL;
  }

  private isCreateAccountRequest( config: AxiosRequestConfig ) {
    return config.url! === USERS_URL && config.method === 'post';
  }

  private isCreateAccountResponse( response: AxiosResponse ) {
    return response.config.url === USERS_URL && response.config.method === 'post';
  }

  private isLoginResponse( response: AxiosResponse ) {
    return response.config.url === AUTH_URL && response.config.method === 'post';
  }

  private isFacilitiesRequest( config: AxiosRequestConfig ) {
    return config.url! === FACILITIES_URL && config.method === 'get';
  }

  private isARecoverPasswordRequest( config: AxiosRequestConfig ) {
    return config.url! === RECOVER_PASSWORD_LINK_URL || config.url! === SET_NEW_PASSWORD_URL;
  }
}
