import React from 'react';
import { useHistory } from 'react-router-dom';
import { SectionRow, SectionTitle } from '../../../components';
import styles from './styles.module.scss';
import { Player } from '../../../entities';
import Times from '../../../helpers/Times';

interface PlayerInfoProps {
  player: Player;
}

const PlayerInfo: React.FC<PlayerInfoProps> = ( { player } ) => {
  const history = useHistory();
  const onClickEdit = () => { history.push( `/edit_player/${player.id}` ); };
  const times = new Times();

  return (
    <div className={styles.infoContainer}>
      <SectionTitle title="Player information" action={onClickEdit} actionLabel="Edit" />
      <SectionRow valueName="Name" value={player.firstName} />
      <SectionRow valueName="Birthday" value={times.dateToString( player.birthday )} />
      <SectionRow valueName="School/college" value={player.school} />
      <SectionRow valueName="Grade/year" value={player.grade} />
    </div>
  );
};

export default PlayerInfo;
