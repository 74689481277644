import { useState } from 'react';

import { CreateAccountView } from '../views';
import { useRootStore } from '../providers/RootStoreProvider';
import {
  Api,
  OAuthResponseDeserializer,
  CurrentUserService,
  PlayersService,
  MembershipPlansService,
  TotalChargesService,
} from '../services';
import {
  FetchFacilitiesInteractor,
  CreateAccountInteractor,
  FetchCurrentUserDataInteractor,
  CreatePlayersInteractor,
  FetchMembershipPlansInteractor,
  AssignMembershipPlanToPlayersInteractor, FetchTotalChargesInteractor,
} from '../interactors';
import useErrorHandler from '../views/errors/useErrorHandler';
import { FacilitiesServiceFactory } from '../services/factories';
import { MembershipPlan } from '../entities';

const useCreateAccountCoordinator = () => {
  const {
    authStore,
    currentUserStore,
    facilitiesStore,
    membershipPlansStore,
    billingCycleStore,
  } = useRootStore();
  const errorHandler = useErrorHandler();

  const createView = () => {
    const authApi = new Api( {
      authStore,
      responseDeserializerKlass: OAuthResponseDeserializer,
    } );

    const api = new Api( { authStore } );

    // Account Holder
    const currentUserService = new CurrentUserService( { api } );
    const fetchCurrentUserDataInteractor = new FetchCurrentUserDataInteractor( {
      store: currentUserStore,
      service: currentUserService,
    } );
    const createAccountService = new CurrentUserService( { api: authApi } );
    const createAccountInteractor = new CreateAccountInteractor( {
      service: createAccountService,
    } );

    // Facilities
    const facilitiesService = FacilitiesServiceFactory.forAllSports( { api } );
    const fetchFacilitiesInteractor = new FetchFacilitiesInteractor( {
      saveInStore: ( facilities ) => facilitiesStore.setFacilities( facilities ),
      service: facilitiesService,
    } );

    // Membership Plans
    const membershipPlansService = new MembershipPlansService( { api } );
    const fetchMembershipPlansInteractor = new FetchMembershipPlansInteractor( {
      store: ( membershipPlans : MembershipPlan[] ) => membershipPlansStore
        .setAllFacilitiesMembershipPlans( membershipPlans ),
      fetch: () => membershipPlansService
        .fetchAllFacilitiesMembershipPlans(),
    } );

    // Create Players
    const playersService = new PlayersService( { api } );
    const createPlayersInteractor = new CreatePlayersInteractor( {
      service: playersService,
    } );

    // Assign Membership Plans to Players
    const assignMembershipPlansInteractor = new AssignMembershipPlanToPlayersInteractor( {
      service: playersService,
    } );

    // fetchTotalCharges
    const totalChargesService = new TotalChargesService( { api } );
    const fetchTotalChargesInteractor = new FetchTotalChargesInteractor( {
      service: totalChargesService,
    } );

    return new CreateAccountView( {
      fetchCurrentUserDataInteractor,
      createAccountInteractor,
      createPlayersInteractor,
      fetchFacilitiesInteractor,
      assignMembershipPlansInteractor,
      fetchTotalChargesInteractor,
      fetchMembershipPlansInteractor,
      facilitiesStore,
      membershipPlansStore,
      billingCycleStore,
      errorHandler,
    } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default useCreateAccountCoordinator;
