import React, { ReactNode, useCallback, useRef } from 'react';
import styles from './styles.module.scss';
import { Accordion } from '../../../components';
import { AccordionHandle } from '../../../components/Accordion/Accordion.component';
import { FAQSection } from '../../../entities';

interface QuestionsContainerProps {
  children: ReactNode;
  section: FAQSection;
}

const QuestionsContainer : React.FC<QuestionsContainerProps> = (
  { children, section }:QuestionsContainerProps,
) => {
  const ref = useRef<AccordionHandle>();
  const setRef = useCallback( ( c ) => {
    ref.current = c;
    section.setAccordionHandle( c );
  }, [ section ] );

  return (
    <div className={styles.card}>
      <Accordion
        buttonClassName={styles.accordion}
        chevronClassName={styles.chevron}
        buttonLabels={[ section.title, section.title ]}
        ref={setRef}
      >
        <section className={styles.questionList}>
          {children}
        </section>
      </Accordion>
    </div>
  );
};

export default QuestionsContainer;
