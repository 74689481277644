import React from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { Button, ErrorMessage, InputContainer } from '../../../components';
import styles from '../styles.module.scss';
import RecoverPasswordLink from './RecoverPasswordLink';

export type LoginFormValues = {
  email: string;
  password: string;
};

interface FormProps {
  onSubmit: (
    values: LoginFormValues,
    helpers: Partial<FormikHelpers<LoginFormValues>>,
  ) => void;
  validate: ( values: LoginFormValues ) => FormikErrors<LoginFormValues>;
  errorMessage?: string;
}

const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

const LoginForm: React.FC<FormProps> = ( {
  onSubmit,
  validate,
  errorMessage,
} ) => {
  const buttonDisabled = (
    isSubmitting: boolean,
    values: LoginFormValues,
    errors: FormikErrors<LoginFormValues>,
  ) => isSubmitting
      || !values.email
      || !values.password
      || !!errors.email
      || !!errors.password;

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {( {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
      } ) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <InputContainer
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            className="caption1"
            label="Email"
            error={errors.email}
            touched={!!touched.email}
          />
          <InputContainer
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            className="caption1"
            label="Password"
            error={errors.password}
            touched={!!touched.password}
          />
          <ErrorMessage touched errorText={errorMessage} />

          <RecoverPasswordLink />

          <Button
            className={styles.submitBtn}
            type="submit"
            label="login"
            disabled={buttonDisabled( isSubmitting, values, errors )}
            loading={isSubmitting && !isValidating}
            loadingType="Oval"
            variant="secondary"
          />
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
