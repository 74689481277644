import React from 'react';
import { MembershipPlan } from '../../entities';
import {
  Separator,
  PricingInfo,
  MembershipHeader,
  MembershipBenefitsList,
} from '..';
import styles from './styles.module.scss';

interface MembershipInformationProps {
  membershipPlan: MembershipPlan;
}

const MembershipInformation : React.FC<MembershipInformationProps> = ( {
  membershipPlan,
} ) => (
  <>
    <div className={styles.headerContainer}>
      <MembershipHeader
        name={membershipPlan.name}
        type={membershipPlan.tier}
        iconUrl={membershipPlan.icon}
      />
    </div>
    <Separator className={styles.separator} />
    <PricingInfo price={membershipPlan.price} />
    <Separator className={styles.separator} />
    <MembershipBenefitsList benefits={membershipPlan.benefits} />
  </>
);

export default MembershipInformation;
