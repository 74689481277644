import { MembershipPlan } from '../entities';

interface FetchMembershipPlansInteractorParams {
  store?: ( membershipPlans : MembershipPlan[] ) => void,
  fetch?: () => Promise<MembershipPlan[]>,
}
export default class FetchMembershipPlansInteractor {
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  store: ( membershipPlans : MembershipPlan[] ) => void;

  fetch: () => Promise<MembershipPlan[]>;

  constructor( {
    store = () => { /* do nothing */ },
    fetch = () => Promise.resolve( [] ),
  } : FetchMembershipPlansInteractorParams = {} ) {
    this.store = store;
    this.fetch = fetch;
  }

  private async fetchMembershipPlans() {
    const membershipPlans = await this.fetch();

    this.store( membershipPlans );
    return membershipPlans;
  }

  execute() {
    return this.fetchMembershipPlans();
  }
}
