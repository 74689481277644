/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import SimpleCart from './SimpleCart';
import { Cart, BackButton } from '../../../components';
import styles from './styles.module.scss';
import ManageMembershipView from '../../../views/ManageMembershipView';

interface CartProps {
  view: ManageMembershipView;
}

function CartStep( { view }: CartProps ) {
  useEffect( () => {
    view.fetchTotalCharges();
  }, [ view ] );

  return view.hasActiveMemberships
    ? (
      <SimpleCart
        view={view.cartView}
      />
    )
    : (
      <div className={styles.sectionContainer}>
        <BackButton />
        <h2 className="header1">Cart</h2>
        <Cart view={view.cartView} />
      </div>
    );
}

export default observer( CartStep );
