import React from 'react';
import { Question, QuestionsContainer } from './index';
import SearchInput from './SearchInput';
import { FAQView } from '../../../views';

interface FAQListProps {
  view: FAQView;
}

const FAQList = ( {
  view,
}: FAQListProps ) => (
  <>
    <SearchInput options={view.options} onFinishedChange={view.selectOption} />
    <QuestionsContainer section={view.sectionWithTitle( 'About Memberships' )!}>
      <Question
        title="Create an account and purchase a membership"
        answer="To create an account, access https://app.swish365.com/ - Get started - Complete your account information - Choose your default facility - Create as many players as you want - Assign memberships to your players (optional you can do it later) - Choose your billing cycle (pay every 1 / 3 / 6 / 12 months) - Go to pay - Complete your payment information in stripe and subscribe."
      />
      <Question
        title="How to purchase a membership to an existing player"
        answer="Sign in to your swish365 account, go to settings (top right corner) - My account - Edit players - Select the player you want to assign a membership - Select between our memberships (if you want more information select on see more) - Choose your billing cycle (pay every 1 / 3 / 6 / 12 months) - Go to pay - Complete your payment information in stripe and subscribe. "
      />
      <Question
        title="How to cancel a membership"
        answer="Sign in to your swish365 account, go to settings (top right corner) - My account - Edit players - Select the player you want to change the membership - Press the bucket on your current membership and confirm. You will receive a confirmation email."
      />
      <Question
        title="How to upgrade/downgrade a membership"
        answer="To do this you will have to delete your current membership and assign a new one.
        Sign in to your swish365 account, go to settings (top right corner) - My account - Edit players - Select the player you want to change the membership - Press the bucket on your current membership and confirm - Go back to the player you want to upgrade/downgrade a membership - Select between our memberships (if you want more information select on see more) - Go to pay* - Complete your payment information in stripe and subscribe.
        *if you are upgrading your membership you will be charge the proportional days remaining until the billing cycle ends, if you are downgrading stripe will charge you a fee of 50 cents to do this change."
      />
    </QuestionsContainer>

    <QuestionsContainer section={view.sectionWithTitle( 'Billing & Payments' )!}>
      <Question
        title="How to change credit cards once you are a member"
        answer="Sign in to your swish365 account, go to settings (top right corner) - My account - Edit AccountHolder - Billing & Payments (bottom of the screen) - edit Payment method - Change credit card in stripe page."
      />
    </QuestionsContainer>

    <QuestionsContainer section={view.sectionWithTitle( 'Booking Sessions' )!}>
      <Question
        title="How to sign up for a shooting/skill class session"
        answer="Sign in to your swish365 account, press on the plus (+) sign you have at the bottom middle section - the sessions you will see will correspond to the facility you have selected (main menu at the top left corner) - you can select the day you want to attend to the facility and filter the different training types and specific hours (you have the filters)."
      />
      <Question
        title="How to change facilities for a session"
        answer="Sign in to your swish365 account, select the two opposing arrows you have to the right of your facility (top right corner of the app)."
      />
    </QuestionsContainer>
  </>
);

export default FAQList;
