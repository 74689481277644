import { useEffect, useState } from 'react';
import { useRootStore } from '../providers/RootStoreProvider';

const useAccountHolderName = () => {
  const { currentUserStore } = useRootStore();
  const [ name, setName ] = useState<string | undefined>( undefined );

  useEffect( () => {
    setName( currentUserStore.currentUser?.firstName );
  }, [ currentUserStore.currentUser ] );

  return name;
};

export default useAccountHolderName;
