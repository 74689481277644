import SwishError, { SwishErrorParams } from './SwishError';
import { RecordErrorResponse } from '../services';

export default class InputError extends SwishError {
  error?: RecordErrorResponse;

  constructor( { error, ...params }: SwishErrorParams & { error?: RecordErrorResponse } ) {
    super( params );

    this.error = error;
  }

  isInputError() { return true; }
}
