import React from 'react';

import { Icon } from '../../../components';
import styles from './styles.module.scss';

interface DeletePlayerButtonProps {
  onClick: () => void;
}

const DeletePlayerButton: React.FC<DeletePlayerButtonProps> = ( {
  onClick,
} ) => (
  <div className={styles.deletePlayerBtnContainer}>
    <div
      role="button"
      className={styles.deletePlayerContentWrapper}
      onClick={onClick}
      tabIndex={0}
    >
      <Icon name="trash" />
      <span className="body1">Delete player</span>
    </div>
  </div>
);

export default DeletePlayerButton;
