import { CurrentUserService } from '../services';
import { LeavingReasonJSON } from '../entities/LeavingReason';
import { LeavingReason } from '../entities';

export default class FetchLeavingReasonsInteractor {
  private service: CurrentUserService;

  constructor( {
    service = new CurrentUserService(),
  } = {} ) {
    this.service = service;
  }

  execute = async () => {
    const jsonReasons : LeavingReasonJSON[] = await this.service.leavingReasons();
    return jsonReasons.map( ( json ) => LeavingReason.fromJSON( json ) );
  }
}
