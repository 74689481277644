import React from 'react';

import { Checkbox as MUICheckbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Circle from '../../assets/icons/circle/circle.png';

interface CheckboxProps {
  name? : string;
  id? : string,
  onClick?: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ( e: React.ChangeEvent<any> ): void;
  };
  isChecked?: boolean;
  ariaLabel ? : string;
  variant? : 'rounded' | 'squared';
}

const iconsByVariant = {
  rounded: {
    base: <img alt="" style={{ height: '18px', width: '18px' }} src={Circle} />,
    checked: <RadioButtonCheckedIcon />,
  },
  squared: {
    base: <CheckBoxOutlineBlankIcon />,
    checked: <CheckBoxIcon />,
  },
};

const Checkbox: React.FC<CheckboxProps> = (
  {
    name = '',
    id = '',
    onClick = () => { /* do nothing */ },
    isChecked,
    ariaLabel = 'checkbox',
    variant = 'rounded',
  }: CheckboxProps,
) => (
  <MUICheckbox
    name={name}
    id={id}
    onChange={( event ) => onClick( event )}
    checked={isChecked}
    disableRipple
    icon={iconsByVariant[variant].base}
    checkedIcon={iconsByVariant[variant].checked}
    aria-label={ariaLabel}
  />
);

export default Checkbox;
