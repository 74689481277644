import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { WithHeader } from '../../hocs';
import styles from './styles.module.scss';
import { EditAccountForm } from './components';
import { AccountHolderValues } from '../../views/EditAccountHolderView';
import { useEditAccountHolderCoordinator } from '../../view-coordinators';
import BackArrowWithLabel from '../../components/BackArrowWithLabel/BackArrowWithLabel.component';
import { HOME_URL } from '../../constants/routes';
import { ResponsiveContainer } from '../../components';

const EditAccountHolder: React.FC = () => {
  const view = useEditAccountHolderCoordinator();
  const history = useHistory();

  const initialValues: AccountHolderValues = view.personalInformation;
  const sameSportFacilities = view.facilities;

  const onSubmit = useCallback(
    async ( accountData: AccountHolderValues ) => {
      await view.editAccount( accountData );
      history.push( HOME_URL );
    },
    [ history, view ],
  );

  const goBack = () => { history.push( HOME_URL ); };

  return (
    <ResponsiveContainer sectionStyle={styles.container}>
      <BackArrowWithLabel onClick={goBack} />
      <h2 className="header2">Account holder information</h2>
      <EditAccountForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        sameSportFacilities={sameSportFacilities}
      />
    </ResponsiveContainer>
  );
};

export default WithHeader( observer( EditAccountHolder ) );
