import React from 'react';
import styles from '../styles.module.scss';
import MembershipExpirationDate from './MembershipExpirationDate';

interface MembershipStatusProps {
  status: 'active' | 'cancelled';
  activeUntil: string;
  isExpirationImminent: boolean
}

const stylesByStatus = {
  active: styles.statusActive,
  cancelled: styles.statusCancelled,
};

const MembershipStatus = ( {
  status,
  activeUntil,
  isExpirationImminent = false,
} : MembershipStatusProps ) => (
  <div className={stylesByStatus[status]}>
    <div className="subheader">
      Current
    </div>
    <MembershipExpirationDate
      date={activeUntil}
      isActive={status === 'active'}
      isImminent={isExpirationImminent}
    />
  </div>
);

export default MembershipStatus;
