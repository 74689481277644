import { makeAutoObservable, runInAction } from 'mobx';

import { SwishError } from '../errors';
import { CreatePlayersInteractor } from '../interactors';
import ErrorHandler from './errors/ErrorHandler';
import { IPlayerForm } from '../components/PlayerForm/PlayerForm.component';
import { parsePlayersData } from '../helpers/formHelpers';

interface CreatePlayersViewParams {
  createPlayersInteractor?: CreatePlayersInteractor;
  errorHandler?: ErrorHandler;
}

export default class CreatePlayersView {
  createPlayersInteractor: CreatePlayersInteractor;

  createPlayersSuccessful = false;

  errorHandler: ErrorHandler;

  constructor( {
    createPlayersInteractor = new CreatePlayersInteractor(),
    errorHandler = new ErrorHandler(),
  }: CreatePlayersViewParams ) {
    this.createPlayersInteractor = createPlayersInteractor;
    this.errorHandler = errorHandler;
    makeAutoObservable( this );
  }

  async createPlayers( playersData: IPlayerForm[] ) {
    this.createPlayersSuccessful = false;
    try {
      await this.createPlayersInteractor.execute( parsePlayersData( playersData ) );
      runInAction( () => { this.createPlayersSuccessful = true; } );
    } catch ( error ) {
      this.errorHandler.handleError( error as SwishError );
    }
  }
}
