/* eslint-disable camelcase */
import {
  ASSIGN_PLANS_TO_PLAYERS_URL,
  PLAYERS_URL,
  PLAYER_URL,
  REMOVE_MEMBERSHIP_URL,
} from '../constants/services';
import { Player } from '../entities';
import { Api } from '.';
import { getUrlForId } from '../helpers/urls';

export interface PlayerData {
  first_name: string;
  last_name: string;
  birthday: Date;
  school: string;
  grade: string;
}

export interface BillingSession {
  cancel_url: string;
  success_url: string;
  duration_in_months: number | undefined;
  promo_code_name?: string;
  memberships_for_players: {
    player_id: number;
    membership_plan_id: number;
  }[];
}

export default class PlayersService {
  api: Api;

  builder: typeof Player.fromJSON;

  constructor( {
    api = new Api(),
    builder = Player.fromJSON,
  } = {} ) {
    this.api = api;
    this.builder = builder;
  }

  createPlayers = ( playerDataArr: PlayerData[] ) => this.api.post( PLAYERS_URL, {
    players: playerDataArr,
  } );

  editPlayer( playerId: number, playerData: PlayerData ) {
    return this.api.patch(
      getUrlForId( PLAYER_URL, playerId ),
      { player: playerData },
    );
  }

  deletePlayer( playerId: number ) {
    return this.api.delete( getUrlForId( PLAYER_URL, playerId ) );
  }

  assignMembershipPlans( billingSession: BillingSession ) {
    return this.api.post(
      ASSIGN_PLANS_TO_PLAYERS_URL,
      { billing_session: billingSession },
    );
  }

  removeMembershipPlanForPlayer( playerId: number ) {
    return this.api.post(
      getUrlForId( REMOVE_MEMBERSHIP_URL, playerId ),
    );
  }
}
