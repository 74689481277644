import { CurrentUserService } from '../services';
import { AccountHolderFormValues } from '../screens/CreateAccount/components/CreateAccountForm';

export default class CreateAccountInteractor {
  service: CurrentUserService;

  constructor( {
    service = new CurrentUserService(),
  } = {} ) {
    this.service = service;
  }

  execute( parentAccountData: AccountHolderFormValues ) {
    return this.service.createAccount( parentAccountData );
  }
}
