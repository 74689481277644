import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface QuestionProps {
  title : string;
  answer : string;
}

const Question : React.FC<QuestionProps> = ( { title, answer } ) => (
  <div>
    <p className={cn( styles.body2, styles.title )}>{title}</p>
    <p className={cn( styles.body1, styles.answer )}>{answer}</p>
  </div>
);

export default Question;
