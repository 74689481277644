import { useState } from 'react';
import { MembershipPlan } from '../entities';
import { EditPlayerInteractor, FetchMembershipPlansInteractor } from '../interactors';

import { useRootStore } from '../providers/RootStoreProvider';
import { Api, MembershipPlansService, PlayersService } from '../services';
import { EditPlayerView } from '../views';
import useErrorHandler from '../views/errors/useErrorHandler';

const useEditPlayerCoordinator = () => {
  const { authStore, currentUserStore, membershipPlansStore } = useRootStore();
  const errorHandler = useErrorHandler();

  const createView = () => {
    const api = new Api( { authStore } );
    const playersService = new PlayersService( { api } );
    const editPlayerInteractor = new EditPlayerInteractor( { service: playersService } );

    const membershipPlansService = new MembershipPlansService( { api } );
    const fetchMembershipPlansInteractor = new FetchMembershipPlansInteractor( {
      store: ( membershipPlans : MembershipPlan[] ) => membershipPlansStore
        .setUsersFacilityMembershipPlans( membershipPlans ),
      fetch: () => membershipPlansService
        .fetchUsersFacilityMembershipPlans(),
    } );

    return new EditPlayerView( {
      currentUserStore,
      membershipPlansStore,
      editPlayerInteractor,
      fetchMembershipPlansInteractor,
      errorHandler,
    } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default useEditPlayerCoordinator;
