import React from 'react';

import { useHistory } from 'react-router-dom';
import { SectionRow, SectionTitle } from '../../../components/index';
import styles from './styles.module.scss';
import { EDIT_ACCOUNT_HOLDER_URL } from '../../../constants/routes';

interface PersonalInformationProps {
  data: {
    name: string;
    email: string;
    phoneNumber: string;
    defaultFacility: string;
  };
}

const PersonalInformation: React.FC<PersonalInformationProps> = ( { data } ) => {
  const history = useHistory();

  return (
    <div className={styles.infoContainer}>
      <SectionTitle
        title="Personal information"
        actionLabel="Edit"
        action={() => history.push( EDIT_ACCOUNT_HOLDER_URL )}
      />
      <SectionRow valueName="Name" value={data.name} />
      <SectionRow valueName="Email" value={data.email} />
      <SectionRow valueName="Phone Number" value={data.phoneNumber} />
      <SectionRow valueName="Default Facility" value={data.defaultFacility} />
    </div>
  );
};

export default PersonalInformation;
