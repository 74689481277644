import React, { memo } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as DisclaimerIcon } from '../../../assets/icons/disclaimer/disclaimer.svg';

const Disclaimer: React.FC = () => (
  <div className={styles.disclaimerContainer}>
    <DisclaimerIcon className={styles.disclaimerIcon} />
    <p className={styles.caption1}>
      To add a new promo code, you&#39;ll have to delete the one you have active
    </p>
  </div>
);

export default memo( Disclaimer );
