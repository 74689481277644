import { useState } from 'react';

import { useRootStore } from '../providers/RootStoreProvider';
import { CreatePlayersInteractor } from '../interactors';
import { Api, PlayersService } from '../services';
import { CreatePlayersView } from '../views';
import useErrorHandler from '../views/errors/useErrorHandler';

const useCreatePlayersCoordinator = () => {
  const { authStore } = useRootStore();
  const errorHandler = useErrorHandler();

  const createView = () => {
    const authApi = new Api( { authStore } );
    const playersService = new PlayersService( { api: authApi } );
    const createPlayersInteractor = new CreatePlayersInteractor( { service: playersService } );

    return new CreatePlayersView( { createPlayersInteractor, errorHandler } );
  };

  const [ viewInstance ] = useState( createView );
  return viewInstance;
};

export default useCreatePlayersCoordinator;
